import React from "react";
import "./../style/home.css";
import { Link } from "react-router-dom";
import hausLogoComplete from "../media/logoHaus.png";
import hausLogoJustLogo from "../media/justLogo.png";
import { Redirect } from "react-router-dom";
import { Popconfirm, message } from "antd";
import {
  getPaymentDetails,
  getSubscriptionDetails,
  resetSubscription,
} from "../network/user";

var address;
if (process.env.NODE_ENV === "production") {
  address = "https://be-haus.com/api";
} else {
  address = "http://localhost:3000";
}

export default class PaymentSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: this.props.user,
      showBillingPage: false,
      stripe_session_url: "",
    };
  }

  async componentDidMount() {
    // if (
    //   this.props.user.stripeInfo &&
    //   this.props.user.stripeInfo.stripeCustomerId
    // ) {
    //   let subscription = await getSubscriptionDetails(
    //     this.props.user.stripeInfo.stripeCustomerId
    //   );
    //   console.log("subscription", subscription);
    //   this.setState({
    //     // email: this.props.user.email,
    //     subscriptionStatus: subscription.subscriptionInfo.status,
    //   });
    // } else {
    //   this.setState({ subscriptionStatus: "No subscription" });
    // }
  }

  componentWillUnmount() {}

  render() {
    //console.log("subscriptionOK PAYMENT:", this.props.subscriptionOK);
    return (
      <div
        style={{
          width: "100%",
          height: "400px",
          backgroundColor: "#faf9f7",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ fontFamily: "Abel", fontSize: 20 }}>
          Account: <strong>{this.state.user.email}</strong>
        </div>
        {this.renderSubscriptionStatus()}
        {this.props.user.stripeInfo?.stripeCustomerId ? (
          <div>
            {this.renderBillingButton()}
            {this.renderResetSubscription(
              this.props.user.stripeInfo?.stripeCustomerId
            )}
          </div>
        ) : null}
      </div>
    );
  }

  renderBillingButton() {
    return (
      <div
        onClick={async () => {
          let billingInfo = await getPaymentDetails(
            this.props.user.stripeInfo.stripeCustomerId
          );

          if (billingInfo.result === "success") {
            this.setState({
              stripe_session_url: billingInfo.stripe_session_url,
              showBillingPage: true,
            });
          } else {
            this.setState({
              stripe_session_url: "",
              showBillingPage: false,
            });
          }
          console.log("Billing URL: ", billingInfo);

          window.location = this.state.stripe_session_url;
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80px",
          width: "300px",
          padding: "8px 20px",
          border: "0.5px solid rgb(86,94,80)",
          borderRadius: "5px",
          backgroundColor: "rgb(86,94,80)",
          color: "#faf9f7",
          fontFamily: "Abel",
          fontSize: "16px",
          fontWeight: "600",
          transition: "all .2s ease-in-out",
          cursor: "pointer",
          margin: 5,
        }}
      >
        Access to your billing information
      </div>
    );
  }

  renderResetSubscription() {
    return (
      <Popconfirm
        title="A new subscription will be added. After this, please add your credit card payment."
        onConfirm={async () => {
          await resetSubscription(this.props.user.stripeInfo.stripeCustomerId);
          await message.info(
            "Subscription added. Access your billing dashboard"
          );
        }}
        onCancel={(e) => console.log(e)}
        okText="Yes"
        cancelText="No"
      >
        <div style={{ fontFamily: "Abel", fontSize: 15, cursor: "pointer" }}>
        Reactivate your Subscription.
        </div>
      </Popconfirm>
    );
  }
  renderSubscriptionStatus() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: 600,
          padding: 20,
          height: "auto",
        }}
      >
        <div style={{ fontFamily: "Abel", fontSize: 20 }}>
          Your subscription status:
        </div>
        <div
          style={{
            fontFamily: "Abel",
            fontSize: 40,
            textTransform: "capitalize",
          }}
        >
          {" "}
          {this.props.subscriptionOK ? this.props.subscriptionOK : "Cancelled"}
        </div>

        {this.props.subscriptionOK === "trialing" ? (
          <div style={{ fontFamily: "Abel", fontSize: 18, fontWeight: 600 }}>
            Please make sure to add a payment method on your billing plan.
          </div>
        ) : null}
        {!this.props.subscriptionOK ? (
          <div style={{ fontFamily: "Abel", fontSize: 18, fontWeight: 600 }}>
            Subscription payment method failed. Please renew your payment method
            or subscription
          </div>
        ) : null}
      </div>
    );
  }
}
