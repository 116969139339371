import { Amplify, Auth } from "aws-amplify";
import React from "react";
import Media from "react-media";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//import AppContextCustom from "./components/AppContext";
import awsconfig from "./aws-exports";
import HamburgerMenu from "./components/HamburgerMenu";
import HamburgerMenu_Authenticated from "./components/HamburgerMenu_Authenticated";
import Header from "./components/Header";
import Header_Authenticated from "./components/Header_Authenticated";
import Account from "./pages/Account";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Classes from "./pages/Classes";
import ClassPage from "./pages/ClassPage";
import Home from "./pages/Home";
import LandingPage from "./pages/LandingPage";
import TeacherProfile from "./pages/TeacherProfile";
import Teachers from "./pages/Teachers";
import UserProfile from "./pages/UserProfile";
import Users from "./pages/Users";
import Wellbeing from "./pages/Wellbeing";
import "./style/App.css";
import Food from "./pages/wellbeing/Food";
import FoodPage from "./pages/wellbeing/FoodPage";
import { Redirect, Link } from "react-router-dom";
import TermsConditions from "./pages/T&C";
//import hausLogoComplete from "./media/logoHaus.png";
import hausLogoComplete from "./media/localImages/logo_horizontal.png";
import { getOneUser, getSubscriptionDetails } from "./network/user";
import MixedContent from "./pages/wellbeing/MixedContent";
import MixedContentPage from "./pages/wellbeing/MixedContentPage";
import Payment from "./pages/auth/Payment";
import Recover from "./pages/auth/Recover";
import {
  FacebookOutlined,
  YoutubeOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
//import ForgotPassword from "./pages/auth/ForgotPassword";

import ReConfirm from "./pages/auth/ReConfirm";

import ReactGA from "react-ga";
Amplify.configure(awsconfig);

const trackingId = "UA-174671503-2"; //  Google Analytics tracking ID

ReactGA.initialize(trackingId);

export default class App extends React.Component {
  constructor(props) {
    super(props);

    //super important states used on ContextAPI
    this.state = {
      isAuthenticated: false,
      subscriptionStatus: "",
      subscriptionOK: "trialing",
    };

    this.setAuthenticated = this.setAuthenticated.bind(this);
  }

  async componentDidMount() {
    // console.log("subscription0 :");
    return await Auth.currentAuthenticatedUser()
      .then(async (user) => {
        if (!user.attributes.sub) {
          this.setState({
            isAuthenticated: false,
            subscriptionOK: false,
          });
          throw "No valid user info";
        }

        ReactGA.set({
          userId: user.attributes.sub,
          // any data that is relevant to the user session
          // that you would like to track with google analytics
        });
        ReactGA.event({
          category: "User Login",
          action: "User just logged in.",
        });

        // console.log("cognitoUser", user);

        let mongoUser = await getOneUser(user.attributes.sub);

        // console.log("mongoUser", mongoUser);
        this.setState({
          isAuthenticated: true, //CHANGE to true!!
          user: user, //cognitoUser
          mongoUser, //mongoUser
        });

        let subscription = await getSubscriptionDetails(
          mongoUser.stripeInfo.stripeCustomerId
        );

        // console.log("Subscription", subscription);

        if (subscription.result === "success") {
          //console.log("subscription :", subscription);

          if (
            subscription.stripeCustomerInfo.subscriptions.data.length === 0 &&
            this.state.mongoUser.role !== "admin"
          ) {
            //If no subscriptions
            this.setState({
              subscriptionOK: false,
            });
          }
          subscription.stripeCustomerInfo.subscriptions.data.forEach(
            (subscription) => {
              if (
                subscription.status === "trialing" ||
                subscription.status === "active"
              ) {
                // console.log("GOT IT!!");
                this.setState({
                  subscriptionOK: subscription.status,
                });
              } else {
                this.setState({
                  subscriptionOK: false,
                });
              }
            }
          );

          // this.setState({
          //   subscriptionStatus: subscription.subscriptionInfo.status,
          // });
        } else {
          if (this.state.mongoUser.role !== "admin") {
            this.setState({
              subscriptionOK: false,
            });
          }
        }

        // AppContextCustom.setAuth(true);
      })
      .catch((err) => {
        if (!this.state.mongoUser || this.state.mongoUser.role !== "admin") {
          this.setState({
            isAuthenticated: false,
            subscriptionOK: false,
          });
        }
        console.log(err);
      });
  }

  setAuthenticated(isAuthenticated) {
    this.setState({ isAuthenticated });
  }
  renderSpacingFromPageTop() {
    return <div style={{ height: "15vh", width: "100%" }}></div>;
  }

  render() {
    //console.log("subscriptionOK?", this.state.subscriptionOK);
    return (
      <Router>
        <Media
          queries={{
            small: "(max-width: 599px)",
            medium: "(min-width: 600px) and (max-width: 1199px)",
            large: "(min-width: 1200px)",
          }}
        >
          {(matches) => (
            <div>
              {!this.state.isAuthenticated ? (
                <>
                  {matches.small ? (
                    <HamburgerMenu user={this.state.mongoUser} />
                  ) : (
                    <Header user={this.state.mongoUser} />
                  )}
                  <div
                    style={{
                      //WIDTH LIMITER and center in whole website
                      backgroundColor: "rgb(229, 232, 233)",
                      width: "100%",
                      maxWidth: "1450px",
                      margin: " 0 auto",
                    }}
                  >
                    {this.renderSpacingFromPageTop()}
                    <Switch>
                      {/* <Route exact path="/" component={LandingPage}   matches={matches}/> */}

                      <Route
                        exact
                        path={"/"}
                        render={() => <LandingPage matches={matches} />}
                      ></Route>
                      <Route
                        exact
                        path={"/login"}
                        render={() => (
                          <Login
                            setAuthenticated={this.setAuthenticated}
                            matches={matches}
                            isAuthenticated={this.state.isAuthenticated}
                          />
                        )}
                      ></Route>
                      <Route
                        exact
                        path={"/recover"}
                        render={() => (
                          <Recover
                            matches={matches}
                            setAuthenticated={this.setAuthenticated}
                            isAuthenticated={this.state.isAuthenticated}
                          />
                        )}
                      ></Route>

                      <Route
                        exact
                        path={"/reconfirm"}
                        render={() => (
                          <ReConfirm
                            matches={matches}
                            setAuthenticated={this.setAuthenticated}
                            isAuthenticated={this.state.isAuthenticated}
                          />
                        )}
                      ></Route>
                      <Route
                        exact
                        path={"/register"}
                        render={() => (
                          <Register
                            matches={matches}
                            //setAuthenticated={this.setAuthenticated}
                            //isAuthenticated={this.state.isAuthenticated}
                          />
                        )}
                      ></Route>

                      <Route
                        exact
                        path="/terms-conditions"
                        component={TermsConditions}
                      />
                      {/* <Route exact path="/register" component={Register} /> */}
                      <Route exact path="/payment" component={Payment} />
                    </Switch>
                    {/* {this.renderLandingFooter()} */}
                  </div>
                </>
              ) : this.state.isAuthenticated &&
                this.state.subscriptionOK !== "active" &&
                this.state.subscriptionOK !== "trialing" &&
                this.state.mongoUser.role !== "admin" ? (
                <>
                  <Route
                    exact
                    path="/account"
                    render={() => (
                      <Account
                        user={this.state.mongoUser}
                        subscriptionOK={this.state.subscriptionOK}
                      />
                    )}
                  />
                  <Redirect to="/account" />
                </>
              ) : (
                <>
                  {matches.small ? (
                    <HamburgerMenu_Authenticated user={this.state.mongoUser} />
                  ) : (
                    <Header_Authenticated user={this.state.mongoUser} />
                  )}

                  <div
                    style={{
                      //WIDTH LIMITER and center in whole website
                      backgroundColor: "rgb(229, 232, 233)",
                      width: "100%",
                      maxWidth: "1410px",
                      margin: " 0 auto",
                    }}
                  >
                    {this.renderSpacingFromPageTop()}
                    <Switch>
                      {/* <Route exact path="/" component={Home} /> */}

                      <Route
                        exact
                        path={"/"}
                        render={() => (
                          <Home user={this.state.mongoUser} matches={matches} />
                        )}
                      ></Route>

                      <Route
                        exact
                        path={"/teachers"}
                        render={() => (
                          <Teachers
                            user={this.state.mongoUser}
                            matches={matches}
                          />
                        )}
                      ></Route>

                      <Route exact path="/wellbeing" component={Wellbeing} />

                      <Route
                        exact
                        path="/account"
                        render={() => (
                          <Account
                            user={this.state.mongoUser}
                            subscriptionOK={this.state.subscriptionOK}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/food"
                        render={() => (
                          <Food user={this.state.mongoUser} matches={matches} />
                        )}
                      />
                      <Route
                        exact
                        path="/books"
                        render={() => (
                          <MixedContent
                            user={this.state.mongoUser}
                            type={"books"}
                            matches={matches}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={"/books/:contentId"}
                        render={(props) => (
                          <MixedContentPage
                            {...props}
                            type={"books"}
                            user={this.state.mongoUser}
                          />
                        )}
                      ></Route>
                      <Route
                        exact
                        path="/astrology"
                        render={() => (
                          <MixedContent
                            user={this.state.mongoUser}
                            type={"astrology"}
                            matches={matches}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={"/astrology/:contentId"}
                        render={(props) => (
                          <MixedContentPage
                            {...props}
                            type={"astrology"}
                            user={this.state.mongoUser}
                          />
                        )}
                      ></Route>
                      <Route
                        exact
                        path="/ayurveda"
                        render={() => (
                          <MixedContent
                            user={this.state.mongoUser}
                            type={"ayurveda"}
                            matches={matches}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={"/ayurveda/:contentId"}
                        render={(props) => (
                          <MixedContentPage
                            {...props}
                            type={"ayurveda"}
                            user={this.state.mongoUser}
                          />
                        )}
                      ></Route>
                      <Route
                        exact
                        path="/beauty"
                        render={() => (
                          <MixedContent
                            user={this.state.mongoUser}
                            type={"beauty"}
                            matches={matches}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={"/beauty/:contentId"}
                        render={(props) => (
                          <MixedContentPage
                            {...props}
                            type={"beauty"}
                            user={this.state.mongoUser}
                          />
                        )}
                      ></Route>
                      <Route
                        exact
                        path={"/food/:recipeId"}
                        render={(props) => (
                          <FoodPage {...props} user={this.state.mongoUser} />
                        )}
                      ></Route>
                      <Route
                        exact
                        path={"/teachers/:teacherId"}
                        render={(props) => (
                          <TeacherProfile
                            {...props}
                            user={this.state.mongoUser}
                            matches={matches}
                          />
                        )}
                      ></Route>
                      <Route exact path="/users" component={Users} />
                      <Route
                        exact
                        path={"/users/:userId"}
                        render={(props) => <UserProfile {...props} />}
                      ></Route>
                      <Route
                        exact
                        path={"/classes"}
                        render={(props) => (
                          <Classes user={this.state.mongoUser} {...props} />
                        )}
                      ></Route>
                      <Route
                        exact
                        path={"/classes/:classId"}
                        render={(props) => (
                          <ClassPage {...props} user={this.state.mongoUser} />
                        )}
                      ></Route>

                      <Route
                        //Special route for selecting types from landing page
                        exact
                        path={"/classes-types/:typeId"}
                        render={(props) => (
                          <Classes user={this.state.mongoUser} {...props} />
                        )}
                      ></Route>

                      <Route
                        exact
                        path="/terms-conditions"
                        component={TermsConditions}
                      />
                      {/* <Route
                          path={"/classes/type/:typeId"}
                          render={(props) => (
                            <Classes user={this.state.mongoUser} {...props} />
                          )}
                        ></Route> */}
                    </Switch>
                  </div>
                </>
              )}
              {this.renderLandingFooter(matches)}
            </div>
          )}
        </Media>
      </Router>
    );
  }

  renderLandingFooter(matches) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: matches.small ? "column" : "row",
          justifyContent: matches.small ? "center" : "space-between",
          alignItems: matches.small ? "center" : "center",
          backgroundColor: "rgb(229,232,233)",
          width: "100%",
          minHeight: "25vh",
          height: "auto",
          padding: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              // marginTop: 20,
              marginLeft: 20,
              display: "flex",
              backgroundImage: "url(" + hausLogoComplete + ")",
              width: "200px",
              height: "100px",
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              zIndex: 1000,
              // backgroundColor:
              //   window.pageYOffset <= 100 ? "#fff6e8" : "#f4f9f4aa",
              // borderRadius: window.pageYOffset <= 100 ? 0 : "50%",
            }}
          ></div>
          <div
            style={{
              margin: 2,
              color: "#000",
              fontSize: 10,
              paddingLeft: 20,
            }}
          >
            ©2020 Thaís Caniceiro Unipessoal Lda{" "}
          </div>
          <div
            style={{
              margin: 2,
              color: "#000",
              fontSize: 10,
            }}
          >
            All rights reserved
          </div>
        </div>

        <div
          style={{
            width: "300px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            color: "#000",
            fontSize: 14,
            fontWeight: 600,
            // alignSelf: "flex-end",
          }}
        >
          <a
            style={{ color: "#000", border: "1px #000", paddingTop: 10 }}
            href="mailto:info.behauscommunity@gmail.com?subject=Be-Haus Support"
          >
            For any questions, please contact us
          </a>
          <div
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              padding: 10,
            }}
          >
            <a
              target="_blank"
              href="https://www.instagram.com/behauscommunity/"
            >
              <InstagramOutlined
                style={{ fontSize: 35, color: "#565e50", cursor: "pointer" }}
              />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/Be-Haus-Community-101243445007839"
            >
              <FacebookOutlined
                style={{ fontSize: 35, color: "#565e50", cursor: "pointer" }}
                onClick={{}}
              />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UC61_lEpueeUg1S83dF3Yr_A"
            >
              <YoutubeOutlined
                style={{ fontSize: 35, color: "#565e50", cursor: "pointer" }}
              />
            </a>
          </div>
        </div>
        <Link to={"/terms-conditions"}>
          <div
            style={{
              width: "200px",
              height: 80,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              color: "#000",
              fontSize: 14,
              fontWeight: 600,
              alignSelf: "flex-end",
            }}
          >
            Terms & Conditions
          </div>
        </Link>
      </div>
    );
  }
}
