import React from "react";
import { Upload, Modal, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import {
  postNewPhotoCreator,
  postNewPhotoMixedContent,
} from "../network/basicContent";
import { Storage } from "aws-amplify";
import awsconfig from "./../aws-exports";
import Compressor from "compressorjs";
import ImgCrop from "antd-img-crop";

const s3bucket = awsconfig.aws_user_files_s3_bucket;

// Amplify.configure({
//   Storage: {
//     AWSS3: {
//       bucket: "haus-bucket-2020", //REQUIRED -  Amazon S3 bucket
//       region: "us-east-1", //OPTIONAL -  Amazon service region
//     },
//   },
// });

export default class CreatorPhotos extends React.Component {
  state = {
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
    isLoading: false,
  };

  componentDidMount() {
    console.log("creatorInfo: ", this.props.creatorInfo);

    if (this.props.creatingNewClass) {
    } else {
      //get all photoURL and create a object that pictureWall understands
      let photosFromClass = this.props.creatorInfo.photos.map(
        (photo, index) => {
          if (photo) {
            //get the name of photo in S3 trough slicing from url (s3 key)
            var slashIndex = photo.lastIndexOf("/") + 1; //bar not included
            let s3Name = photo.substring(slashIndex, photo.length);
            return {
              uid: index,
              name: s3Name,
              status: "done",
              url: photo,
            };
          }
        }
      );
      //filter all non-photos or previous upload errors
      photosFromClass = photosFromClass.filter(
        (photo) => !!photo && !!photo.url
      );
      //set pictures list
      this.setState({ fileList: photosFromClass });
    }
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  //ADD OR DELETE PHOTOS
  handleChange = async (change) => {
    console.log(change);
    this.setState({ isLoading: true });
    if (change.file.status === "removed") {
      try {
        if (change.file.name !== "No_picture_available.png") {
          //dont delete our default pic
          await Storage.remove(change.file.name);
        }

        await postNewPhotoCreator(
          change.file.url,
          this.props.creatorInfo._id,
          "remove" //action
        );
        console.log("removed file");

        //update photos state
        let fileList = this.state.fileList;
        fileList = fileList.filter((photo) => photo.name !== change.file.name);

        this.setState({ fileList });
      } catch (err) {
        console.log("Problems removing  picture" + err);
      }
    }

    if (change.file.status === "uploading" || change.file.status === "done") {
      console.log("Uploading image");
      let randomName = uuidv4().slice(0, 6);
      let imageURL =
        "https://" + s3bucket + ".s3.amazonaws.com/public/" + randomName;

      try {
        console.log("Uploading ", imageURL);
        let imgCompressed = await this.compressImage(change.file.originFileObj);
        console.log("ImgCompressed ", imgCompressed);
        await this.uploadPhotoToS3(randomName, imgCompressed);

        await postNewPhotoCreator(imageURL, this.props.creatorInfo._id, "add");
      } catch (err) {
        console.log("Problems uploading picture" + err);
      }

      change.file.status = "done";
      this.setState({ fileList: change.fileList });
    }
    this.setState({ isLoading: false });
  };

  async uploadPhotoToS3(fileName, imageBuffer) {
    return await Storage.put(fileName, imageBuffer)
      .then((result) => {
        console.log("result", result);

        return result;
      })
      .catch((err) => console.log(err));
  }

  async compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success: resolve,
        error: reject,
        convertSize: 1000000,
      });
    });
  }

  render() {
    console.log("fileList:", this.state.fileList);
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div className="clearfix">
        <ImgCrop rotate>
          <Upload
            action=""
            customRequest={this.uploadPhoto}
            listType="picture-card"
            fileList={fileList}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>
        </ImgCrop>

        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
        {this.state.isLoading ? (
          <Spin
            //tip="Loading..."
            size="large"
            style={{
              position: "fixed",
              width: "50px",
              height: "50px",
              margin: "5% auto",
              left: 0,
              right: 0,
            }}
          />
        ) : null}
      </div>
    );
  }
}

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
