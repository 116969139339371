import React from "react";
import "./../style/home.css";
import { Link } from "react-router-dom";
//import hausLogoComplete from "../media/logoHaus_green.png";
import hausLogoComplete from "../media/localImages/logo_horizontal.png";

import hausLogoJustLogo from "../media/logoHaus_green.png";

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = { theposition: 0 };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }
  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    this.setState({
      theposition: scrolled,
    });
  };
  render() {
    //console.log("theposition:", this.state.theposition);
    return (
      <div
        style={{
          width: "100%",
          height: window.pageYOffset <= 100 ? "15vh" : "5vh",
          background:
            window.pageYOffset <= 100
              ? //  ? "linear-gradient(117deg, rgba(34,193,195,0.2) 0%, rgba(253,187,45,0.1) 100%)"
                "#e2ecf4"
              : "#e2ecf4",

          display: "flex",
          position: "fixed",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 30,
          zIndex: 1000,
          transition: "all 1s ease",
        }}
      >
        <div
          style={{
            backgroundImage:
              window.pageYOffset <= 100
                ? `url(${hausLogoComplete})`
                : `url(${hausLogoJustLogo})`,
            width: window.pageYOffset <= 100 ? "220px" : "100px",
            marginTop: window.pageYOffset <= 100 ? 0 : 50,
            height: window.pageYOffset <= 100 ? "80px" : "100px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            zIndex: 1000,
            backgroundColor: window.pageYOffset <= 100 ? null : "#f4f9f4aa",
            borderRadius: window.pageYOffset <= 100 ? 0 : "50%",
          }}
        ></div>

        {this.props.user.role === "admin" ? (
          <div
            style={{
              padding: 10,
              fontSize: 20,
              color: "#000",
              // width: "40%",
              fontWeight: 700,
              fontFamily: "Abel",
            }}
          >
            BACKOFFICE
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "60%",
          }}
        >
          {/* className is for hover effects only */}
          <Link to="/">
            <div
              className={"menuItem"}
              style={{ color: "#000", fontWeight: 500 }}
            >
              HAUS
            </div>
          </Link>
          <Link to="/classes">
            <div className={"menuItem"} style={{ color: "#000" }}>
              Classes
            </div>
          </Link>
          <Link to="/teachers">
            <div className={"menuItem"} style={{ color: "#000" }}>
              Teachers
            </div>
          </Link>
          <Link to="/wellbeing">
            <div className={"menuItem"} style={{ color: "#000" }}>
              Wellbeing
            </div>
          </Link>
          {this.props.user.role === "admin" ? (
            <Link to="/users">
              <div className={"menuItem"} style={{ color: "#000" }}>
                Users
              </div>
            </Link>
          ) : null}
          <Link to="/account">
            <div
              //  onClick={() => this.filteredClasses("duration", 60)}
              style={{
                display: "flex",
                height: "40px",
                width: "auto",
                padding: "8px 20px",
                border: "0.5px solid #000",
                borderRadius: "24px",
                backgroundColor: "#faf9f7",
                color: "#000",
                fontSize: "14px",
                transition: "all .2s ease-in-out",
                cursor: "pointer",
                whiteSpace: "nowrap",
                fontWeight: 400,
                margin: 10,
              }}
            >
              My account
            </div>
          </Link>
        </div>
      </div>
    );
  }
}
