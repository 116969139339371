import { Input, Table, Switch } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { getAllUsers, changeUserRole } from "../network/user";
const { Search } = Input;

let initialUsers = [];
export default class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = { users: [] };
  }

  filterSearchResults(value) {
    if (!value) {
      // console.log("settign initialUsers", initialUsers);
      this.setState({ users: initialUsers });
      return;
    }

    let filter = initialUsers.filter((user) => {
      let userString = JSON.stringify(user)
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      let searchValue = value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      console.log(" searchValue ", searchValue);
      //  console.log("userString, searchValue", userString, searchValue);
      return userString.indexOf(searchValue) !== -1;
    });

    this.setState({ users: filter });
  }
  async componentDidMount() {
    await this.triggerGetUsers();
  }

  async triggerGetUsers() {
    console.log("Getting users");
    let users = await getAllUsers();
    console.log("users", users.docs); //still without lean IMPROVE
    try {
      if (users.result === "failure") {
        this.setState({ users: [] });
      } else {
        this.setState({ users: [] });
        console.log("Setting new users");
        this.setState({ users: users.docs });
        initialUsers = users.docs;
      }
    } catch {
      this.setState({ users: [] });
    }

    console.log("users!", users);
    return users;
  }

  getColumns() {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text) => text,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      // {
      //   title: "Payments",
      //   dataIndex: "payments",
      //   key: "payments",
      // },
      {
        title: "Open",
        dataIndex: "id",
        key: "id",
        render: (id) => <Link to={"/users/" + id}>Open</Link>,
      },
      {
        title: "Is Administrator?",
        dataIndex: "id",
        key: "id",
        render: (id, user) => {
          //  console.log("id, user", id, user);
          return (
            <Switch
              checked={user.role === "user" ? false : true}
              onChange={(value) => this.changedSetAdmin(id, value)}
            />
          );
        },
      },
      // {
      //   title: "Action",
      //   key: "action",
      //   render: (text, record) => (
      //     <Space size="middle">
      //       <a>Invite {record.name}</a>
      //       <a>Delete</a>
      //     </Space>
      //   ),
      // },
    ];
  }

  async changedSetAdmin(userId, isAdmin) {
    // console.log(userId, isAdmin);

    changeUserRole(userId, isAdmin);
    window.location.reload();
  }

  render() {
    return (
      <div style={{ backgroundColor: "#faf9f7" }}>
        <Search
          placeholder="Search any field"
          onSearch={(value) => this.filterSearchResults(value)}
          onChange={(value) => this.filterSearchResults(value.target.value)}
          style={{ width: 200, margin: 10 }}
        />
        {/* <Input
          placeholder="input search text"
          onChange={(value) => this.filterSearchResults(value.target.value)}
          style={{ width: 200 }}
        /> */}
        <Table columns={this.getColumns()} dataSource={this.state.users} />
      </div>
    );
  }
}
function refreshPage() {
  window.location.reload();
}
