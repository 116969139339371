import React from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { CheckoutForm } from "./CheckoutForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let stripePromise;

if (process.env.NODE_ENV === "development") {
  stripePromise = loadStripe(
    "pk_test_51H4U7yKOK7fpk4t88NOWjp1NysymgkdIx8rQGtN5LHJR4m5dg5qmxp3TglV9gLNT7E8n0h5n5ze3vcYf2BrjewiS00oP5zSIDz"
  );
} else {
  stripePromise = loadStripe(
    "pk_live_51H4U7yKOK7fpk4t8Vup21TK9pyDwHMYOuXn2ImZcHiT2PpY5YPo8VtaaPSRNTeERMmq6Mc2Tsaxic8L6NCzdQVC800HkRGaqD3"
  );
}

export const Stripe = ({ stripeCustomer, paymentBasis, setIsLoading }) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm
      stripeCustomer={stripeCustomer}
      paymentBasis={paymentBasis}
      setIsLoading={setIsLoading}
    />
  </Elements>
);
