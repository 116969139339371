import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";

import "../style/wellbeing.css";
const food = "https://source.unsplash.com/jUPOXXRNdcA/500x500";
const cosmeticsImg = "https://source.unsplash.com/bd_fCZhy_W8/500x500";
const ayurveda = "https://source.unsplash.com/vA1L1jRTM70/500x500";
const conscFood = "https://source.unsplash.com/ocnsb17U6FE/500x500";
const astrology = "https://source.unsplash.com/boNRsEMxPsY/500x500";
const beautyAffairs = "https://source.unsplash.com/9oLEkjtYhCI/500x500";
const booksImg = "https://source.unsplash.com/2zDw14yCYqk/500x500";
export default class Wellbeing extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div
        style={{
          width: "100%",
          maxWidth: 1200,
          marginLeft: "auto",
          marginRight: "auto",
          height: "auto",
          backgroundColor: "#faf9f7",
          // height: "85vh",
          padding: 30,
          //   display: "flex",
        }}
      >
        {/* {this.renderIntroText()} */}
        {/* <Link to="/food">{this.renderButton(food, " Food & Health")}</Link>
        {this.renderButton(cosmeticsImg, " Natural Cosmetics")} */}

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <div display={{ display: "flex", flexDirection: "column" }}>
              <Link to="/ayurveda">
                {this.renderSmallSquare("Ayurveda", ayurveda)}
              </Link>
              <Link to="/food">
                {this.renderSmallSquare("Conscious Food", conscFood)}
              </Link>
            </div>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Link to="/astrology">
              {this.renderBigSquare("Astrology", astrology)}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Link to="/beauty">
              {this.renderSmallSquare("Beauty Affairs", beautyAffairs)}
            </Link>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Link to="/books">{this.renderSmallSquare("Books", booksImg)}</Link>
          </Col>
        </Row>
      </div>
    );
  }

  renderBigSquare(title, img) {
    return (
      <div
        style={{
          width: "100%",
          height: "600px",
          //backgroundColor: "black",
          background: "url(" + img + ")",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
        }}
      >
        <div
          className={"wellbeingDivEffect"}
          style={{
            // backgroundColor: "#0005",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }} //black overlay
        >
          <div
            style={{
              fontFamily: "Abel",
              fontSize: 50,
              color: "white",
              textTransform: "uppercase",
            }}
          >
            {title}
          </div>
        </div>
      </div>
    );
  }
  renderSmallSquare(title, img) {
    return (
      <div
        style={{
          width: "100%",
          height: "300px",
          //backgroundColor: "black",
          background: "url(" + img + ")",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
        }}
      >
        <div
          className={"wellbeingDivEffect"}
          style={{
            //  backgroundColor: "#0005",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }} //black overlay
        >
          <div
            style={{
              fontFamily: "Abel",
              fontSize: 50,
              color: "white",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            {title}
          </div>
        </div>
      </div>
    );
  }
  // renderButton(bgImage, title) {
  //   return (
  //     <div
  //       className={"shadowOnHover"}
  //       className={"wellbeingButton"}
  //       style={{
  //         width: "500px",
  //         height: "500px",
  //         backgroundColor: "red",
  //         margin: 10,
  //         background: "url(" + bgImage + ")",
  //         backgroundSize: "cover",
  //         backgroundRepeat: "no-repeat",
  //         backgroundPosition: "50% 50%",
  //         // opacity: "0.4",
  //         padding: 30,
  //         display: "flex",
  //         borderRadius: 10,
  //         cursor: "pointer",
  //       }}
  //     >
  //       <div
  //         style={{
  //           fontFamily: "ArchitectsDaughter",
  //           fontSize: 50,
  //           opacity: 1,
  //           width: "100%",
  //           backgroundColor: "#0003",
  //           alignSelf: "flex-end",
  //           justifySelf: "flex-end",
  //           textAlign: "right",
  //           padding: 10,
  //           borderRadius: 10,
  //           color: "#fff",
  //         }}
  //       >
  //         {title}
  //       </div>
  //     </div>
  //   );
  // }

  // renderIntroText() {
  //   return (
  //     <div
  //       style={{
  //         height: "auto",
  //         width: "90%",
  //         padding: 20,
  //         margin: 10,
  //         display: "flex",
  //         flexDirection: "column",
  //         padding: 10,
  //         // backgroundColor: "#fff",
  //         color: "#000",
  //         borderRadius: 10,
  //         fontSize: 20,
  //       }}
  //     >
  //       {" "}
  //       <div
  //         style={{
  //           padding: "5px",
  //           paddingBottom: 10,
  //           fontSize: "40px",
  //           fontWeight: "700",
  //           fontFamily: "ArchitectsDaughter",
  //         }}
  //       >
  //         Wellbeing
  //       </div>
  //       Our well-being is directly related to our feeling of satisfaction with
  //       life. To be well is to feel good as a whole, always remembering that we
  //       are in a constant process of transformation and adaptation to the
  //       changes around us.
  //       <br />
  //       <br />
  //       We present some suggestions to strengthen our body by increasing our
  //       self-esteem in order to enjoy a more serene life, with greater
  //       acceptance and fluidity.
  //     </div>
  //   );
  // }
}
