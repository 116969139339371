import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import "./../style/home.css";
import hausLogoComplete from "../media/localImages/logo_horizontal.png";

import hausLogoJustLogo from "../media/logoHaus_green.png";

var styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    left: "36px",
    top: "45px",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#fff6e8",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};
export default class HamburgerMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
    };
  }

  render() {
    return (
      <div id="outer-container">
        <Menu
          left
          styles={styles}
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
        >
          <Link to="/">
            <div
              onClick={() => this.closeMenu()}
              style={{
                display: "flex",
                backgroundImage: `url(${hausLogoComplete})`,
                width: window.pageYOffset <= 100 ? "240px" : "100px",
                marginTop: window.pageYOffset <= 100 ? 0 : 50,
                height: window.pageYOffset <= 100 ? "95px" : "90px",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                zIndex: 1000,
                backgroundColor:
                  window.pageYOffset <= 100 ? "#fff6e8" : "#f4f9f4aa",
                borderRadius: window.pageYOffset <= 100 ? 0 : "50%",
              }}
            ></div>
          </Link>
          <br />
          <Link to="/login">
            <div
              onClick={() => this.closeMenu()}
              //  onClick={() => this.filteredClasses("duration", 60)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
                width: "200px",
                border: "0.5px solid rgb(86,94,80)",
                borderRadius: "5px",
                backgroundColor: "#faf9f7",
                color: "rgb(86,94,80)",
                fontSize: "14px",
                fontWeight: "600",
                transition: "all .2s ease-in-out",
                cursor: "pointer",
                whiteSpace: "nowrap",
                fontWeight: 400,
                margin: 10,
              }}
            >
              Login
            </div>
          </Link>
          <br />
          <Link to="/register">
            <div
              onClick={() => this.closeMenu()}
              //  onClick={() => this.filteredClasses("duration", 60)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
                width: "200px",
                border: "0.5px solid rgb(86,94,80)",
                borderRadius: "5px",
                backgroundColor: "rgb(86,94,80)",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "600",
                transition: "all .2s ease-in-out",
                cursor: "pointer",
                whiteSpace: "nowrap",
                fontWeight: 400,
                margin: 10,
              }}
            >
              Register
            </div>
          </Link>
        </Menu>

        <main id="page-wrap">
          <div
            style={{
              display: "flex",
              width: "auto",
              alignSelf: "center",
              backgroundImage: `url(${hausLogoComplete})`,
              //width: window.pageYOffset <= 100 ? "240px" : "100px",
              //marginTop: window.pageYOffset <= 100 ? 0 : 50,
              height: window.pageYOffset <= 100 ? "95px" : "90px",
              margin: 15,
              marginLeft: 80,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              zIndex: 1000,
              backgroundColor: "#fff",
              //  borderRadius: window.pageYOffset <= 100 ? 0 : "50%",
            }}
          ></div>
        </main>
      </div>
    );
  }

  closeMenu() {
    console.log("done");
    this.setState({ menuOpen: false });
  }
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }
}
