import React from "react";
import { slide as Menu } from "react-burger-menu";
import "./../style/home.css";
import { Link } from "react-router-dom";
import hausLogoComplete from "../media/localImages/logo_horizontal.png";

import hausLogoJustLogo from "../media/logoHaus_green.png";

var styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    left: "36px",
    top: "45px",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#faf9f7",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};
export default class HamburgerMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
    };
  }

  render() {
    // console.log("this.state.menuOpen", this.state.menuOpen);
    return (
      <div id="outer-container">
        <Menu
          left
          styles={styles}
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
        >
          <Link to="/">
            <div
              onClick={() => this.closeMenu()}
              className={"menuItem"}
              style={{ color: "#000", fontWeight: 500 }}
            >
              HAUS
            </div>
          </Link>
          <br />
          <Link to="/classes">
            <div
              onClick={() => this.closeMenu()}
              className={"menuItem"}
              style={{ color: "#000" }}
            >
              Classes
            </div>
          </Link>
          <br />
          <Link to="/teachers">
            <div
              className={"menuItem"}
              style={{ color: "#000" }}
              onClick={() => this.closeMenu()}
            >
              Teachers
            </div>
          </Link>
          <br />
          <Link to="/wellbeing">
            <div
              className={"menuItem"}
              style={{ color: "#000" }}
              onClick={() => this.closeMenu()}
            >
              Wellbeing
            </div>
          </Link>
          <br />
          {/* <Link to="/users">
            <div className={"menuItem"} style={{ color: "#000" }}>
              Users
            </div>
          </Link> */}
          <br />
          <Link to="/account">
            <div
              onClick={() => this.closeMenu()}
              //  onClick={() => this.filteredClasses("duration", 60)}
              style={{
                display: "flex",
                height: "40px",
                width: "auto",
                padding: "8px 20px",
                border: "0.5px solid #000",
                borderRadius: "24px",
                backgroundColor: "#faf9f7",
                color: "#000",
                fontSize: "14px",
                transition: "all .2s ease-in-out",
                cursor: "pointer",
                whiteSpace: "nowrap",
                fontWeight: 400,
                margin: 10,
              }}
            >
              My account
            </div>
          </Link>
        </Menu>
        <main id="page-wrap">
          {" "}
          <div
            style={{
              display: "flex",
              width: "auto",
              alignSelf: "center",
              backgroundImage: `url(${hausLogoComplete})`,
              //width: window.pageYOffset <= 100 ? "240px" : "100px",
              //marginTop: window.pageYOffset <= 100 ? 0 : 50,
              height: window.pageYOffset <= 100 ? "95px" : "90px",
              margin: 15,
              marginLeft: 80,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              zIndex: 1000,
              backgroundColor: "#fff",
              //  borderRadius: window.pageYOffset <= 100 ? 0 : "50%",
            }}
          ></div>{" "}
        </main>{" "}
      </div>
    );
  }
  closeMenu() {
    console.log("done");
    this.setState({ menuOpen: false });
  }
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }
}
