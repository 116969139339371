import {
  UserOutlined,
  VideoCameraTwoTone,
  ClockCircleTwoTone,
} from "@ant-design/icons";
import { Input, message, Modal, Select, Switch, Tabs, Rate } from "antd";
import React from "react";
import {
  putMixedContentInfo,
  saveNewMixedContent,
} from "./../network/basicContent";

import ClassPhotos from "./UploadClassPhoto";
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const languagePossibilities = [
  <Option key={"Portuguese"}>{"Portuguese"}</Option>,
  <Option key={"English"}>{"English"}</Option>,
  <Option key={"Spanish"}>{"Spanish"}</Option>,
];
const classesPossibilities = [
  <Option key={"Recipe"}>{"Recipe"}</Option>,
  // <Option key={"Pilates"}>{"Pilates"}</Option>,
  // <Option key={"Gym"}>{"Gym"}</Option>,
];

export default class EditMixedContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      title: "",
      description: "",
      photos: "",
      videos: [],
      isVisible: false,
      difficulty: "",
      duration: "",
      language: [],
      type: "",
    };
  }

  async componentDidMount() {
    //also gets type of content and sets State on mount -- e.g type ==="food"
    console.log("this.props.type", this.props.type);
    if (this.props.type) {
      this.setState({ type: this.props.type });
    }
    if (this.props.creatingNewClass) {
    } else {
      this.setState({
        ...this.props.classInfo,
      });
    }
  }

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };
  handleOk = (e) => {
    // console.log(e);
    this.setState({
      modalVisible: false,
    });
    window.location.reload();
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      modalVisible: false,
    });
  };

  handleChangeClass(value) {
    console.log(`selected`, value);
    this.setState({ type: value });
  }

  handleChangeLanguage(value) {
    console.log(`selected`, value);

    this.setState({ language: value });
  }

  handleAddTeachersToClass(value) {
    console.log(`selected`, value);

    this.setState({ teachers: value });
  }

  renderEditTeacherPanel() {
    return (
      <div
        style={{
          color: "black",
          minHeight: "130px",
          height: "auto",
          width: "100%",
          backgroundColor: "#fff",
          padding: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div
          onClick={(e) => this.showModal(e)}
          style={{
            display: "flex",
            height: "50px",
            width: "auto",
            justifyContent: "center",
            alignItems: "center",
            //padding: "20px",
            padding: "10px",
            border: "0.5px solid #000",
            borderRadius: "24px",
            backgroundColor: "#faf9f7",
            color: "#000",
            fontSize: "12px",
            cursor: "pointer",
            fontWeight: 600,
            margin: 5,
          }}
        >
          {this.props.creatingNewClass ? "Add new content" : "Edit"}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ backgroundColor: "#faf9f7" }}>
        {this.renderEditTeacherPanel()}
        {this.renderEditTeacherModal()}
      </div>
    );
  }

  renderTeacherInfoPanel() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          alignItems: "flex-end",
          width: "100%",
          height: "auto",
        }}
      >
        {/* <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Tags Mode"
          onChange={this.handleChangeLanguage}
        >
          {this.state.teacherLanguages.map((language, index) => (
            <Option key={index}>{language}</Option>
          ))}
        </Select> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%",
            height: "auto",

            padding: 20,
          }}
        >
          <div
            style={{
              height: "auto",
              width: "200px",
              margin: 10,
            }}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Languages"
              value={this.state.language}
              onChange={(value) => this.handleChangeLanguage(value)}
            >
              {languagePossibilities}
            </Select>
          </div>
          {/* <div style={{ height: "auto", width: "200px", margin: 10 }}>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Lesson type"
              value={this.state.type}
              onChange={(value) => this.handleChangeClass(value)}
            >
              {classesPossibilities}
            </Select>
          </div> */}

          <div
            style={{
              height: "auto",
              width: "200px",
              padding: 5,
              margin: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>Visible to public?</div>
            <Switch
              checked={this.state.isVisible}
              onChange={(isVisible) => this.setState({ isVisible })}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            fontSize: 13,
            alignSelf: "flex-start",
            padding: 20,
          }}
        ></div>
        <Input
          style={{ marginBottom: 20 }}
          size="large"
          placeholder="Title"
          prefix={<UserOutlined />}
          value={this.state.title}
          onChange={(input) => this.setState({ title: input.target.value })}
        />
        {/* {this.props.type === "food " ? ( */}
        <div style={{ width: "100%" }}>
          <div style={{ fontSize: 13, marginTop: 30 }}>
            Video VIMEO link (If available)
          </div>
          <Input
            style={{ marginBottom: 20 }}
            size="large"
            placeholder="Video Link"
            prefix={<VideoCameraTwoTone />}
            value={this.state.videos[0]}
            onChange={(input) =>
              this.setState({ videos: [input.target.value] })
            }
          />
        </div>
        {/* ) : (
          <div>teste</div>
        )} */}

        {/* <div style={{ fontSize: 13, marginTop: 30, alignSelf: "flex-start" }}>
          Creator
        </div>
        <Input
          style={{ marginBottom: 20 }}
          size="large"
          placeholder="Title"
          prefix={<UserOutlined />}
          value={this.state.creator}
          onChange={(input) => this.setState({ creator: input.target.value })}
        /> */}
        <div style={{ fontSize: 13, marginTop: 30, alignSelf: "flex-start" }}>
          Description
        </div>
        <TextArea
          rows={8}
          value={this.state.description}
          onChange={(input) =>
            this.setState({ description: input.target.value })
          }
        />
        <div
          onClick={async () => {
            if (this.props.creatingNewClass) {
              console.log("Saving new");
              await this.saveNewMixedContent();
            } else {
              console.log("Saving changes");
              await this.saveAllChanges();
            }
          }}
          style={{
            display: "flex",
            width: "150px",
            margin: 10,
            padding: "12px 24px",
            border: "1px solid #000",
            borderRadius: "24px",
            backgroundColor: "#000",
            color: "#fff",
            fontSize: "16px",
            transition: "all .2s ease-in-out",
            cursor: "pointer",
            whiteSpace: "nowrap",
          }}
        >
          Save changes
        </div>
      </div>
    );
  }

  async saveNewMixedContent() {
    await saveNewMixedContent({
      ...this.state,
    }).then((result) => {
      console.log("result of saving: ", result);

      if (result.status == 200) {
        message.success("Creator info saved!");
      } else {
        message.error("Info not saved. Check your connection.");
      }
    });
  }
  async saveAllChanges() {
    await putMixedContentInfo(this.props.classInfo._id, {
      ...this.state,
    }).then((result) => {
      console.log("result of saving: ", result);

      if (result.status == 200) {
        message.success("Creator info saved!");
      } else {
        message.error("Info not saved. Check your connection.");
      }
    });
  }
  renderEditTeacherModal() {
    return (
      <Modal
        title="Add Content"
        visible={this.state.modalVisible}
        onOk={(e) => this.handleOk(e)}
        onCancel={(e) => this.handleCancel(e)}
        style={{ height: "80vh" }}
        width={"80vw"}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Content" key="1">
            {this.renderTeacherInfoPanel()}
          </TabPane>
          {!this.props.creatingNewClass ? (
            <TabPane tab=" Photos" key="2">
              <ClassPhotos
                classInfo={this.props.classInfo}
                isMixedContent={true}
              />
            </TabPane>
          ) : null}
        </Tabs>
      </Modal>
    );
  }
}
