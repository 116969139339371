import React from "react";
import { Row, Col, Tabs } from "antd";
import { Auth } from "aws-amplify";
import { Redirect } from "react-router-dom";
import PaymentSettings from "../components/PaymentSettings";

const { TabPane } = Tabs;

export default class Account extends React.Component {
  constructor(props) {
    super(props);

    this.state = { redirectToLogin: false };
  }

  tabChanged(key) {
    console.log(key);
  }

  async signOut() {
    try {
      return await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  redirectToLogin() {
    if (this.state.redirectToLogin) {
      return <Redirect to="/" />;
    } else {
      return "";
    }
  }

  componentDidMount() {}

  render() {
    //console.log("subscriptionOK ACCOUNT:", this.props.subscriptionOK);
    return (
      <div
        style={{
          backgroundColor: "#faf9f7",
          height: "100%",
          width: "100%",
          padding: 20,
        }}
      >
        {/* {this.renderSpacingFromPageTop()} */}
        <Row>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                padding: "10px",
                fontSize: "26px",
                fontWeight: "500",
                alignSelf: "start",
              }}
            >
              Account Settings
            </div>
            <div
              onClick={async () => {
                await this.signOut();
                this.setState({ redirectToLogin: true });
                window.location.reload();
              }}
              style={{
                display: "flex",
                height: "40px",
                width: "95px",
                padding: "8px 20px",
                border: "0.5px solid #000",
                borderRadius: "24px",
                backgroundColor: "#faf9f7",
                color: "#000",
                fontSize: "14px",
                transition: "all .2s ease-in-out",
                cursor: "pointer",
                whiteSpace: "nowrap",
                fontWeight: 400,
                margin: 10,
              }}
            >
              Logout
              {this.redirectToLogin()}
            </div>
          </div>
        </Row>

        <Tabs defaultActiveKey="1" onChange={this.tabChanged}>
          <TabPane tab="Billing" key="1">
            <PaymentSettings
              user={this.props.user}
              subscriptionOK={this.props.subscriptionOK}
            />
          </TabPane>
          {/* <TabPane tab=" User Info" key="2">
            User Info
          </TabPane> */}
        </Tabs>
      </div>
    );
  }

  renderSpacingFromPageTop() {
    return <div style={{ height: 165, width: "100%" }}></div>;
  }
}
