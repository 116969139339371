import React, { useContext } from "react";
import { Col, Row, Form, Input, Spin, message, notification } from "antd";
import { Auth } from "aws-amplify";
import { Redirect } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import hausLogoJustLogo from "../../media/logoHaus_green.png";
import { SmileOutlined } from "@ant-design/icons";
import { AppContext } from "../../components/AppContext";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const openNotification = () => {
  notification.open({
    message: "Check you email.",
    description: "New verification link sent.",
    icon: <SmileOutlined style={{ color: "#888" }} />,
    duration: 5,
  });
};
export default class ReConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      family_name: "",
      given_name: "",
      redirectToHome: false,
      isLoading: false,
      insertCode: false,
    };
  }

  render() {
    return (
      <div>
        <AppContext.Consumer>
          {(context) => (
            <Row>
              {this.state.redirectToHome ? <Redirect to="/login" /> : null}
              <Col xs={{ span: 24 }} md={{ span: 10 }}>
                <div
                  style={{
                    width: "100%",
                    height: "85vh",
                    overflowY: "scroll",
                    backgroundColor: "rgb(218,228,211)",
                    padding: "5vw",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      // height: "400px",
                      backgroundColor: "rgb(218,228,211)",
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    <div
                      style={{
                        backgroundImage: `url( ${hausLogoJustLogo})`,
                        height: 100,
                        width: 100,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                    <div
                      style={{
                        fontFamily: "Abel",
                        fontSize: 35,
                        fontWeight: 400,
                        margin: 20,
                      }}
                    >
                      Resend verification link
                    </div>
                    <Form
                      style={{
                        width: "100%",
                        maxWidth: "400px",
                        height: "auto",
                        backgroundColor: "rgb(218,228,211)",
                      }}
                      name="basic"
                      initialValues={{ remember: true }}
                      // onFinish={onFinish}
                      // onFinishFailed={onFinishFailed}
                    >
                      <div>
                        <Form.Item
                          // label="Email"
                          style={{ width: "100%", margin: "5px" }}
                          name="email"
                          large
                          rules={[
                            {
                              type: "email",
                              required: true,
                              message: "Please input your email.",
                            },
                          ]}
                        >
                          <Input
                            onChange={(input) =>
                              this.setState({ email: input.target.value })
                            }
                            placeholder={"Email"}
                            style={{
                              fontFamily: "roboto",
                              fontSize: 18,
                              border: "0.5px solid #000",
                              borderRadius: 12,
                              width: "100%",
                              maxWidth: 390,
                              height: 40,
                            }}
                          />
                        </Form.Item>

                        <Form.Item style={{ width: "100%", margin: "5px" }}>
                          {/* <Button type="primary"  htmlType="submit"> */}
                          <div
                            // htmlType="submit"
                            onClick={
                              async () =>
                                await Auth.resendSignUp(this.state.email)
                                  .then(async (data) => {
                                    console.log(data);
                                    openNotification();
                                    await new Promise((resolve) =>
                                      setTimeout(resolve, 3000)
                                    );
                                    this.setState({
                                      redirectToHome: true,
                                      isLoading: false,
                                    });
                                  })
                                  .catch((err) => message.error(err.message))
                              // this.signIn()
                            }
                            style={{
                              display: "flex",
                              height: "40px",
                              width: "100%",
                              maxWidth: "390px",
                              justifyContent: "center",
                              alignItems: "center",
                              //margin: "5px",
                              border: "0.5px solid #000",
                              borderRadius: "12px",
                              backgroundColor: "rgb(86,94,80)",
                              color: "#fff",
                              fontSize: "14px",
                              transition: "all .2s ease-in-out",
                              cursor: "pointer",
                              //  whiteSpace: "nowrap",
                              fontWeight: 500,
                              //margin: 5,
                            }}
                          >
                            Resend verification link
                          </div>

                          <div>
                            <div
                              style={{
                                margin: 2,
                                color: "#000",
                                fontSize: 10,
                              }}
                            >
                              ©2020 Thaís Caniceiro Unipessoal Lda
                            </div>
                            <div
                              style={{
                                margin: 2,
                                color: "#000",
                                fontSize: 10,
                              }}
                            >
                              All rights reserved
                            </div>
                          </div>
                        </Form.Item>
                      </div>
                    </Form>
                    {this.state.isLoading ? (
                      <Spin style={{ zIndex: 1000 }} indicator={antIcon} />
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 14 }}>
                <div
                  style={{
                    width: "100%",
                    height: "85vh",
                    backgroundImage:
                      'url("https://source.unsplash.com/hHzzdVQnkn0")',
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              </Col>
            </Row>
          )}
        </AppContext.Consumer>
      </div>
    );
  }

  async signIn() {
    let email = this.state.email;
    let password = this.state.password;
    this.setState({ isLoading: true });
    const hide = message.loading("Login in ...", 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 2500);

    try {
      await Auth.signIn({
        username: email,
        password,
      }).then((result) => {
        console.log("login result: ", result);
        this.setState({ redirectToHome: true, isLoading: false });
        window.location.reload();
      });
    } catch (error) {
      console.log("error signing up:", error);
      message.error({
        content: error.message,
        className: "custom-class",
        style: {
          marginTop: "20vh",
        },
      });
      this.setState({ isLoading: false });

      // throw error;
    }
  }
}
