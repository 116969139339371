import {
  faClock,
  faLanguage,
  faLevelUpAlt,
  faMale,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import React from "react";
import ReactPlayer from "react-player";
import "../style/classPage.css";
import EditClasses from "./../components/EditClassesModal";
import { getOneClass, getClasses } from "./../network/basicContent";

import durationIcon from "../media/localImages/classIcons/Time-Icon2.png";
import levelIcon from "../media/localImages/classIcons/Level-Icon2.png";
import languageIcon from "../media/localImages/classIcons/Language-Icon2.png";
import practiceIcon from "../media/localImages/classIcons/Practice-Icon2.png";
import propsIcon from "../media/localImages/classIcons/Props-Icon2.png";

import { Link } from "react-router-dom";
export default class Classes extends React.Component {
  constructor(props) {
    super(props);

    this.state = { classInfo: null, loadVideo: false };
  }
  async componentDidMount() {
    let classId = this.props.match.params.classId;
    let classInfo = await getOneClass(classId);
    let classes = await getClasses();

    this.setState({ classInfo, loadVideo: true, classes });
  }

  async getClassInfo(classId) {
    //  let classId = this.props.match.params.classId;
    let classInfo = await getOneClass(classId);
    let classes = await getClasses();

    this.setState({ classInfo, loadVideo: true, classes });
  }

  render() {
    return (
      <div style={{ backgroundColor: "#fff" }}>
        {this.state.classInfo ? (
          <div>
            {this.props.user.role === "admin" ? (
              <EditClasses
                classInfo={this.state.classInfo}
                creatingNewClass={false}
              />
            ) : null}
            {this.renderClassSpecs()}
            {this.state.loadVideo && this.state.classInfo.classVideo[0] ? (
              this.renderVideoPlayer(this.state.classInfo.classVideo[0])
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: 300,
                  border: "1px solid #000",
                }}
              >
                <div> Class video not yet available</div>
              </div>
            )}
            {this.renderClassesGrid()}
          </div>
        ) : (
          <dvi>loading</dvi>
        )}
      </div>
    );
  }

  renderSpacingFromPageTop() {
    return <div style={{ height: 150, width: "100%" }}></div>;
  }

  renderClassSpecs(classSpecs) {
    return (
      <Row type="flex" align="start" justify="center">
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <div
            style={{
              width: "auto",
              height: "auto",
              // backgroundColor: "#faf9f7",
              borderRadius: "20px",
              margin: 10,
            }}
          >
            <div
              style={{ fontSize: 30, padding: 0, lineHeight: 2, color: "#000" }}
            >
              {this.state.classInfo.title}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <div>with</div>
              {this.state.classInfo.teachers.map((teacher) => (
                <Link to={"/teachers/" + teacher._id}>
                  <div
                    // onClick={() => {}}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignContent: "center",
                      // height: "40px",
                      padding: 5,
                      width: "auto",
                      border: "0.5px solid #000",
                      borderRadius: "24px",
                      backgroundColor: "#faf9f7",
                      color: "#000",
                      fontSize: "12px",
                      transition: "all .2s ease-in-out",
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                      margin: 5,
                    }}
                  >
                    {teacher.name}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </Col>

        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <div
            style={{
              width: "100%",

              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              flexWrap: "wrap",
              margin: 0,
            }}
          >
            {/* {this.renderSpec(
              "PRACTICE",
              this.state.classInfo.typeOfClass[0],
              practiceIcon
            )} */}
            <div>
              {this.renderSpec(
                "LEVEL",
                this.state.classInfo.difficulty,
                levelIcon
              )}
              {this.renderSpec(
                "TIME",
                this.state.classInfo.duration + " min",
                durationIcon
              )}
            </div>
            <div>
              {this.renderSpec(
                "LANGUAGE",
                this.state.classInfo.language[0],
                languageIcon
              )}
              {this.renderSpec(
                "PROPS",
                this.state.classInfo.classRequirements,
                propsIcon
              )}{" "}
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  renderSpec(key, value, icon) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "160px",
          height: "100px",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "60px",
            height: "60px",
            padding: 10,
            backgroundImage: `url(${icon})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div
          //Difficulty
          style={{
            height: 70,
            width: "auto",
            overflow: "hidden",
            // backgroundColor: "#fff",
            color: "#000",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "left",
            padding: 5,
          }}
        >
          <div
            style={{
              fontSize: 18,
              padding: 0,
              lineHeight: 2,
              fontFamily: "Abel",
              color: "#444",
            }}
          >
            {key}
          </div>
          <div
            style={{
              fontSize: 12,
              padding: 0,
              lineHeight: 1,
              fontWeight: 700,
              color: "#444",
            }}
          >
            {value}
          </div>
        </div>
      </div>
    );
  }
  renderClassesGrid() {
    return (
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <div
            style={{
              color: "black",
              height: "auto",
              width: "100%",
              padding: "10vh 2vw 2vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                //paddingTop: 50,
                padding: "10px",
                fontSize: "20px",
                fontWeight: "500",
                alignSelf: "start",
                fontFamily: "Abel",
              }}
            >
              Some classes from{" "}
              {this.state.classInfo.teachers[0]?.name
                ? this.state.classInfo.teachers[0].name
                : "this teacher"}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                whiteSpace: "wrap",
                justifyContent: "center",
                alignItems: "center",
                // overflowX: "scroll",
                // overflowY: "hidden",
                width: "100%",
                height: "auto",
              }}
            >
              {this.state.classInfo.teachers[0] &&
                this.state.classes.map((_class) => {
                  if (
                    //filter classes from this teacher
                    _class.teachers.some(
                      (teacher) =>
                        teacher.name === this.state.classInfo.teachers[0].name
                    )
                  )
                    return (
                      <Link to={"/classes/" + _class._id}>
                        <div
                          onClick={() => {
                            this.getClassInfo(_class._id);
                            window.scrollTo(0, 0);
                          }}
                          className={"shadowOnHover"}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "400px",
                            width: "300px",
                            backgroundColor: "#ccc",
                            margin: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${
                                _class.photos[0]
                                  ? _class.photos[0]
                                  : "https://haus-bucket-2020.s3.amazonaws.com/public/No_picture_available.png"
                              })`,
                              width: "100%",
                              height: "300px",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              zIndex: 500,
                            }}
                          ></div>
                          <div
                            style={{
                              backgroundColor: "#fff",
                              height: "120px",
                              width: "100%",
                              padding: "10px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 15,
                                color: "#000",
                                padding: 5,
                              }}
                            >
                              {_class.title}
                            </div>
                            {/* <div
                          style={{ fontSize: 10, color: "#000", padding: 5 }}
                        >
                          with <strong>{_class.teachers[0].name}</strong>
                        </div> */}

                            <div
                              style={{
                                display: "flex",
                                flex: "row",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: 18,
                                  color: "#888",
                                  padding: 5,
                                }}
                              >
                                {_class.typeOfClass}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: 12,

                                  color: "#000",
                                  padding: 5,
                                  fontWeight: 500,
                                  width: "auto",
                                  height: "40px",
                                  //  borderRadius: "50%",
                                  //  border: "1px solid #888",
                                }}
                              >
                                Time: {_class.duration}min
                              </div>
                              <div
                                style={{
                                  fontSize: 13,
                                  color: "#000",
                                  padding: 5,
                                  fontWeight: 500,
                                }}
                              >
                                Level: {_class.difficulty}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                })}
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  renderVideoPlayer(videoLink) {
    //FIX - https://vimeo.com/428201296 this is a normal VIMEO url that is not embeded. We need to extract the ID of the video on VIMEO
    //The problem - administrators can not properly copy the embeded code, so we have to make a hack, making their life easy - copying the simple vimeo link
    let videoVimeoId = videoLink.split("/").pop();
    //maybe temporary
    //console.log("videoVimeoId,videoLink", videoVimeoId, videoLink);
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 20,
          backgroundColor: "#eee",
        }}
      >
        <iframe
          src={"https://player.vimeo.com/video/" + videoVimeoId}
          width="1200"
          height="700"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullscreen
        ></iframe>
        {/* <ReactPlayer
          url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
          width="100%"
          height="80vh"
          config={{
            youtube: {
              playerVars: {
                modestbranding: 1,
                rel: 0,
                controls: 0,
                showinfo: 0,
              },
            },
          }}
        /> */}
      </div>
    );
  }
}
