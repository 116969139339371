import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { getClasses } from "../network/basicContent";
import EditClassesModal from "./../components/EditClassesModal";
import moment from "moment";
import "../style/classes.css";
export default class Classes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      classes: [],
      filteredClasses: [],
      filter: {
        duration: "all",
        language: "all",
        practice: "all",
        level: "all",
      },
    };
  }
  async componentDidMount() {
    // console.log("this.props", this.props);

    let classes = await getClasses();
    let sortedClasses = [];
    classes.forEach((_class, index) => {
      console.log("Lets see ", _class.title);
      if (_class.updatedAt) {
        //get all classes with valid date and get timestamp
        _class.timestamp = moment(_class.updatedAt).unix();
        // console.log(
        //   "moving for ",
        //   _class.title,
        //   _class.timestamp,
        //   _class.updatedAt
        // );
        sortedClasses.push(_class);
      } else {
        // classes.splice(index, 1);
        //console.log("nope...");
      }
    });
    classes.forEach((_class, index) => {
      //get all classes with invalid date
      if (!_class.updatedAt) {
        sortedClasses.push(_class);
      }
    });

    sortedClasses = sortedClasses.sort(dynamicSort("-timestamp"));

    //classes = classes.sort(dynamicSort("-timestamp"));
    console.log("classes", sortedClasses);

    //console.log("this.props.user", this.props.user);
    this.setState({ classes: sortedClasses, filteredClasses: sortedClasses });

    if (!!this.props.match.params.typeId) {
      let classType = this.props.match.params.typeId;
      console.log("classType", classType);
      if (classType === "fitness") {
        this.filteredClasses("practice", "Fitness");
      }
      if (classType === "yoga") {
        this.filteredClasses("practice", "Yoga");
      }
      if (classType === "meditation") {
        this.filteredClasses("practice", "Meditation");
      }
    }
  }
  render() {
    return (
      <div style={{ backgroundColor: "#f6f6f6" }}>
        {this.props.user.role === "admin" ? (
          <EditClassesModal classInfo={null} creatingNewClass={true} />
        ) : (
          this.renderSpacingFromPageTop()
        )}
        {this.renderPersonalize()}
        {this.renderClassFilters()}
        {this.renderClassesGrid()}
      </div>
    );
  }

  renderPersonalize() {
    return (
      <div
        style={{ margin: 30, marginLeft: 50, fontFamily: "Abel", fontSize: 25 }}
      >
        Personalize your class
      </div>
    );
  }

  filteredClasses(field, value) {
    let filterObject = this.state.filter;
    filterObject[field] = value;
    this.setState({ filter: filterObject });

    console.log("filterObject", filterObject);

    let filteredClasses = this.state.classes;

    //Filter duration
    filteredClasses = filteredClasses.filter(
      (_class) =>
        _class.duration <= this.state.filter.duration ||
        this.state.filter.duration === "all"
    );

    //filter language
    filteredClasses = filteredClasses.filter(
      (_class) =>
        _class.language.includes(this.state.filter.language) ||
        this.state.filter.language === "all"
    );
    //filter level
    filteredClasses = filteredClasses.filter(
      (_class) =>
        _class.difficulty === this.state.filter.level ||
        this.state.filter.level === "all"
    );
    //filter PRActice
    filteredClasses = filteredClasses.filter(
      (_class) =>
        _class.typeOfClass.includes(this.state.filter.practice) ||
        this.state.filter.practice === "all"
    );

    this.setState({ filteredClasses });

    console.log("filteredClasses", filteredClasses);
  }

  renderSpacingFromPageTop() {
    return <div style={{ height: 20, width: "100%" }}></div>;
  }
  renderClassesGrid() {
    return (
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <div
            style={{
              color: "black",
              height: "auto",
              width: "100%",
              padding: "2vh 2vw 2vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <div
              style={{
                padding: "10px",
                fontSize: "26px",
                fontWeight: "500",
                alignSelf: "start",
              }}
            >
              Our Classes
            </div> */}
            <div
              style={{
                display: "flex",
                paddingTop: 30,
                flexDirection: "row",
                flexWrap: "wrap",
                whiteSpace: "wrap",
                justifyContent: "center",
                alignItems: "center",
                // overflowX: "scroll",
                // overflowY: "hidden",
                width: "100%",
                height: "auto",
              }}
            >
              {this.state.filteredClasses &&
              this.state.filteredClasses.length > 0 ? (
                this.state.filteredClasses.map((_class, index) => (
                  <Link to={"/classes/" + _class._id} key={index}>
                    {_class._id === this.state.classOnHover
                      ? this.renderClassDivOnHover(_class)
                      : this.renderClassDiv(_class)}
                  </Link>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "300px",
                    padding: "10px",
                    fontSize: "26px",
                    fontWeight: "500",
                    alignSelf: "start",
                    color: "rgb(86,94,80)",
                  }}
                >
                  No classes with this filters
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  renderClassDivOnHover(_class) {
    return (
      <div
        className={"classShadowOnHover"}
        onMouseLeave={() => this.setState({ classOnHover: null })}
        style={{
          // backfaceVisibility: "false",
          display: "flex",
          flexDirection: "column",
          height: "420px",
          width: "300px",
          margin: "10px",
          zIndex: 100,
          backgroundColor: "#444",
          transition: "background 0.5s",
          zIndex: 1000,
          padding: 20,
        }}
      >
        <div
          style={{
            color: "#fff",
            fontFamily: "roboto",
            fontSize: 20,
            fontWeight: 600,
            paddingBottom: 10,
          }}
        >
          {_class.title}
        </div>
        <div style={{ color: "#fff", fontFamily: "roboto" }}>
          {_class.description}
        </div>
        <div
          style={{
            // backgroundColor: "#fff",
            height: "120px",
            width: "100%",
            padding: "10px",
          }}
        >
          <div style={{ fontSize: 10, color: "#fff", padding: 5 }}>
            with <strong>{_class.teachers[0].name}</strong>
          </div>

          <div
            style={{
              display: "flex",
              flex: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,

                color: "#fff",
                padding: 5,
                fontWeight: 500,
                width: "auto",
                height: "40px",
                //  borderRadius: "50%",
                //  border: "1px solid #888",
              }}
            >
              Time: {_class.duration}min
            </div>
            <div
              style={{
                fontSize: 13,
                color: "#fff",
                padding: 5,
                fontWeight: 500,
              }}
            >
              Level: {_class.difficulty}
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderClassDiv(_class) {
    return (
      <div
        className={"classShadowOnHover"}
        onMouseEnter={() => {
          console.log(_class._id);
          this.setState({ classOnHover: _class._id });
        }}
        onMouseLeave={() => this.setState({ classOnHover: null })}
        style={{
          // backfaceVisibility: "false",
          display: "flex",
          flexDirection: "column",
          height: "420px",
          width: "300px",
          margin: "10px",
          zIndex: 100,
          backgroundColor: "#ddd",
          transition: "all 0.5s",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            backgroundImage: `url(${
              _class.photos[0]
                ? _class.photos[0]
                : "https://haus-bucket-2020.s3.amazonaws.com/public/No_picture_available.png"
            })`,
            width: "100%",
            height: "300px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            //   transition: "backgroundImage 0.5s",
            // zIndex: 500,
          }}
        >
          {_class.updatedAt && moment().diff(_class.updatedAt, "days") < 31 ? (
            <div
              style={{
                textAlign: "center",
                margin: 2,
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "5",
                fontSize: 20,
                fontFamily: "Abel",
                width: 50,
                height: 30,
              }}
            >
              New!
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            height: "120px",
            width: "100%",
            padding: "10px",
          }}
        >
          <div style={{ fontSize: 15, color: "#000", padding: 5 }}>
            {_class.title}
          </div>
          <div style={{ fontSize: 10, color: "#000", padding: 5 }}>
            with <strong>{_class.teachers[0].name}</strong>
          </div>

          <div
            style={{
              display: "flex",
              flex: "row",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: 18,
                color: "#888",
                padding: 5,
              }}
            >
              {_class.typeOfClass}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,

                color: "#000",
                padding: 5,
                fontWeight: 500,
                width: "auto",
                height: "40px",
                //  borderRadius: "50%",
                //  border: "1px solid #888",
              }}
            >
              Time: {_class.duration}min
            </div>
            <div
              style={{
                fontSize: 13,
                color: "#000",
                padding: 5,
                fontWeight: 500,
              }}
            >
              Level: {_class.difficulty}
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderFilterButton(buttonName, isSelected, onClickFunction) {
    return (
      <div
        onClick={onClickFunction}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "40px",
          width: "100px",
          padding: "8px 20px",
          border: "0.5px solid #797979",
          borderRadius: "5px",
          backgroundColor: isSelected ? "#797979" : "#faf9f7",
          color: isSelected ? "#faf9f7" : "#797979",
          fontFamily: "Abel",
          fontSize: "16px",
          fontWeight: "600",
          transition: "all .2s ease-in-out",
          cursor: "pointer",
          margin: 5,
        }}
      >
        {buttonName}
      </div>
    );
  }

  renderPRACTICEFilter() {
    return (
      <div /*   FILTER BLOCK */ style={{ width: "auto", marginLeft: 30 }}>
        <div style={{ margin: 5, fontFamily: "Abel", fontSize: 25 }}>
          PRACTICE
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {this.renderFilterButton(
            "All", //button name
            this.state.filter.practice === "all", //is Selected?
            () => this.filteredClasses("practice", "all") //onClick
          )}
          {this.renderFilterButton(
            "Pilates", //button name
            this.state.filter.practice === "Pilates", //is Selected?
            () => this.filteredClasses("practice", "Pilates") //onClick
          )}
          {this.renderFilterButton(
            "Yoga", //button name
            this.state.filter.practice === "Yoga", //is Selected?
            () => this.filteredClasses("practice", "Yoga") //onClick
          )}
          {this.renderFilterButton(
            "Fitness", //button name
            this.state.filter.practice === "Fitness", //is Selected?
            () => this.filteredClasses("practice", "Fitness") //onClick
          )}
          {this.renderFilterButton(
            "Meditation", //button name
            this.state.filter.practice === "Meditation", //is Selected?
            () => this.filteredClasses("practice", "Meditation") //onClick
          )}
        </div>
      </div>
    );
  }
  renderDURATIONFilter() {
    return (
      <div /*   FILTER BLOCK */ style={{ width: "auto", marginLeft: 30 }}>
        <div style={{ margin: 5, fontFamily: "Abel", fontSize: 25 }}>
          TIME AVAILABILITY
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {this.renderFilterButton(
            "All", //button name
            this.state.filter.duration === "all", //is Selected?
            () => this.filteredClasses("duration", "all") //onClick
          )}
          {this.renderFilterButton(
            "<20m", //button name
            this.state.filter.duration === 20, //is Selected?
            () => this.filteredClasses("duration", 20) //onClick
          )}
          {this.renderFilterButton(
            "<40m", //button name
            this.state.filter.duration === 40, //is Selected?
            () => this.filteredClasses("duration", 40) //onClick
          )}
          {this.renderFilterButton(
            "<60m", //button name
            this.state.filter.duration === 60, //is Selected?
            () => this.filteredClasses("duration", 60) //onClick
          )}
        </div>
      </div>
    );
  }
  renderLEVELFilter() {
    return (
      <div /*   FILTER BLOCK */ style={{ width: "auto", marginLeft: 30 }}>
        <div style={{ margin: 5, fontFamily: "Abel", fontSize: 25 }}>LEVEL</div>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {this.renderFilterButton(
            "All", //button name
            this.state.filter.level === "all", //is Selected?
            () => this.filteredClasses("level", "all") //onClick
          )}
          {this.renderFilterButton(
            "Beginner", //button name
            this.state.filter.level === "Beginner", //is Selected?
            () => this.filteredClasses("level", "Beginner") //onClick
          )}
          {this.renderFilterButton(
            "Intermediate", //button name
            this.state.filter.level === "Intermediate", //is Selected?
            () => this.filteredClasses("level", "Intermediate") //onClick
          )}
          {this.renderFilterButton(
            "Advanced", //button name
            this.state.filter.level === "Advanced", //is Selected?
            () => this.filteredClasses("level", "Advanced") //onClick
          )}
        </div>
      </div>
    );
  }
  renderLANGUAGEFilter() {
    return (
      <div /*   FILTER BLOCK */ style={{ width: "auto", marginLeft: 30 }}>
        <div style={{ margin: 5, fontFamily: "Abel", fontSize: 25 }}>
          LANGUAGE
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {this.renderFilterButton(
            "All", //button name
            this.state.filter.language === "all", //is Selected?
            () => this.filteredClasses("language", "all") //onClick
          )}
          {this.renderFilterButton(
            "Portuguese", //button name
            this.state.filter.language === "Portuguese", //is Selected?
            () => this.filteredClasses("language", "Portuguese") //onClick
          )}
          {this.renderFilterButton(
            "English", //button name
            this.state.filter.language === "English", //is Selected?
            () => this.filteredClasses("language", "English") //onClick
          )}
        </div>
      </div>
    );
  }
  renderClassFilters() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          margin: 20,
        }}
      >
        <div>
          {this.renderPRACTICEFilter()}
          {this.renderDURATIONFilter()}
        </div>
        <div>
          {this.renderLEVELFilter()}
          {this.renderLANGUAGEFilter()}
        </div>
      </div>
    );
  }
}

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
