import { Tabs } from "antd";
import React from "react";
import { getOneUser } from "../network/user";

const { TabPane } = Tabs;

export default class Wellbeing extends React.Component {
  constructor(props) {
    super(props);

    this.state = { user: {} };
  }

  async componentDidMount() {
    let userId = this.props.match.params.userId;
    console.log("userId", userId);
    let user = await getOneUser(userId);
    console.log("user?", user);
    if (user) {
      this.setState({ user });
    }
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: "#faf9f7",
          padding: 20,
          height: "85vh",
          width: "100%",
        }}
      >
        <Tabs defaultActiveKey="1" onChange={this.changeTab}>
          <TabPane tab="User Info" key="1">
            <div style={{ overflowY: "scroll", height: "75vh" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // flexWrap: "wrap",
                  padding: 30,
                  height: "auto",
                }}
              >
                <h1>Name:</h1>
                <div style={{ padding: 20, fontSize: 25, fontWeight: 800 }}>
                  {this.state.user.name}
                </div>
                <h3>Age:</h3>
                <div style={{ padding: 20, fontSize: 25, fontWeight: 800 }}>
                  {this.state.user.age}
                </div>

                <h3>Email:</h3>
                <div style={{ padding: 20, fontSize: 20, fontWeight: 500 }}>
                  {this.state.user.email}
                </div>
                <h3>Country:</h3>
                <div style={{ padding: 20, fontSize: 20, fontWeight: 500 }}>
                  {this.state.user.country
                    ? this.state.user.country
                    : "No info Available"}
                </div>

                <h3>Level:</h3>
                <div style={{ padding: 20, fontSize: 20, fontWeight: 500 }}>
                  {this.state.user.level}
                </div>
                <h3>Goals:</h3>
                <div style={{ padding: 20, fontSize: 20, fontWeight: 500 }}>
                  {this.state.user.goals}
                </div>
                {/* whitespace */}
                <div style={{ width: "100%", height: 400 }}></div>
              </div>
            </div>
          </TabPane>
          {/* <TabPane tab="Payments" key="2">
            To Do
          </TabPane> */}
        </Tabs>
      </div>
    );
  }

  changeTab() {}
}
