import React from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Checkbox,
  message,
  notification,
  Select,
  Result,
  Spin,
} from "antd";
import Amplify, { Auth } from "aws-amplify";

import awsconfig from "./../../aws-exports";
import { SmileOutlined, LoadingOutlined } from "@ant-design/icons";

import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { registerUser } from "../../network/user";
import hausLogoJustLogo from "../../media/logoHaus_green.png";
import { Stripe } from "../../components/stripe/Stripe";
import { Redirect } from "react-router-dom";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select;

Amplify.configure(awsconfig);
const openNotification = () => {
  notification.open({
    message: "Confirm your email.",
    description:
      "Welcome!  Go to your email, confirm your account and you are ready to go!",
    icon: <SmileOutlined style={{ color: "#888" }} />,
    duration: 10,
  });
};
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const onFinish = (values) => {
  console.log("Success:", values);
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};
export default class Payment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      secondPassword: "",
      family_name: "",
      given_name: "",
      goals: "",
      level: "Level 1",
      stripeCustomer: {},
      redirectBackToLogin: false,
    };

    this.setIsLoading = this.setIsLoading.bind(this);
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.stripeCustomer
    ) {
      this.setState({
        stripeCustomer: this.props.location.state.stripeCustomer,
      });
    } else {
      this.setState({
        redirectBackToLogin: true, //something wrong happened - no stripe info for payment
        stripeCustomer: {},
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.redirectBackToLogin ? (
          <Redirect
            to={{
              pathname: "/login",
              // state: {
              //   email: this.state.email,
              //   stripeCustomer: this.state.stripeCustomer,
              // },
            }}
          />
        ) : null}
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 10 }}>
            <div
              style={{
                height: "85vh",
                backgroundColor: "rgb(218,228,211)",
                paddingTop: "50px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "400px",
                  backgroundColor: "rgb(218,228,211)",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "auto",
                    height: "auto",
                    overflowY: "scroll",
                    //backgroundColor: "red",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url( ${hausLogoJustLogo})`,
                      height: 100,
                      width: 100,
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                  <div
                    style={{
                      fontFamily: "Abel",
                      fontSize: 35,
                      fontWeight: 400,
                      margin: 20,
                    }}
                  >
                    Let's get started
                  </div>

                  {this.renderPriceChoice()}
                  <div
                    style={{
                      padding: "5px",
                      paddingBottom: "20px",
                      fontSize: "15px",
                      color: "rgb(86,94,80)",
                      fontFamily: "Roboto",
                    }}
                  >
                    Cancel easily anytime!
                  </div>
                  {/* <div
                    style={{
                      fontFamily: "Abel",
                      fontSize: 15,
                      fontWeight: 300,
                      margin: 5,
                    }}
                  >
                    50% Off First Month, until August 31th!
                  </div> */}
                  {this.renderStripePayment()}
                  <div
                    style={{
                      padding: "5px",
                      paddingBottom: "20px",
                      fontSize: "15px",
                      color: "rgb(86,94,80)",
                      fontFamily: "Roboto",
                      fontWeight: 600,
                    }}
                  >
                    Our payments are secured with Stripe®
                  </div>
                  {this.state.isLoading ? (
                    <Spin style={{ zIndex: 1000 }} indicator={antIcon} />
                  ) : null}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 14 }}>
            <div
              style={{
                width: "100%",
                height: "85vh",
                backgroundImage:
                  'url("https://source.unsplash.com/SAFYn1O-qyw")',
                backgroundPosition: "0% center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Col>
        </Row>
      </div>
    );
  }

  renderStripePayment() {
    return (
      <div style={{ width: "100%", height: "200px" }}>
        <Stripe
          stripeCustomer={this.state.stripeCustomer}
          paymentBasis={this.state.paymentBasis}
          setIsLoading={() => this.setIsLoading}
        />
      </div>
    );
  }

  renderPriceChoice() {
    return (
      <div
        style={{
          width: "100%",
          padding: 10,
          height: "auto",
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "130px",
            height: "130px",
            backgroundColor:
              this.state.paymentBasis === "monthly"
                ? "rgb(255, 246, 232)"
                : "#fff",
            border:
              this.state.paymentBasis === "monthly"
                ? "2px solid #000"
                : "0.5px solid #000",
            borderRadius: "5px",
            margin: 5,
            cursor: "pointer",
          }}
          onClick={() => this.choosePayment("monthly")}
        >
          <div style={{ fontFamily: "Abel", fontSize: 25, fontWeight: 600 }}>
            9.99€
          </div>
          <div
            style={{ fontFamily: "Abel", fontSize: 20, textAlign: "center" }}
          >
            Monthly Subscription
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "130px",
            height: "130px",
            backgroundColor:
              this.state.paymentBasis === "yearly"
                ? "rgb(255, 246, 232)"
                : "#fff",
            border:
              this.state.paymentBasis === "yearly"
                ? "2px solid #000"
                : "0.5px solid #000",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => this.choosePayment("yearly")}
        >
          <div style={{ fontFamily: "Abel", fontSize: 25, fontWeight: 600 }}>
            84€
          </div>
          <div
            style={{ fontFamily: "Abel", fontSize: 20, textAlign: "center" }}
          >
            Yearly Subscription
          </div>
        </div>
      </div>
    );
  }

  choosePayment(paymentBasis) {
    this.setState({ paymentBasis });
  }
  formIsReady() {
    return (
      this.state.email &&
      this.state.family_name &&
      this.state.given_name &&
      this.state.level &&
      this.state.goals &&
      this.state.password &&
      this.state.secondPassword &&
      this.state.password === this.state.secondPassword
    );
  }

  setIsLoading(isLoading) {
    this.setState({ isLoading: isLoading });
  }
  async signUp() {
    let email = this.state.email;
    let password = this.state.password;

    this.setState({ isLoading: true });
    const hide = message.loading("Signing you up ...", 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 2500);

    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email: this.state.email,
          family_name: this.state.family_name,
          given_name: this.state.given_name,
        },
      }).then(async (user) => {
        console.log("sign Up result: ", user);
        this.setState({ isLoading: false });

        let userProfile = {
          id: user.userSub,
          email: this.state.email,
          name: this.state.given_name + " " + this.state.family_name,
          age: this.state.age,
          level: this.state.level,
          goals: this.state.goals,
          role: "user", //must be validated on BE
        };
        console.log("registering on BE", user);
        let registerResult = await registerUser(user.userSub, userProfile).then(
          (result) => {
            console.log("result: ", result);
            openNotification();
          }
        );
        console.log("registerResult: ", registerResult);
        // window.location.reload();
      });
      //  console.log({ user });
    } catch (error) {
      console.log("error signing up:", error);
      message.error({
        content: error.message,
        className: "custom-class",
        style: {
          marginTop: "20vh",
        },
      });
      this.setState({ isLoading: false });
    }
  }
}
