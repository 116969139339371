import React from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";

import { message, notification, Spin } from "antd";
import { SmileOutlined, LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const showSuccessNotification = () => {
  notification.open({
    message: "Welcome!",
    description: "Don't forget to validate your email, and then Login!",
    icon: <SmileOutlined style={{ color: "#888" }} />,
    duration: 10,
  });
};

var address;
if (process.env.NODE_ENV === "production") {
  address = "https://be-haus.com/api";
} else {
  address = "http://localhost:3000";
}

export const CheckoutForm = ({ stripeCustomer, paymentBasis }) => {
  // setIsLoading(true);
  //export function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  console.log("stripeCustomer RECEIVED: ", stripeCustomer);
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.

      return;
    }
    //setIsLoading(true);
    if (paymentBasis !== "monthly" && paymentBasis !== "yearly") {
      message.error("Please choose a subscription.");
      document.getElementById("stripe_button").style.visibility = "hidden"; //no state so had to use this
      throw "Please choose a subscription.";
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // If a previous payment was attempted, get the lastest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      "latestInvoicePaymentIntentStatus"
    );

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[createPaymentMethod error]", error);
      message.error(error.message);
      //setIsLoading(false);
      document.getElementById("stripe_button").style.visibility = "hidden";
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      const paymentMethodId = paymentMethod.id;
      let customerId = stripeCustomer.id;

      //   setIsLoading(true);
      createSubscription({
        customerId,
        paymentMethodId,
        //  priceId,
        paymentBasis,
      });
      //let paymentMethodId = "test1";

      // if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
      //   // Update the payment method and retry invoice payment
      //   const invoiceId = localStorage.getItem("latestInvoiceId");
      //   retryInvoiceWithNewPaymentMethod({
      //     customerId,
      //     paymentMethodId,
      //     invoiceId,
      //     priceId,
      //   });
      // } else {
      //   // Create the subscription

      //   console.log(
      //     "Good to GO: customerId, paymentMethodId, priceId",
      //     customerId,
      //     paymentMethodId,
      //     priceId
      //   );
      //   createSubscription({
      //     customerId,
      //     paymentMethodId,
      //     priceId,
      //     paymentBasis,
      //   });
      // }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CardSection />

        <button
          onClick={() =>
            (document.getElementById("stripe_button").style.visibility =
              "visible")
          }
          disabled={!stripe}
          style={{
            display: "flex",
            height: "40px",
            maxWidth: 390,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            // padding: "8px 20px",
            border: "#000",
            borderRadius: "12px",
            backgroundColor: "rgb(86,94,80)",
            color: "#fff",
            fontSize: "14px",
            transition: "all .2s ease-in-out",
            cursor: "pointer",
            //  whiteSpace: "nowrap",
            fontWeight: 500,
            marginTop: 10,
            textTransform: "uppercase",
          }}
        >
          Start 15 days trial
        </button>
        <div
          id="stripe_button"
          style={{ margin: 5, visibility: "hidden" }}
          onClick={() =>
            (document.getElementById("stripe_button").style.visibility =
              "visible")
          }
        >
          <Spin size="large" style={{ zIndex: 1000 }} indicator={antIcon} />
        </div>
      </div>
    </form>
  );
};

function createSubscription({
  customerId,
  paymentMethodId,
  // priceId,
  paymentBasis,
}) {
  document.getElementById("stripe_button").style.visibility = "hidden"; // no state available... so...
  //setIsLoading(true);
  return (
    fetch(address + "/users/create-subscription", {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        // priceId: priceId,
        paymentBasis: paymentBasis,
      }),
    })
      .then((response) => {
        return response.json();
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          message.error(result.error.message);

          throw result;
        }
        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the addional details we need.
      .then((result) => {
        //  setIsLoading(false);
        return {
          paymentMethodId: paymentMethodId,
          // priceId: priceId,
          subscription: result,
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      //     .then(handlePaymentThatRequiresCustomerAction)

      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      // .then(handleRequiresPaymentMethod)

      // No more actions required. Provision your service for the user.
      .then(async (result) => {
        // setIsLoading(false);
        return await onSubscriptionComplete(result);
      })
      .catch((error) => {
        message.error(error.message);
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        //  showCardError(error);
      })
  );
}

// function retryInvoiceWithNewPaymentMethod({
//   customerId,
//   paymentMethodId,
//   invoiceId,
//   priceId,
// }) {
//   return (
//     fetch("/retry-invoice", {
//       method: "post",
//       headers: {
//         "Content-type": "application/json",
//       },
//       body: JSON.stringify({
//         customerId: customerId,
//         paymentMethodId: paymentMethodId,
//         invoiceId: invoiceId,
//       }),
//     })
//       .then((response) => {
//         return response.json();
//       })
//       // If the card is declined, display an error to the user.
//       .then((result) => {
//         if (result.error) {
//           message.error(result.error);
//           // The card had an error when trying to attach it to a customer.
//           throw result;
//         }
//         return result;
//       })
//       // Normalize the result to contain the object returned by Stripe.
//       // Add the addional details we need.
//       .then((result) => {
//         return {
//           // Use the Stripe 'object' property on the
//           // returned result to understand what object is returned.
//           invoice: result,
//           paymentMethodId: paymentMethodId,
//           priceId: priceId,
//           isRetry: true,
//         };
//       })
//       // Some payment methods require a customer to be on session
//       // to complete the payment process. Check the status of the
//       // payment intent to handle these actions.
//       //     .then(handlePaymentThatRequiresCustomerAction)
//       // No more actions required. Provision your service for the user.
//       .then(onSubscriptionComplete)
//       .catch((error) => {
//         message.error(error.message);
//         // An error has happened. Display the failure to the user here.
//         // We utilize the HTML element we created.
//         console.error(error);
//         //displayError(error);
//       })
//   );
// }
// function handleRequiresPaymentMethod({
//   subscription,
//   paymentMethodId,
//   priceId,
// }) {
//   if (subscription.status === "active") {
//     // subscription is active, no customer actions required.
//     return { subscription, priceId, paymentMethodId };
//   } else if (
//     subscription.latest_invoice.payment_intent.status ===
//     "requires_payment_method"
//   ) {
//     // Using localStorage to manage the state of the retry here,
//     // feel free to replace with what you prefer.
//     // Store the latest invoice ID and status.
//     localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
//     localStorage.setItem(
//       "latestInvoicePaymentIntentStatus",
//       subscription.latest_invoice.payment_intent.status
//     );
//     throw { error: { message: "Your card was declined." } };
//   } else {
//     return { subscription, priceId, paymentMethodId };
//   }
// }

async function onSubscriptionComplete(result) {
  // console.log("result.subscription.status: ", result.subscription.status);
  // console.log("result.subscription: ", result.subscription);

  console.log("Subscription result: ", result);
  // Payment was successful.
  if (
    result.subscription.response.subscription === "active" ||
    result.subscription.response.subscription === "trialing"
  ) {
    showSuccessNotification();

    await new Promise((resolve) => setTimeout(resolve, 4000));
    window.location.replace("/login");

    // Change your UI to show a success message to your customer.
    // Call your backend to grant access to your service based on
    // `result.subscription.items.data[0].price.product` the customer subscribed to.
  }
}

// function handlePaymentThatRequiresCustomerAction({
//   subscription,
//   invoice,
//   priceId,
//   paymentMethodId,
//   isRetry,
// }) {
//   if (subscription && subscription.status === "active") {
//     // Subscription is active, no customer actions required.
//     return { subscription, priceId, paymentMethodId };
//   }

//   // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
//   // If it's a retry, the payment intent will be on the invoice itself.
//   let paymentIntent = invoice
//     ? invoice.payment_intent
//     : subscription.latest_invoice.payment_intent;

//   if (
//     paymentIntent.status === "requires_action" ||
//     (isRetry === true && paymentIntent.status === "requires_payment_method")
//   ) {
//     return stripe
//       .confirmCardPayment(paymentIntent.client_secret, {
//         payment_method: paymentMethodId,
//       })
//       .then((result) => {
//         if (result.error) {
//           // Start code flow to handle updating the payment details.
//           // Display error message in your UI.
//           // The card was declined (i.e. insufficient funds, card has expired, etc).
//           throw result;
//         } else {
//           if (result.paymentIntent.status === "succeeded") {
//             // Show a success message to your customer.
//             // There's a risk of the customer closing the window before the callback.
//             // We recommend setting up webhook endpoints later in this guide.
//             return {
//               priceId: priceId,
//               subscription: subscription,
//               invoice: invoice,
//               paymentMethodId: paymentMethodId,
//             };
//           }
//         }
//       })
//       .catch((error) => {
//         //  displayError(error);
//       });
//   } else {
//     // No customer action needed.
//     return { subscription, priceId, paymentMethodId };
//   }
// }
