import React, { useContext } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Spin,
  message,
  notification,
  Tooltip,
} from "antd";
import { Auth } from "aws-amplify";
import { Redirect } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import hausLogoJustLogo from "../../media/logoHaus_green.png";
import { SmileOutlined } from "@ant-design/icons";
import { AppContext } from "../../components/AppContext";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const openNotification = () => {
  notification.open({
    message: "Password recovered.",
    description: "Please login",
    icon: <SmileOutlined style={{ color: "#888" }} />,
    duration: 5,
  });
};
export default class Recover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      family_name: "",
      given_name: "",

code:"",
newPassword:"",
      redirectToHome: false,
      isLoading: false,
      insertCode: false,
    };
  }


  render() {
    console.log("this.state",this.state)
    return (
      <div>
        <AppContext.Consumer>
          {(context) => (
            <Row>
              {this.state.redirectToHome ? <Redirect to="/login" /> : null}
              <Col xs={{ span: 24 }} md={{ span: 10 }}>
                <div
                  style={{
                    width: "100%",
                    height: "85vh",
                    overflowY: "scroll",
                    backgroundColor: "rgb(218,228,211)",
                    padding: "5vw",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      // height: "400px",
                      backgroundColor: "rgb(218,228,211)",
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    <div
                      style={{
                        backgroundImage: `url( ${hausLogoJustLogo})`,
                        height: 100,
                        width: 100,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                    <div
                      style={{
                        fontFamily: "Abel",
                        fontSize: 35,
                        fontWeight: 400,
                        margin: 20,
                      }}
                    >
                      Recover / Forgot Password
                    </div>
                    <Tooltip title="Type your e-mail, and a security code will be sent to your e-mail. Just paste the code below in the first box, and your new password on the second box :)">
                      <div
                        style={{
                          fontWeight: 600,
                          border: "0.5px solid rgb(86,94,80)",
                          borderRadius: 10,
                          padding: 5,
                        }}
                      >
                        How?
                      </div>
                    </Tooltip>
                    <Form
                      style={{
                        width: "100%",
                        maxWidth: "400px",
                        height: "auto",
                        backgroundColor: "rgb(218,228,211)",
                      }}
                      name="basic"
                      initialValues={{ remember: true }}
                      // onFinish={onFinish}
                      // onFinishFailed={onFinishFailed}
                    >
                      {!this.state.insertCode ? (
                        <div>
                          <Form.Item
                            // label="Email"
                            style={{
                              margin: "5px",
                              width: "100%",
                              maxWidth: "390px",
                            }}
                            name="email"
                            large
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message: "Please input your email.",
                              },
                            ]}
                          >
                          
                            <Input
value={this.state.email}
                              onChange={(input) =>
                                this.setState({ email: input.target.value })
                              }
                              placeholder={"Email"}
                              style={{
                                fontFamily: "roboto",
                                fontSize: 18,
                                border: "0.5px solid #000",
                                borderRadius: 12,
                                width: "100%",
                                height: 40,
                              }}
                            />
                          </Form.Item>

                          <Form.Item style={{ margin: "5px", width: "100%" }}>
                            {/* <Button type="primary"  htmlType="submit"> */}
                            <div
                              // htmlType="submit"
                              onClick={
                                async () =>
                                  await Auth.forgotPassword(this.state.email)
                                    .then(async (data) => {
                                      this.setState({ insertCode: true,    
                                      code:"",
                                      newPassword:"" });
                                      message.info("Please check your email.");
                                      console.log(data);
                                      await new Promise((resolve) =>
                                        setTimeout(resolve, 1000)
                                      );
                                      this.setState({    
                                      code:"",
                                      newPassword:"" });
                                     
                                    })
                                    .catch((err) => message.error(err.message))
                                // this.signIn()
                              }
                              style={{
                                display: "flex",
                                height: "40px",
                                width: "100%",
                                maxWidth: "390px",
                                justifyContent: "center",
                                alignItems: "center",
                                //margin: "5px",
                                border: "0.5px solid #000",
                                borderRadius: "12px",
                                backgroundColor: "rgb(86,94,80)",
                                color: "#fff",
                                fontSize: "14px",
                                transition: "all .2s ease-in-out",
                                cursor: "pointer",
                                //  whiteSpace: "nowrap",
                                fontWeight: 500,
                                //margin: 5,
                              }}
                            >
                              Confirm email
                            </div>
                            <div style={{ margin: 10 }}>
                              <div
                                style={{
                                  margin: 2,
                                  color: "#000",
                                  fontSize: 8,
                                }}
                              >
                                ©2020 Thaís Caniceiro Unipessoal Lda
                              </div>
                              <div
                                style={{
                                  margin: 2,
                                  color: "#000",
                                  fontSize: 10,
                                }}
                              >
                                All rights reserved
                              </div>
                            </div>
                          </Form.Item>
                        </div>
                      ) : (
                        <div>
                        <Input type={"hidden"} value="something2"/>
                          <Form.Item
                          autoComplete="off" 
                            // label="Email"
                            style={{ margin: "5px", width: "100%" }}
                            //name="Your Code"
                            name="recover_code"
                            large
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input the code you received on your email.",
                              },
                            ]}
                          >
                          <Input type={"hidden"} value="something3"/>
                            <Input
                            value={this.state.code}
                            autoComplete="off" 
                            name="recovery_code"
                              onChange={(input) =>
                                this.setState({ code: input.target.value })
                              }
                              placeholder={"Your code - check your email"}
                              style={{
                                fontFamily: "roboto",
                                fontSize: 18,
                                border: "0.5px solid #000",
                                borderRadius: 12,
                                width: "100%",
                                maxWidth: 390,
                                height: 40,
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            // label="Email"
                         
                            autoComplete="off" 
                            style={{ margin: "5px", width: "100%" }}
                            name="recoverNewPassword_"
                            large
                            rules={[
                              {
                                required: true,
                                message: "Please input your new password!",
                              },
                            ]}
                          >
                          <input type={"hidden"} value="something4"/>
                            <Input.Password
                            autoComplete="off" 
                            value={this.state.newPassword}
                              name="recoverNewPassword_"
                              onChange={(input) =>
                                this.setState({
                                  newPassword: input.target.value,
                                })
                              }
                              placeholder={"Your new password"}
                              style={{
                                fontFamily: "roboto",
                                fontSize: 18,
                                border: "0.5px solid #000",
                                borderRadius: 12,
                                width: "100%",
                                maxWidth: 390,
                                height: 40,
                              }}
                            />
                          </Form.Item>

                          <Form.Item style={{ margin: "5px", width: "100%" }}>
                            {/* <Button type="primary"  htmlType="submit"> */}
                            <div
                              // htmlType="submit"
                              onClick={
                                async () =>
                                  await Auth.forgotPasswordSubmit(
                                    this.state.email,
                                    this.state.code,
                                    this.state.newPassword
                                  )
                                    .then( (data) => {
                                      console.log(data);
                                      openNotification();
                                    
                                      this.setState({
                                        redirectToHome: true,
                                        isLoading: false,
                                      });
                                    })
                                    .catch((err) => message.error(err.message))
                                // this.signIn()
                              }
                              style={{
                                width: "100%",
                                maxWidth: 390,
                                display: "flex",
                                height: "40px",
                                // width: "390px",
                                justifyContent: "center",
                                alignItems: "center",
                                // margin: "5px",
                                border: "0.5px solid #000",
                                borderRadius: "12px",
                                backgroundColor: "rgb(86,94,80)",
                                color: "#fff",
                                fontSize: "14px",
                                transition: "all .2s ease-in-out",
                                cursor: "pointer",
                                //  whiteSpace: "nowrap",
                                fontWeight: 500,
                                //margin: 5,
                              }}
                            >
                              Recover Password
                            </div>

                            <div>
                              <div
                                style={{
                                  margin: 2,
                                  color: "#000",
                                  fontSize: 12,
                                  fontWeight: 600,
                                  paddingBottom: 20,
                                  cursor: "pointer",
                                }}
                                onClick={
                                  async () => {
                                    if (!this.state.isLoading) {
                                      this.setState({ isLoading: true });
                                      await Auth.forgotPassword(
                                        this.state.email
                                      )
                                        .then((data) => {
                                          this.setState({
                                            insertCode: true,
                                            isLoading: false,
                                             code:"",
                                             newPassword:""
                                          });
                                          message.info("Code sent again");
                                          console.log(data);
                                        })
                                        .catch((err) =>
                                          message.error(err.message)
                                        );
                                    }
                                  }
                                  // this.signIn()
                                }
                              >
                                Send recovery code again
                              </div>
                              <div
                                style={{
                                  margin: 2,
                                  color: "#000",
                                  fontSize: 8,
                                }}
                              >
                                ©2020 Thaís Caniceiro Unipessoal Lda
                              </div>
                              <div
                                style={{
                                  margin: 2,
                                  color: "#000",
                                  fontSize: 10,
                                }}
                              >
                                All rights reserved
                              </div>
                            </div>
                          </Form.Item>
                        </div>
                      )}
                    </Form>
                    {this.state.isLoading ? (
                      <Spin style={{ zIndex: 1000 }} indicator={antIcon} />
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 14 }}>
                <div
                  style={{
                    width: "100%",
                    height: "85vh",
                    backgroundImage:
                      'url("https://source.unsplash.com/hHzzdVQnkn0")',
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              </Col>
            </Row>
          )}
        </AppContext.Consumer>
      </div>
    );
  }

  async signIn() {
    let email = this.state.email;
    let password = this.state.password;
    this.setState({ isLoading: true });
    const hide = message.loading("Login in ...", 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 2500);

    try {
      await Auth.signIn({
        username: email,
        password,
      }).then((result) => {
        console.log("login result: ", result);
        this.setState({ redirectToHome: true, isLoading: false });
        window.location.reload();
      });
    } catch (error) {
      console.log("error signing up:", error);
      message.error({
        content: error.message,
        className: "custom-class",
        style: {
          marginTop: "20vh",
        },
      });
      this.setState({ isLoading: false });

      // throw error;
    }
  }
}
