import { Col, Row, Modal as AntdModal } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { getMixedContent, getOneCreator } from "../../network/basicContent";
import EditClassesModal from "../../components/EditClassesModal";
import photo_thatiane from "../../media/tathiane_foto.jpeg";
import EditMixedContent from "../../components/EditMixedContent";
import EditCreator from "../../components/EditWellbeingCreator";
export default class Food extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      classes: [],
      filteredClasses: [],
      filter: { duration: "all", language: "all" },
    };
  }
  async componentDidMount() {
    let classes = await getMixedContent();
    let creator = await getOneCreator("food");

    console.log("creator!! : ", creator);
    //filter only specific type = ToDO on BE
    classes = classes.filter((classes) => classes.type === "food");
    console.log("classes!! ", classes);
    this.setState({ classes, creator });
  }
  render() {
    return (
      <div style={{ backgroundColor: "#faf9f7" }}>
        {this.props.user.role === "admin" && this.state.creator ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "50%" }}>
              <EditMixedContent creatingNewClass={true} type={"food"} />
            </div>
            <div style={{ width: "50%" }}>
              <EditCreator
                teacherInfo={this.state.creator}
                creatingNewTeacher={false}
              />
            </div>
          </div>
        ) : null}

        {this.state.creator ? (
          <>
            {this.renderCreatorHero()} {this.renderBioModal()}
          </>
        ) : null}

        {this.renderVideosGrid()}
      </div>
    );
  }
  handleOk = (e) => {
    //console.log(e);
    this.setState({
      showingBioModal: false,
    });
  };

  handleCancel = (e) => {
    //console.log(e);
    this.setState({
      showingBioModal: false,
    });
  };
  renderSpacingFromPageTop() {
    return <div style={{ height: 50, width: "100%" }}></div>;
  }
  renderVideosGrid() {
    return (
      <Row justify="start" align="top">
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <div
            style={{
              color: "black",
              height: "auto",
              width: "100%",
              padding: "2vh 2vw 2vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                padding: "10px",
                fontSize: "26px",
                fontWeight: "500",
                alignSelf: "start",
                fontFamily: "Abel",
              }}
            >
              Conscious Food
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                whiteSpace: "wrap",
                justifyContent: "center",
                alignItems: "center",
                // overflowX: "scroll",
                // overflowY: "hidden",
                width: "100%",
                height: "auto",
              }}
            >
              {this.state.classes.map((_class) => (
                <Link to={"/food/" + _class._id}>
                  <div
                    className={"shadowOnHover"}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "400px",
                      width: "300px",
                      backgroundColor: "#ccc",
                      margin: "10px",
                    }}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${
                          _class.photos[0]
                            ? _class.photos[0]
                            : "https://haus-bucket-2020.s3.amazonaws.com/public/No_picture_available.png"
                        })`,
                        width: "100%",
                        height: "300px",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                    <div
                      style={{
                        backgroundColor: "#fff",
                        height: "100px",
                        width: "100%",
                        padding: "10px",
                        textTransform: "capitalize",
                      }}
                    >
                      <div style={{ fontSize: 15, color: "#000", padding: 5 }}>
                        {_class.title}
                      </div>
                      <div
                        style={{
                          fontSize: 18,
                          color: "#888",
                          padding: 5,
                          textTransform: "capitalize",
                          alignSelf: "right",
                        }}
                      >
                        {_class.type}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  renderFoodHeroBlock() {
    return (
      <Row type="flex" align="middle" style={{ margin: "10px" }}>
        <Col xs={{ span: 22 }} md={{ span: 8 }}>
          {/* <img src="https://source.unsplash.com/F2qh3yjz6Jk" alt="new" /> */}

          <div
            style={{
              height: "50vh",
              width: "100%",
              // backgroundImage: 'url("https://source.unsplash.com/4J059aGa5s4")',
              background: "url(" + photo_thatiane + ")",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 0%", //last zero pushes photo down! change if needed
            }}
          ></div>
        </Col>
        <Col xs={{ span: 22 }} md={{ span: 6 }}>
          {/* <img src="https://source.unsplash.com/F2qh3yjz6Jk" alt="new" /> */}

          <div
            style={{
              height: "50vh",
              width: "100%",
              backgroundImage: 'url("https://source.unsplash.com/jUPOXXRNdcA")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 50%",
            }}
          ></div>
        </Col>
        <Col xs={{ span: 22 }} md={{ span: 10 }}>
          <div
            style={{
              color: "black",
              height: "50vh",
              width: "100%",
              border: "1px solid #ccc",
              backgroundColor: "#fff",
              padding: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <div
              style={{
                padding: "5px",
                paddingBottom: 10,
                fontSize: "40px",
                fontWeight: "700",
                color: "rgb(86,94,80)",
                fontFamily: "Abel",
              }}
            >
              Tathiane Almeida
            </div>
            <div
              style={{
                padding: "5px",
                paddingBottom: "10px",
                fontFamily: "Roboto",
                fontSize: "22px",
                color: "rgb(86,94,80)",
              }}
            >
              A Haus é um projeto que surgiu com o intuito de oferecer a
              população em geral um estilo de vida mais saudável e mais
              sustentável através de um conjunto de ferramentas...
            </div>
            {/* <Link to="/wellbeing">
              <div
                style={{
                  display: "flex",
                  padding: "12px 24px",
                  border: "1px solid #000",
                  borderRadius: "24px",
                  backgroundColor: "#000",
                  color: "#fff",
                  fontSize: "16px",
                  transition: "all .2s ease-in-out",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                Go to Wellbeing
              </div>
            </Link> */}
          </div>
        </Col>
      </Row>
    );
  }

  renderCreatorHero() {
    console.log("this.state.teacher.photos", this.state.creator.photos);
    return (
      <Row type="flex" align="top" justify="start">
        <Col xs={{ span: 24 }} md={{ span: 9 }}>
          <div
            style={{
              alignSelf: "center",
              justifySelf: "center",
              backgroundImage: `url(${this.state.creator.photos[0]})`,
              width: "100%",
              height: "400px",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              // marginTop: "15vh",
            }}
          ></div>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 15 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              height: "400px",
              padding: 30,
              //marginTop: "15vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              backgroundColor: "#eee",
            }}
          >
            <div
              style={{
                fontSize: "48px",
                //padding: "10px",
                color: "#000",
                height: "auto",
                width: "300px",
                lineHeight: "0.9",
                textTransform: "uppercase",
                fontFamily: "Abel",
              }}
            >
              {this.state.creator.name}
            </div>
            <div
              style={{
                height: "100%",
                padding: 30,
                textOverflow: "ellipsis",
                overflow: "hidden",

                fontSize: "20px",
                /// padding: "20px",
                color: "#000",
                fontFamily: "Abel",
              }}
            >
              "
              {this.state.creator.smallBio.length > 200
                ? this.state.creator.smallBio.slice(0, 200) + "..."
                : this.state.creator.smallBio}
              "
            </div>

            <div
              onClick={() => this.setState({ showingBioModal: true })}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                //margin: "50px",
                border: "1px solid #fff",
                borderRadius: "10px",

                width: "150px",
                height: "50px",
                backgroundColor: "rgb(237,194,167)",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Bio
            </div>
          </div>
        </Col>
      </Row>
    );
  }
  renderBioModal() {
    return (
      <AntdModal
        title="Teacher Bio"
        visible={this.state.showingBioModal}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        style={{ height: "80vh" }}
        width={"80vw"}
      >
        <Row type="flex" align="middle" justify="center">
          <Col xs={{ span: 24 }} md={{ span: 11 }}>
            <div
              style={{
                alignSelf: "center",
                justifySelf: "center",
                backgroundImage: `url(${
                  this.state.creator.photos[1] //if theres 2 pics, put the second
                    ? this.state.creator.photos[1]
                    : this.state.creator.photos[0]
                })`,
                width: "100%",
                height: "600px",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                // marginTop: "15vh",
              }}
            ></div>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 13 }}>
            <div
              style={{
                width: "100%",
                height: "auto",
                //marginTop: "15vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <div
                style={{
                  fontSize: "62px",
                  padding: "20px",
                  color: "#000",
                  height: "auto",
                  width: "300px",
                  lineHeight: "0.9",
                }}
              >
                {this.state.creator.name}
              </div>
              <div style={{ fontSize: "14px", padding: "20px", color: "#000" }}>
                {this.state.creator.bio}
              </div>
              <div style={{ fontSize: "18px", padding: "20px", color: "#999" }}>
                {this.state.creator.email}
              </div>
            </div>
          </Col>
        </Row>
      </AntdModal>
    );
  }
}
