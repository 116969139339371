import {
  UserOutlined,
  VideoCameraTwoTone,
  ClockCircleTwoTone,
  BoxPlotOutlined,
} from "@ant-design/icons";
import { Input, message, Modal, Select, Switch, Tabs, Rate } from "antd";
import React from "react";
import {
  getTeachers,
  putClassInfo,
  saveNewClass,
} from "./../network/basicContent";

import ClassPhotos from "./UploadClassPhoto";
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const languagePossibilities = [
  <Option key={"Portuguese"}>{"Portuguese"}</Option>,
  <Option key={"English"}>{"English"}</Option>,
  <Option key={"Spanish"}>{"Spanish"}</Option>,
];
const classesPossibilities = [
  <Option key={"Yoga"}>{"Yoga"}</Option>,
  <Option key={"Pilates"}>{"Pilates"}</Option>,
  <Option key={"Fitness"}>{"Fitness"}</Option>,
  <Option key={"Meditation"}>{"Meditation"}</Option>,
];

export default class EditClasses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      title: "",
      description: "",
      photos: "",
      classVideo: [],
      isVisible: false,
      difficulty: "",
      duration: "",
      language: [],
      typeOfClass: [],
      teachers: [],
      allTeachers: [],
    };
  }

  async componentDidMount() {
    if (this.props.creatingNewClass) {
    } else {
      this.setState({
        ...this.props.classInfo,
      });
    }

    // get All Teachers to connect class to teachers
    let allTeachers = await getTeachers();
    console.log("allTeachers", allTeachers);
    this.setState({
      allTeachers, // to allow class teacher choice
    });
  }

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };
  handleOk = async () => {
    let savingResult;
    // console.log(e);
    this.setState({ isLoading: true });

    message.loading("Saving...", 2);
    // Dismiss manually and asynchronously
    // setTimeout(hide, 2000);
    if (this.props.creatingNewClass) {
      savingResult = await this.saveNewClass();
    } else {
      savingResult = await this.saveAllChanges();
    }

    this.setState({ isLoading: false });

    console.log("savingResult", savingResult);

    if (savingResult && savingResult.status && savingResult.status == 200) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.setState({
        modalVisible: false,
      });
      window.location.reload();
    }
  };

  handleCancel = (e) => {
    //console.log(e);
    this.setState({
      modalVisible: false,
    });
  };

  handleChangeClass(value) {
    console.log(`selected`, value);
    this.setState({ typeOfClass: value });
  }

  handleChangeLanguage(value) {
    console.log(`selected`, value);

    this.setState({ language: value });
  }

  handleAddTeachersToClass(value) {
    console.log(`selected`, value);

    this.setState({ teachers: value });
  }

  renderEditTeacherPanel() {
    return (
      <div
        style={{
          color: "black",
          minHeight: "130px",
          height: "auto",
          width: "100%",
          backgroundColor: "#fff",
          padding: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div
          onClick={(e) => this.showModal(e)}
          style={{
            display: "flex",
            height: "50px",
            width: "auto",
            justifyContent: "center",
            alignItems: "center",
            //padding: "20px",
            padding: "10px",

            border: "0.5px solid #000",
            borderRadius: "24px",
            backgroundColor: "#faf9f7",
            color: "#000",
            fontSize: "12px",
            cursor: "pointer",
            fontWeight: 600,
            margin: 5,
          }}
        >
          {this.props.creatingNewClass ? "Add new  class" : "Edit class"}
        </div>
      </div>
    );
  }

  setTeacherVisible(visible) {
    this.setState({ teacherVisible: visible });
  }

  render() {
    // console.log("this.state.teachers", this.state.teachers);
    //console.log("this.state.allTeachers", this.state.allTeachers);
    return (
      <div style={{ backgroundColor: "#faf9f7" }}>
        {this.renderEditTeacherPanel()}
        {this.renderEditTeacherModal()}
      </div>
    );
  }

  renderTeacherInfoPanel() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          alignItems: "flex-end",
          width: "100%",
          height: "auto",
        }}
      >
        {/* <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Tags Mode"
          onChange={this.handleChangeLanguage}
        >
          {this.state.teacherLanguages.map((language, index) => (
            <Option key={index}>{language}</Option>
          ))}
        </Select> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%",
            height: "auto",

            padding: 20,
          }}
        >
          <div
            style={{
              height: "auto",
              width: "200px",
              margin: 10,
            }}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Class Languages"
              value={this.state.language}
              onChange={(value) => this.handleChangeLanguage(value)}
            >
              {languagePossibilities}
            </Select>
          </div>
          <div style={{ height: "auto", width: "200px", margin: 10 }}>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Class Types"
              value={this.state.typeOfClass}
              onChange={(value) => this.handleChangeClass(value)}
            >
              {classesPossibilities}
            </Select>
          </div>

          <div
            style={{
              height: "auto",
              width: "200px",
              padding: 5,
              margin: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>Visible to public?</div>
            <Switch
              checked={this.state.isVisible}
              onChange={(isVisible) => this.setState({ isVisible })}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            fontSize: 13,
            alignSelf: "flex-start",
            padding: 20,
          }}
        >
          {/* <div style={{ padding: 30 }}>
            <div>Difficulty(1-3)</div>
            <Rate
              allowHalf
              defaultValue={1}
              count={3}
              value={this.state.difficulty}
              onChange={(value) => this.setState({ difficulty: value })}
            />
          </div> */}
          <div style={{ padding: 30 }}>
            <div>Level</div>
            <Select
              // mode="tags"
              style={{ width: "150px" }}
              placeholder="Choose level"
              value={this.state.difficulty}
              onChange={(value) => this.setState({ difficulty: value })}
            >
              <Option key={"Beginner"}>{"Beginner"}</Option>
              <Option key={"Intermediate"}>{"Intermediate"}</Option>
              <Option key={"Advanced"}>{"Advanced"}</Option>
            </Select>
          </div>
          <div style={{ padding: 30 }}>
            <div>Duration(minutes)</div>
            <Input
              style={{ width: "70px" }}
              size="large"
              placeholder="min"
              prefix={<ClockCircleTwoTone />}
              value={this.state.duration}
              onChange={(input) =>
                this.setState({ duration: input.target.value })
              }
            />
          </div>
          <div style={{ padding: 30 }}>
            <div>Props</div>
            <Input
              style={{ width: "200px" }}
              size="large"
              placeholder="Props"
              prefix={<BoxPlotOutlined />}
              value={this.state.classRequirements}
              onChange={(input) =>
                this.setState({ classRequirements: input.target.value })
              }
            />
          </div>
        </div>
        <Input
          style={{ marginBottom: 20 }}
          size="large"
          placeholder="Class Title"
          prefix={<UserOutlined />}
          value={this.state.title}
          onChange={(input) => this.setState({ title: input.target.value })}
        />
        <div style={{ fontSize: 13, marginTop: 30, alignSelf: "flex-start" }}>
          Video VIMEO link
        </div>
        <Input
          style={{ marginBottom: 20 }}
          size="large"
          placeholder="Class Video Link"
          prefix={<VideoCameraTwoTone />}
          value={this.state.classVideo[0]}
          onChange={(input) =>
            this.setState({ classVideo: [input.target.value] })
          }
        />
        <div style={{ fontSize: 13, marginTop: 30, alignSelf: "flex-start" }}>
          Teachers
        </div>
        <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Choose Teachers"
          //value={this.state.teachers.map((teacher) => teacher.name)}
          onChange={(value) => this.handleAddTeachersToClass(value)}
        >
          {this.state.allTeachers &&
            this.state.allTeachers.map((teacher) => (
              <Option key={teacher._id}>{teacher.name}</Option>
            ))}
        </Select>
        <div style={{ fontSize: 13, marginTop: 30, alignSelf: "flex-start" }}>
          Class Description
        </div>
        <TextArea
          rows={8}
          value={this.state.description}
          onChange={(input) =>
            this.setState({ description: input.target.value })
          }
        />
        {/* <div
          onClick={async () => {
            if (this.props.creatingNewClass) {
              await this.saveNewClass();
            } else {
              await this.saveAllChanges();
            }
          }}
          style={{
            display: "flex",
            width: "150px",
            margin: 10,
            padding: "12px 24px",
            border: "1px solid #000",
            borderRadius: "24px",
            backgroundColor: "#000",
            color: "#fff",
            fontSize: "16px",
            transition: "all .2s ease-in-out",
            cursor: "pointer",
            whiteSpace: "nowrap",
          }}
        >
          Save changes
        </div> */}
      </div>
    );
  }

  async saveNewClass() {
    return await saveNewClass({
      ...this.state,
    }).then((result) => {
      //   console.log("result of saving: ", result);

      if (result.status == 200) {
        message.success("Class info saved!");
        return result;
      } else {
        message.error(
          "Info not saved. Information missing, or check your connection.",
          5
        );
      }
    });
  }
  async saveAllChanges() {
    return await putClassInfo(this.props.classInfo._id, {
      ...this.state,
    }).then((result) => {
      //  console.log("result of saving: ", result);

      if (result.status == 200) {
        message.success("Class info saved!");
        return result;
      } else {
        message.error(
          "Info not saved. Information missing, or check your connection."
        );
      }
    });
  }
  renderEditTeacherModal() {
    return (
      <Modal
        title="Add Class"
        visible={this.state.modalVisible}
        onOk={(e) => this.handleOk(e)}
        onCancel={(e) => this.handleCancel(e)}
        style={{ height: "80vh" }}
        width={"80vw"}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Class Info" key="1">
            {this.renderTeacherInfoPanel()}
          </TabPane>
          {!this.props.creatingNewClass ? (
            <TabPane tab="Class Photos" key="2">
              <ClassPhotos classInfo={this.props.classInfo} />
            </TabPane>
          ) : null}
        </Tabs>
      </Modal>
    );
  }
}
