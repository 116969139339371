import { Auth } from "aws-amplify";

let authenticate = async () => {
  let userInfo;
  try {
    userInfo = await Auth.currentAuthenticatedUser({
      bypassCache: false,
    });
  } catch (err) {
    console.log("[network common] authentication error: ", err);
    return null;
  }
  return userInfo;
};

var address;
if (process.env.NODE_ENV === "production") {
  address = "https://be-haus.com/api";
} else {
  address = "http://localhost:3000";
}

//eslint-disable-next-line
//import { handleResponse, authenticate } from "./common";

export async function getTeachers() {
  let userInfo = await Auth.currentAuthenticatedUser({
    bypassCache: false,
  });

  if (!userInfo) {
    return null;
  }
  console.log("userInfo", userInfo);

  const response = await fetch(address + "/teachers", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
      userinfo: JSON.stringify(userInfo.attributes),
    },
    //body: { userInfo: userInfo.attributes }, //in case user is not yet on BE - will be save
  });

  return response.json();
  //return await handleResponse(response);
}

export async function getOneTeacher(teacherId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  if (!teacherId) {
    throw "Please add a teacher ID";
  }

  const response = await fetch(address + "/teachers/" + teacherId, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
  });
  let body = await response.json();
  return body;
  //return await handleResponse(response);
}

export async function getOneCreator(wellbeingSector) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  if (!wellbeingSector) {
    throw "Please add a wellbeingSector";
  }

  const response = await fetch(
    address + "/wellbeing-creator/" + wellbeingSector,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        jwt: userInfo.signInUserSession.accessToken.jwtToken,
      },
    }
  );
  let body = await response.json();
  return body;
  //return await handleResponse(response);
}
export async function getClasses() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(address + "/classes", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
  });

  return response.json();
  //return await handleResponse(response);
}

export async function getOneClass(teacherId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  if (!teacherId) {
    throw "Please add a class ID";
  }

  const response = await fetch(address + "/classes/" + teacherId, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
  });
  return response.json();
  //return await handleResponse(response);
}

export async function getMixedContent(type) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(address + "/miscellaneous", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
  });

  return response.json();
  //return await handleResponse(response);
}

export async function getOneMixedContent(contentId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  if (!contentId) {
    throw "Please add a content ID";
  }

  const response = await fetch(address + "/miscellaneous/" + contentId, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
  });
  return response.json();
  //return await handleResponse(response);
}
export async function postNewPhoto(photoS3Url, teacherId, action) {
  //Post one Photo
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(address + "/teachers/photo/" + teacherId, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
    body: JSON.stringify({ photoURL: photoS3Url, action: action }),
  });

  console.log("response: ", response);
  // let responseJson = await response.json();
  // console.log("responseJson: ", responseJson);

  return response;
  //return await handleResponse(response);
}
export async function postNewPhotoToClass(photoS3Url, classId, action) {
  //Post one Photo
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(address + "/classes/photo/" + classId, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
    body: JSON.stringify({ photoURL: photoS3Url, action: action }),
  });

  console.log("response: ", response);
  // let responseJson = await response.json();
  // console.log("responseJson: ", responseJson);

  return response;
  //return await handleResponse(response);
}
export async function postNewPhotoMixedContent(photoS3Url, classId, action) {
  //Post one Photo
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(address + "/miscellaneous/photo/" + classId, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
    body: JSON.stringify({ photoURL: photoS3Url, action: action }),
  });

  console.log("response: ", response);
  // let responseJson = await response.json();
  // console.log("responseJson: ", responseJson);

  return response;
  //return await handleResponse(response);
}
export async function postNewPhotoCreator(photoS3Url, classId, action) {
  //Post one Photo
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(
    address + "/wellbeing-creator/creator/photo/" + classId,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        jwt: userInfo.signInUserSession.accessToken.jwtToken,
      },
      body: JSON.stringify({ photoURL: photoS3Url, action: action }),
    }
  );

  console.log("response: ", response);
  // let responseJson = await response.json();
  // console.log("responseJson: ", responseJson);

  return response;
  //return await handleResponse(response);
}

export async function putTeacherInfo(teacherId, info) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(address + "/teachers/info/" + teacherId, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
    body: JSON.stringify({ teacherInfo: info }),
  });
  return response;
  //return await handleResponse(response);
}
export async function saveWellbeingCreatorInfo(teacherId, info) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(
    address + "/wellbeing-creator/info/" + teacherId,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        jwt: userInfo.signInUserSession.accessToken.jwtToken,
      },
      body: JSON.stringify({ teacherInfo: info }),
    }
  );
  return response;
  //return await handleResponse(response);
}
export async function saveNewTeacher(info) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(address + "/teachers", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
    body: JSON.stringify({ teacherInfo: info }),
  });
  return response;
  //return await handleResponse(response);
}
export async function putClassInfo(classId, info) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(address + "/classes/info/" + classId, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
    body: JSON.stringify({ classInfo: info }),
  });
  return response;
  //return await handleResponse(response);
}
export async function saveNewClass(info) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(address + "/classes", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
    body: JSON.stringify({ classInfo: info }),
  });
  return response;
  //return await handleResponse(response);
}

export async function putMixedContentInfo(classId, info) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(address + "/miscellaneous/info/" + classId, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
    body: JSON.stringify({ classInfo: info }),
  });
  return response;
  //return await handleResponse(response);
}
export async function saveNewMixedContent(info) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(address + "/miscellaneous", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
    body: JSON.stringify({ classInfo: info }),
  });
  return response;
  //return await handleResponse(response);
}
