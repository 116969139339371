import React from "react";
import "./../style/home.css";
import { Link } from "react-router-dom";
//import hausLogoComplete from "../media/logoHaus.png";

import hausLogoComplete from "../media/localImages/logo_horizontal.png";
import hausLogoJustLogo from "../media/logoHaus_green.png";

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = { theposition: 0 };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }
  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    this.setState({
      theposition: scrolled,
    });
  };
  render() {
    //console.log("theposition:", this.state.theposition);
    return (
      <div
        style={{
          width: "100%",
          // height: window.pageYOffset <= 100 ? "15vh" : "5vh",
          height: window.pageYOffset <= 100 ? "17vh" : "5vh",
          background: window.pageYOffset <= 100 ? "#fff6e8" : "#f4f9f4",
          display: "flex",
          position: "fixed",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 30,
          zIndex: 1000,
          transition: "all 1s ease",
        }}
      >
        <Link to="/">
          <div
            style={{
              display: "flex",
              backgroundImage:
                window.pageYOffset <= 100
                  ? `url(${hausLogoComplete})`
                  : `url(${hausLogoJustLogo})`,
              width: window.pageYOffset <= 100 ? "240px" : "100px",
              marginTop: window.pageYOffset <= 100 ? 0 : 50,
              height: window.pageYOffset <= 100 ? "95px" : "90px",
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              zIndex: 1000,
              backgroundColor: window.pageYOffset <= 100 ? null : "#f4f9f4aa",
              borderRadius: window.pageYOffset <= 100 ? 0 : "50%",
            }}
          ></div>
        </Link>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            height: "100%",
            padding: 20,
          }}
        >
          {/* className is for hover effects only */}
          {/* <Link to="/">
            <div
              className={"menuItem"}
              style={{ color: "#000", fontWeight: 500 }}
            >
              HAUS
            </div>
          </Link> */}
          {/* <Link to="/classes">
            <div className={"menuItem"} style={{ color: "#000" }}>
              Classes
            </div>
          </Link>
          <Link to="/teachers">
            <div className={"menuItem"} style={{ color: "#000" }}>
              Teachers
            </div>
          </Link>
          <Link to="/wellbeing">
            <div className={"menuItem"} style={{ color: "#000" }}>
              Wellbeing
            </div>
          </Link> */}

          <Link to="/login">
            <div
              //  onClick={() => this.filteredClasses("duration", 60)}
              style={{
                display: "flex",
                height: "40px",
                width: "auto",
                padding: "8px 20px",
                border: "0.5px solid rgb(86,94,80)",
                borderRadius: "5px",
                backgroundColor: "#faf9f7",
                color: "rgb(86,94,80)",
                fontSize: "14px",
                fontWeight: "600",
                transition: "all .2s ease-in-out",
                cursor: "pointer",
                whiteSpace: "nowrap",
                fontWeight: 400,
                margin: 10,
              }}
            >
              Login
            </div>
          </Link>
          <Link to="/register">
            <div
              //  onClick={() => this.filteredClasses("duration", 60)}
              style={{
                display: "flex",
                height: "40px",
                width: "auto",
                padding: "8px 20px",
                border: "0.5px solid rgb(86,94,80)",
                borderRadius: "5px",
                backgroundColor: "rgb(86,94,80)",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "600",
                transition: "all .2s ease-in-out",
                cursor: "pointer",
                whiteSpace: "nowrap",
                fontWeight: 400,
                margin: 10,
              }}
            >
              Register
            </div>
          </Link>
        </div>
      </div>
    );
  }
}
