import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import EditTeacher from "../components/EditTeacherModal";
import { getTeachers } from "../network/basicContent";
import "../style/teachers.css";

// var teachers = [
//   {
//     photos: [
//       "https://source.unsplash.com/p-v1DBkTrgo",
//       "https://source.unsplash.com/p-v1DBkTrgo",
//     ],
//     videos: [],
//     bio:
//       "some Bio lorem some Bio loremsome Bio loremsome Bio loremsome Bio loremsome Bio lorem",
//     name: "Joaquim António",
//     email: "joaquim_antonio@email.com",
//     tags: ["pilates", "yoga"],
//     language: ["PT"],
//   },
// ];
export default class Classes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      teachers: [],
    };
  }
  async componentDidMount() {
    let teachers = await getTeachers();

    this.setState({ teachers: teachers ? teachers : [] });
  }
  render() {
    return (
      <div style={{ backgroundColor: "#faf9f7" }}>
        {/* {this.renderSpacingFromPageTop()} */}
        {
          this.props.user.role === "admin" ? (
            <EditTeacher teacherInfo={null} creatingNewTeacher={true} />
          ) : null // this.renderSpacingFromPageTop()
        }
        {this.renderClassesGrid()}
      </div>
    );
  }

  renderSpacingFromPageTop() {
    return <div style={{ height: 150, width: "100%" }}></div>;
  }

  renderClassesGrid() {
    return (
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <div
            style={{
              color: "black",
              height: "auto",
              width: "100%",
              padding: "2vh 2vw 2vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                padding: "10px",
                fontSize: "28px",
                fontWeight: "500",
                alignSelf: "start",
                fontFamily: "Abel",
                color: "rgb(86,94,80)",
              }}
            >
              Our Teachers
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                whiteSpace: "wrap",
                justifyContent: "center",
                alignItems: "center",
                // overflowX: "scroll",
                // overflowY: "hidden",
                width: "100%",
                height: "auto",
              }}
            >
              {this.state.teachers &&
                this.state.teachers.length > 0 &&
                this.state.teachers.map((teacher, index) => (
                  <Link to={"/teachers/" + teacher._id} key={index}>
                    <div
                      className={"shadowOnHover"}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "400px",
                        width: "300px",
                        backgroundColor: "#ccc",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${
                            teacher.photos[0]
                              ? teacher.photos[0]
                              : "https://haus-bucket-2020.s3.amazonaws.com/public/No_picture_available.png"
                          })`,
                          width: "100%",
                          height: "300px",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div>
                      <div
                        style={{
                          backgroundColor: "#fff",
                          height: "100px",
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{ fontSize: 20, color: "#000", padding: 5 }}
                        >
                          {teacher.name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",

                            height: "auto",
                            justifyContent: "flex-end",
                            //marginTop: "15vh",
                          }}
                        >
                          {teacher.tags.map((tag, index) => (
                            <div
                              key={index}
                              //  onClick={() => this.filteredClasses("duration", 60)}
                              style={{
                                display: "flex",
                                height: "34px",
                                width: "auto",

                                padding: "5px 10px",
                                border: "0.5px solid #333",
                                borderRadius: "24px",
                                backgroundColor: "#faf9f7",
                                color: "#333",
                                fontSize: "14px",
                                transition: "all .2s ease-in-out",
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                                fontWeight: 400,
                                margin: 10,
                                textTransform: "capitalize",
                              }}
                            >
                              {tag}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
