import React, { createContext } from "react";

export const AppContext = createContext();

export default class AppContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user: null, isAuthenticated: false };
  }

  setUserInfo(user) {
    this.setState({ user });
  }

  setAuth(isAuthenticated) {
    this.setState({ isAuthenticated });
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          setUserInfo: this.setUserInfo,
          setAuth: this.setAuth,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
