import {
  faClock,
  faLanguage,
  faLevelUpAlt,
  faMale,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import React from "react";
import ReactPlayer from "react-player";
import "../../style/classPage.css";
import EditMixedContent from "../../components/EditMixedContent";
import { getOneMixedContent } from "../../network/basicContent";
import { Link } from "react-router-dom";
import Linkify from "react-linkify";

const componentDecorator = (href, text, key) => (
  //detect links on strings. Links can be customized here
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);
export default class MixedContentPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { contentInfo: null, loadVideo: false };
  }
  async componentDidMount() {
    let contentId = this.props.match.params.contentId;
    // console.log("contentId :", contentId);
    let contentInfo = await getOneMixedContent(contentId);
    console.log("contentInfo :", contentInfo);
    this.setState({ contentInfo });
  }

  render() {
    return (
      <div style={{ backgroundColor: "#fff" }}>
        {this.state.contentInfo ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: 50,
            }}
          >
            {this.props.user.role === "admin" ? (
              <EditMixedContent
                classInfo={this.state.contentInfo}
                creatingNewClass={false}
              />
            ) : (
              this.renderSpacingFromPageTop()
            )}
            <div
              style={{
                maxWidth: "500px",
                width: "100%",
                height: "auto",
                padding: 20,
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              {this.renderTitleAndDate()}

              {this.renderArticlePicture()}
              {this.renderSubtitle()}
              {this.renderArticleText()}
              {this.renderGoBackButton()}
              {/* {this.renderClassSpecs()} */}

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: 300,
                  border: "1px solid #000",
                }}
              >
                <div> Video not yet available</div>
              </div> */}
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  }

  renderGoBackButton() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            this.props.history.goBack();
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40px",
            width: "100px",

            border: "0.5px solid #797979",
            borderRadius: "5px",
            backgroundColor: "#797979",
            color: "#faf9f7",
            fontFamily: "Abel",
            fontSize: "16px",
            fontWeight: "600",
            transition: "all .2s ease-in-out",
            cursor: "pointer",
            margin: 5,
          }}
        >
          Go Back
        </div>
      </div>
    );
  }
  renderSpacingFromPageTop() {
    return <div style={{ height: 40, width: "100%" }}></div>;
  }

  renderTitleAndDate() {
    let type = this.props.type;
    let title = "";
    if (type === "beauty") {
      title = "Beauty Affairs";
    }
    if (type === "books") {
      title = "Books";
    }

    return (
      <div>
        <div
          style={{
            fontSize: "30px",
            fontWeight: "500",
            alignSelf: "start",
            fontFamily: "Abel",
          }}
        >
          {title}
        </div>

        <div
          style={{
            fontSize: "20px",
            fontWeight: "300",
            alignSelf: "start",
            fontFamily: "Abel",
          }}
        >
          {this.state.contentInfo.updatedAt &&
            moment(this.state.contentInfo.updatedAt).format(
              "dddd, DD MMMM YYYY"
            )}
        </div>
      </div>
    );
  }

  renderArticlePicture() {
    return (
      <div
        style={{
          backgroundImage: `url(${
            this.state.contentInfo.photos[0]
              ? this.state.contentInfo.photos[0]
              : "https://haus-bucket-2020.s3.amazonaws.com/public/No_picture_available.png"
          })`,
          width: "100%",
          height: "500px",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          marginTop: 25,
        }}
      ></div>
    );
  }

  renderSubtitle() {
    return (
      <div
        style={{
          fontSize: "20px",
          fontWeight: "300",
          alignSelf: "start",
          fontFamily: "Abel",
          marginTop: 10,
        }}
      >
        {this.state.contentInfo.title && this.state.contentInfo.title}
      </div>
    );
  }

  renderArticleText() {
    return (
      <div
        style={{
          fontSize: "16px",
          fontWeight: "500",
          alignSelf: "start",
          fontFamily: "Roboto",
          color: "#000",
          marginTop: 20,
          marginBottom: 40,
        }}
      >
        <Linkify componentDecorator={componentDecorator}>
          {this.state.contentInfo.description &&
            this.state.contentInfo.description.split("\n").map((sentence) => {
              return (
                <div>
                  {sentence}
                  <br />
                </div>
              );
            })}
        </Linkify>
      </div>
    );
  }
}
