import { Col, Row, Modal as AntdModal } from "antd";
import React from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import EditTeacher from "../components/EditTeacherModal";
import { getOneTeacher, getClasses } from "../network/basicContent";
import { Link } from "react-router-dom";
import instagramLogo from "../media/instagram-logo.png";
import facebookLogo from "../media/facebook_logo.png";
export default class TeacherProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      teacher: null,
      loading: true,
      openPhotoViewer: false,
      currentImage: 0,
      showingBioModal: false,
    };
  }

  async componentDidMount() {
    let teacherId = this.props.match.params.teacherId;

    let classes = await getClasses();
    await getOneTeacher(teacherId).then((teacherInfo) => {
      console.log("teacherInfo received:", teacherInfo);

      console.log("classes: ", classes);

      this.setState({
        classes,
        teacher: teacherInfo,
        loading: false,
      });
    });
  }
  render() {
    console.log("teacher", this.state.teacher);

    return (
      <div style={{ backgroundColor: "#faf9f7" }}>
        {this.state.loading ? (
          <div>Loading</div>
        ) : (
          <div>
            {this.props.user.role === "admin" ? (
              <EditTeacher
                teacherInfo={this.state.teacher}
                creatingNewTeacher={false}
              />
            ) : null}

            {this.renderTeacherHero()}
            {this.state.teacher.photos.lenght > 1
              ? this.renderPhotoGallery()
              : null}
            {this.renderPhotoViewerModal()}
            {this.renderClassesGrid()}
            {this.renderBioModal()}
          </div>
        )}
      </div>
    );
  }

  renderPhotoGallery() {
    return (
      <Row type="flex" align="middle">
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {this.state.teacher.photos.map((photo, index) => (
              <div
                onClick={() => {
                  //   console.log("openPhotoViewer", openPhotoViewer, photo, index);
                  this.setState({ openPhotoViewer: true, currentImage: index });
                }}
                className={"shadowOnHover"}
                style={{
                  backgroundImage: `url(${photo})`,
                  width: "300px",
                  height: "300px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  margin: 5,
                  cursor: "pointer",
                }}
              ></div>
            ))}
          </div>

          {/* <Gallery
            onClick={(openPhotoViewer, { photo, index }) => {
              console.log("openPhotoViewer", openPhotoViewer, photo, index);
              this.setState({ openPhotoViewer: true, currentImage: index });
            }}
            photos={this.state.teacher.photos.map((photo) => ({
              sizes: [
                "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw",
              ],
              src: photo,
              width: 1,
              height: 1,
            }))}
          /> */}
        </Col>
      </Row>
    );
  }

  renderPhotoViewerModal() {
    let galleryArray = this.state.teacher.photos.map((photo) => ({
      src: photo,
      width: 1,
      height: 1,
    })); //transform array to be compatible

    return (
      <ModalGateway>
        {this.state.openPhotoViewer ? (
          <Modal
            onClose={() =>
              this.setState({ openPhotoViewer: false, currentImage: 0 })
            }
          >
            <Carousel
              style={{ margin: 30 }}
              currentIndex={this.state.currentImage}
              views={galleryArray.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    );
  }

  renderTeacherHero() {
    console.log("this.state.teacher.photos", this.state.teacher.photos);
    return (
      <Row type="flex" align="top" justify="start">
        <Col xs={{ span: 24 }} md={{ span: 11 }}>
          <div
            style={{
              alignSelf: "center",
              justifySelf: "center",
              backgroundImage: `url(${this.state.teacher.photos[0]})`,
              width: "100%",
              height: "550px",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              // marginTop: "15vh",
            }}
          ></div>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 13 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              height: "550px",
              padding: 30,
              //marginTop: "15vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              backgroundColor: "#eee",
            }}
          >
            <div
              style={{
                fontSize: "48px",
                //padding: "10px",
                color: "#000",
                height: "auto",
                width: "300px",
                lineHeight: "0.9",
                textTransform: "uppercase",
                fontFamily: "Abel",
              }}
            >
              {this.state.teacher.name}
            </div>
            <div
              style={{
                fontSize: this.props.matches.small ? 18 : 24,
                padding: "20px",
                color: "#000",
                fontFamily: "Abel",
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "calc (100%)",
                width: "calc (100%)",
              }}
            >
              "
              {this.state.teacher.smallBio.length > 400
                ? this.state.teacher.smallBio.slice(0, 400) + "..."
                : this.state.teacher.smallBio}
              "
            </div>

            <div
              onClick={() => this.setState({ showingBioModal: true })}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                //margin: "50px",
                border: "1px solid #fff",
                borderRadius: "10px",

                width: "150px",
                height: "50px",
                backgroundColor: "rgb(237,194,167)",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Bio
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  renderBioModal() {
    return (
      <AntdModal
        title="Teacher Bio"
        visible={this.state.showingBioModal}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        style={{ height: "80vh" }}
        width={"80vw"}
      >
        <Row type="flex" align="top" justify="center">
          <Col xs={{ span: 24 }} md={{ span: 11 }}>
            <div
              style={{
                // alignSelf: "center",
                // justifySelf: "center",
                backgroundImage: `url(${
                  this.state.teacher.photos[1] //if theres 2 pics, put the second
                    ? this.state.teacher.photos[1]
                    : this.state.teacher.photos[0]
                })`,
                width: "100%",
                height: "600px",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                // marginTop: "15vh",
              }}
            ></div>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 13 }}>
            <div
              style={{
                width: "100%",
                height: "auto",
                //marginTop: "15vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <div
                style={{
                  fontSize: "62px",
                  padding: "20px",
                  color: "#000",
                  height: "auto",
                  width: "300px",
                  lineHeight: "0.9",
                  fontFamily: "Abel",
                }}
              >
                {this.state.teacher.name}
              </div>
              <div
                style={{
                  fontSize: 16,
                  padding: "20px",
                  color: "#000",
                }}
              >
                {this.state.teacher.bio.split("\n").map((sentence) => {
                  return (
                    <div>
                      {sentence}
                      <br />
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  fontSize: "16px",
                  padding: "10px",
                  paddingBottom: 0,
                  fontWeight: 500,
                  color: "#000",
                }}
              >
                Contact for private classes:
              </div>
              <div style={{ fontSize: "18px", padding: "10px", color: "#999" }}>
                {this.state.teacher.email}
              </div>
              <div
                style={{
                  width: "100%",
                  height: 100,
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {this.state.teacher.instagram ? (
                  <div
                    onClick={() => window.open(this.state.teacher.instagram)}
                    style={{
                      cursor: "pointer",
                      margin: 10,
                      backgroundImage: `url(${instagramLogo})`,
                      width: "50px",
                      height: "50px",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                ) : null}
                {this.state.teacher.facebook ? (
                  <div
                    onClick={() => window.open(this.state.teacher.facebook)}
                    style={{
                      cursor: "pointer",
                      margin: 10,
                      backgroundImage: `url(${facebookLogo})`,
                      width: "50px",
                      height: "50px",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </AntdModal>
    );
  }
  handleOk = (e) => {
    //console.log(e);
    this.setState({
      showingBioModal: false,
    });
  };

  handleCancel = (e) => {
    //console.log(e);
    this.setState({
      showingBioModal: false,
    });
  };

  renderClassesGrid() {
    if (this.state.classes.length > 0)
      return (
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }}>
            <div
              style={{
                color: "black",
                height: "auto",
                width: "100%",
                padding: "10vh 2vw 2vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  //paddingTop: 50,
                  padding: "10px",
                  fontSize: "25px",
                  fontWeight: "500",
                  alignSelf: "start",
                  fontFamily: "Abel",
                }}
              >
                Some classes from {this.state.teacher.name}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  whiteSpace: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  // overflowX: "scroll",
                  // overflowY: "hidden",
                  width: "100%",
                  height: "auto",
                }}
              >
                {this.state.classes.map((_class) => {
                  if (
                    _class.teachers.some(
                      (teacher) => teacher.name === this.state.teacher.name
                    )
                  )
                    return (
                      <Link to={"/classes/" + _class._id}>
                        <div
                          className={"shadowOnHover"}
                          onMouseEnter={() => {
                            console.log(_class._id);
                            this.setState({ classOnHover: _class._id });
                          }}
                          onMouseLeave={() =>
                            this.setState({ classOnHover: null })
                          }
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "420px",
                            width: "300px",
                            background:
                              this.state.classOnHover === _class._id
                                ? "#000"
                                : null,

                            margin: "10px",
                            zIndex: 1000,
                          }}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${
                                _class.photos[0]
                                  ? _class.photos[0]
                                  : "https://haus-bucket-2020.s3.amazonaws.com/public/No_picture_available.png"
                              })`,
                              width: "100%",
                              height: "300px",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              zIndex: 500,
                            }}
                          ></div>
                          <div
                            style={{
                              backgroundColor: "#fff",
                              height: "120px",
                              width: "100%",
                              padding: "10px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 15,
                                color: "#000",
                                padding: 5,
                              }}
                            >
                              {_class.title}
                            </div>
                            {/* <div
                          style={{ fontSize: 10, color: "#000", padding: 5 }}
                        >
                          with <strong>{_class.teachers[0].name}</strong>
                        </div> */}

                            <div
                              style={{
                                display: "flex",
                                flex: "row",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: 18,
                                  color: "#888",
                                  padding: 5,
                                }}
                              >
                                {_class.typeOfClass}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: 12,

                                  color: "#000",
                                  padding: 5,
                                  fontWeight: 500,
                                  width: "auto",
                                  height: "40px",
                                  //  borderRadius: "50%",
                                  //  border: "1px solid #888",
                                }}
                              >
                                Time: {_class.duration}min
                              </div>
                              <div
                                style={{
                                  fontSize: 13,
                                  color: "#000",
                                  padding: 5,
                                  fontWeight: 500,
                                }}
                              >
                                Level: {_class.difficulty}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                })}
              </div>
            </div>
          </Col>
        </Row>
      );
  }
}
