// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsInfo = {
  aws_app_analytics: "disabled",
  aws_cognito_identity_pool_id:
    "us-east-1:e5760556-e0ca-491b-9f43-d6afbd0a27e3",
  aws_cognito_region: "us-east-1",
  //aws_project_id: '',
  aws_project_name: "haus",
  aws_project_region: "us-east-1",
  aws_resource_name_prefix: "",
  aws_sign_in_enabled: "enable",
  aws_user_files: "enable",
  aws_user_files_s3_bucket: "haus-bucket-2020",
  aws_user_files_s3_bucket_region: "us-east-1",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_uHbfBd4aQ",
  aws_user_pools_web_client_id: "66kr30h4okj5j2d4ktk2g8g6f5",
};

export default awsInfo;
