import { Auth } from "aws-amplify";

let authenticate = async () => {
  let userInfo;
  try {
    userInfo = await Auth.currentAuthenticatedUser({
      bypassCache: false,
    });
  } catch (err) {
    console.log("[network common] authentication error: ", err);
    return null;
  }
  return userInfo;
};

var address;
if (process.env.NODE_ENV === "production") {
  address = "https://be-haus.com/api";
} else {
  address = "http://localhost:3000";
}

//eslint-disable-next-line
//import { handleResponse, authenticate } from "./common";

export async function getAllUsers() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  if (!userInfo) {
    return null;
  }
  console.log("userInfo", userInfo);

  const response = await fetch(address + "/users", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
    //body: { userInfo: userInfo.attributes }, //in case user is not yet on BE - will be save
  });

  return response.json();
  //return await handleResponse(response);
}

export async function getOneUser(userId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  if (!userId) {
    throw "user ID needed";
  }

  const response = await fetch(address + "/users/" + userId, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
  });

  return response.json();

  //return await handleResponse(response);
}

export async function registerUser(userId, userProfile) {
  console.log("Registering id: ", userId, "userProfile:", userProfile);
  // const userInfoCognito = await authenticate();
  // if (!userInfoCognito) {
  //   return null;
  // }
  // if (!userId) {
  //   throw "userId needed";
  // }

  const response = await fetch(address + "/users/register", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // jwt: userInfoCognito.signInUserSession.accessToken.jwtToken,
    },
    body: JSON.stringify({ ...userProfile }),
  });

  let responseJson = response.json();
  //console.log("Register response: ", responseJson);
  return responseJson;

  //return await handleResponse(response);
}
export async function resetSubscription(customerId) {
  //console.log("Registering id: ", customerId, "userProfile:", userProfile);
  const userInfoCognito = await authenticate();
  if (!userInfoCognito) {
    return null;
  }
  if (!customerId) {
    throw "customerId needed";
  }

  const response = await fetch(address + "/users/reset-subscription", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfoCognito.signInUserSession.accessToken.jwtToken,
    },
    body: JSON.stringify({ customerId }),
  });

  let responseJson = response.json();
  //console.log("Register response: ", responseJson);
  return responseJson;

  //return await handleResponse(response);
}

export async function changeUserRole(userId, isAdmin) {
  console.log("changeUserRole id: ", userId, "isAdmin?:", isAdmin);
  const userInfoCognito = await authenticate();
  if (!userInfoCognito) {
    return null;
  }
  if (!userId) {
    throw "userId needed";
  }

  const response = await fetch(address + "/users/" + userId, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfoCognito.signInUserSession.accessToken.jwtToken,
    },
    body: JSON.stringify({ userId: userId, role: isAdmin ? "admin" : "user" }),
  });

  let responseJson = response.json();
  console.log("Register response: ", responseJson);
  return responseJson;

  //return await handleResponse(response);
}

export async function getClasses() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(address + "/classes", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
  });
  let responseJson = await response.json();
  return responseJson;
  //return await handleResponse(response);
}

export async function getPaymentDetails(stripeCustomerId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  let response = await fetch(address + "/users/stripe-billing", {
    method: "post",
    headers: {
      "Content-type": "application/json",
      jwt: userInfo.signInUserSession.accessToken.jwtToken,
    },
    body: JSON.stringify({
      stripeCustomerId: stripeCustomerId,
    }),
  });

  let responseJson = await response.json();
  return responseJson;
}

export async function getSubscriptionDetails(stripeSubscriptionId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  let response = await fetch(
    address + "/users/stripe-customer/" + stripeSubscriptionId,
    {
      method: "get",
      headers: {
        "Content-type": "application/json",
        jwt: userInfo.signInUserSession.accessToken.jwtToken,
      },
    }
  );

  let responseJson = await response.json();
  return responseJson;
}

// export async function postNewPhoto(photoS3Url, teacherId, action) {
//   //Post one Photo
//   const userInfo = await authenticate();
//   if (!userInfo) {
//     return null;
//   }
//   const response = await fetch(address + "/teachers/photo/" + teacherId, {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       jwt: userInfo.signInUserSession.accessToken.jwtToken,
//     },
//     body: JSON.stringify({ photoURL: photoS3Url, action: action }),
//   });

//   console.log("response: ", response);
//   // let responseJson = await response.json();
//   // console.log("responseJson: ", responseJson);

//   return response;
//   //return await handleResponse(response);
// }

// export async function putTeacherInfo(teacherId, info) {
//   const userInfo = await authenticate();
//   if (!userInfo) {
//     return null;
//   }
//   const response = await fetch(address + "/teachers/info/" + teacherId, {
//     method: "PUT",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       jwt: userInfo.signInUserSession.accessToken.jwtToken,
//     },
//     body: JSON.stringify({ teacherInfo: info }),
//   });
//   return response;
//   //return await handleResponse(response);
// }
// export async function saveNewTeacher(info) {
//   const userInfo = await authenticate();
//   if (!userInfo) {
//     return null;
//   }
//   const response = await fetch(address + "/teachers", {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       jwt: userInfo.signInUserSession.accessToken.jwtToken,
//     },
//     body: JSON.stringify({ teacherInfo: info }),
//   });
//   return response;
//   //return await handleResponse(response);
// }
