import {
  faClock,
  faLanguage,
  faLevelUpAlt,
  faMale,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import React from "react";
import ReactPlayer from "react-player";
import "../../style/classPage.css";
import EditMixedContent from "../../components/EditMixedContent";
import { getOneMixedContent } from "../../network/basicContent";
import { Link } from "react-router-dom";
export default class FoodPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { classInfo: null, loadVideo: false };
  }
  async componentDidMount() {
    let recipeId = this.props.match.params.recipeId;
    console.log("recipeId :", recipeId);
    let classInfo = await getOneMixedContent(recipeId);
    console.log("recipeInfo :", classInfo);
    this.setState({ classInfo, loadVideo: true });
  }

  render() {
    return (
      <div style={{ backgroundColor: "#fff" }}>
        {this.state.classInfo ? (
          <div>
            {this.props.user.role === "admin" ? (
              <EditMixedContent
                classInfo={this.state.classInfo}
                creatingNewClass={false}
                type={"food"}
              />
            ) : (
              this.renderSpacingFromPageTop()
            )}
            {this.renderClassSpecs()}
            {this.state.loadVideo && this.state.classInfo.videos[0] ? (
              this.renderVideoPlayer(this.state.classInfo.videos[0])
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: 300,
                  border: "1px solid #000",
                }}
              >
                <div> Video not yet available</div>
              </div>
            )}
          </div>
        ) : (
          <div>loading</div>
        )}
      </div>
    );
  }

  renderSpacingFromPageTop() {
    return <div style={{ height: 40, width: "100%" }}></div>;
  }

  renderClassSpecs(classSpecs) {
    return (
      <Row type="flex" align="start" justify="center">
        <Col xs={{ span: 24 }} md={{ span: 16 }}>
          <div
            style={{
              width: "auto",
              height: "auto",
              // backgroundColor: "#faf9f7",
              borderRadius: "20px",
              margin: 10,
            }}
          >
            <div
              style={{ fontSize: 30, padding: 0, lineHeight: 2, color: "#000" }}
            >
              {this.state.classInfo.title}
            </div>
            <div style={{ fontSize: 18, padding: 0, color: "#888" }}>
              {this.state.classInfo.description.split("\n").map((sentence) => {
                return (
                  <div>
                    {sentence}
                    <br />
                  </div>
                );
              })}
            </div>
          </div>
        </Col>

        <Col xs={{ span: 24 }} md={{ span: 3 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              width: "auto",
              height: "auto",
              // backgroundColor: "#faf9f7",
              // border: "solid 1px #ccc",
              borderRadius: "18px",
              margin: 10,
            }}
          >
            <div
              //language
              style={{
                height: 100,
                width: 100,
                overflow: "hidden",
                // backgroundColor: "#fff",
                color: "#000",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 5,
              }}
            >
              {/* <div>
                <FontAwesomeIcon
                  icon={faLanguage}
                  style={{ width: 45, height: 45, color: "#ccc" }}
                />
              </div> */}
              <div style={{ fontSize: 10, padding: 0, lineHeight: 2 }}>
                Language
              </div>
              <div
                style={{
                  fontSize: 15,
                  padding: 0,
                  lineHeight: 1,
                  fontWeight: 700,
                  color: "#444",
                }}
              >
                {this.state.classInfo.language[0]}
              </div>
            </div>
            {/* <div
              //class type
              style={{
                height: 100,
                width: 100,
                overflow: "hidden",
                backgroundColor: "#fff",
                color: "#000",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 5,
              }}
            >
          
              <div style={{ fontSize: 10, padding: 0, lineHeight: 2 }}>
                Lesson
              </div>
              <div
                style={{
                  fontSize: 15,
                  padding: 0,
                  lineHeight: 1,
                  fontWeight: 700,
                  color: "#444",
                }}
              >
                {this.state.classInfo && this.state.classInfo.type}
              </div>
            </div>
            <div
              //Difficulty
              style={{
                height: 100,
                width: 100,
                overflow: "hidden",
                // backgroundColor: "#fff",
                color: "#000",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 5,
              }}
            >
             
            </div> */}
          </div>
        </Col>
      </Row>
    );
  }

  renderVideoPlayer(videoLink) {
    //FIX - https://vimeo.com/428201296 this is a normal VIMEO url that is not embeded. We need to extract the ID of the video on VIMEO
    //The problem - administrators can not properly copy the embeded code, so we have to make a hack, making their life easy - copying the simple vimeo link
    let videoVimeoId = videoLink.split("/").pop();
    //maybe temporary

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 20,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "auto",
            backgroundColor: "#eee",
            padding: 10,
          }}
        >
          <iframe
            src={"https://player.vimeo.com/video/" + videoVimeoId}
            width="100%"
            height="600"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullscreen
          ></iframe>
        </div>
        {this.renderGoBackButton()}
      </div>
    );
  }

  renderGoBackButton() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          margin: 50,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            this.props.history.goBack();
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40px",
            width: "100px",
            padding: "8px 20px",
            border: "0.5px solid #797979",
            borderRadius: "5px",
            backgroundColor: "#797979",
            color: "#faf9f7",
            fontFamily: "Abel",
            fontSize: "16px",
            fontWeight: "600",
            transition: "all .2s ease-in-out",
            cursor: "pointer",
            margin: 5,
          }}
        >
          Go Back
        </div>
      </div>
    );
  }
}
