import React from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Checkbox,
  message,
  notification,
  Select,
  Result,
} from "antd";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./../../aws-exports";
import { SmileOutlined } from "@ant-design/icons";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { registerUser } from "../../network/user";
import hausLogoJustLogo from "../../media/logoHaus_green.png";

import { Redirect } from "react-router-dom";

const { Option } = Select;

Amplify.configure(awsconfig);
const openNotification = () => {
  notification.open({
    message: "Payment method",
    description: "After providing a payment method, confirm your email!",
    icon: <SmileOutlined style={{ color: "#888" }} />,
    duration: 10,
  });
};
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const onFinish = (values) => {
  console.log("Success:", values);
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};
export default class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      secondPassword: "",
      family_name: "",
      given_name: "",
      goals: "",
      level: "Level 1",
      country: "",
    };
  }

  render() {
    return (
      <div>
        {this.state.redirectToPayment ? (
          <Redirect
            to={{
              pathname: "/payment",
              state: {
                email: this.state.email,
                stripeCustomer: this.state.stripeCustomer,
              },
            }}
          />
        ) : null}
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 10 }}>
            <div
              style={{
                //height: "85vh",
                height: "auto",
                //overflowY: "scroll",
                backgroundColor: "#d9e4d1",
                padding: 10,
                paddingTop: 20,
                paddingBottom: 30,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "400px",
                  paddingTop: "20px",
                  backgroundColor: "#d9e4d1",
                  width: "100%",
                  height: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "auto",
                    overflowY: "scroll",
                    //backgroundColor: "red",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url( ${hausLogoJustLogo})`,
                      height: 100,
                      width: 100,

                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "20% 50%",
                    }}
                  ></div>
                  <div
                    style={{
                      fontFamily: "Abel",
                      fontSize: 35,
                      fontWeight: 400,
                      margin: 20,
                    }}
                  >
                    Create your Account
                  </div>

                  {this.renderRegisterForm()}

                  <div
                    style={{
                      margin: 2,
                      color: "#000",
                      fontSize: 8,
                    }}
                  >
                    ©2020 Thaís Caniceiro Unipessoal Lda
                  </div>
                  <div
                    style={{
                      margin: 2,
                      color: "#000",
                      fontSize: 10,
                    }}
                  >
                    All rights reserved
                  </div>
                  <div
                    onClick={() => {
                      window.open("https://be-haus.com/terms-conditions");
                    }}
                    style={{
                      margin: 2,
                      color: "#000",
                      fontSize: 10,
                      cursor: "pointer",
                    }}
                  >
                    By registering you are agreeing with Terms and Conditions
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 14 }}>
            <div
              style={{
                width: "100%",
                minHeight: "40vh",
                height: "100%",
                backgroundImage:
                  'url("https://source.unsplash.com/SAFYn1O-qyw")',
                backgroundPosition: "43% 0%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Col>
        </Row>
      </div>
    );
  }

  renderRegisterForm() {
    return (
      <Form
        //  {...layout}
        style={{
          width: "100%",
          maxWidth: "400px",
          height: "auto",
          backgroundColor: "rgb(218,228,211)",
        }}
        name="register form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        // wrapperCol={{ span: 24 }}
      >
        <Form.Item
          //   label="First Name"
          style={{ display: "inline-block", margin: "5px" }}
          name="firstName"
          //labelCol={{ span: 6 }}
          rules={[
            {
              required: true,
              message: "Please input your first name.",
            },
          ]}
        >
          <Input
            onChange={(input) =>
              this.setState({ given_name: input.target.value })
            }
            value={this.state.given_name}
            placeholder={"First Name"}
            style={{
              border: "0.5px solid #000",
              borderRadius: 12,
              maxWidth: 190,
              width: "100%",
              height: 40,
              fontFamily: "roboto",
              fontSize: 18,
            }}
          />
        </Form.Item>
        <Form.Item
          //   label="First Name"
          style={{ display: "inline-block", margin: "5px" }}
          name="lastName"
          //labelCol={{ span: 6 }}
          rules={[
            {
              required: true,
              message: "Please input your last name.",
            },
          ]}
        >
          <Input
            value={this.state.family_name}
            onChange={(input) =>
              this.setState({ family_name: input.target.value })
            }
            placeholder={"Last Name"}
            style={{
              fontFamily: "roboto",
              fontSize: 18,
              border: "0.5px solid #000",
              borderRadius: 12,
              width: 190,
              height: 40,
            }}
          />
        </Form.Item>
        <br />
        <Form.Item
          //   label="First Name"
          style={{ display: "inline-block", margin: "5px" }}
          name="country"
          //labelCol={{ span: 6 }}
          rules={[
            {
              required: true,
              message: "Please input your country",
            },
          ]}
        >
          <CountryDropdown
            value={this.state.country}
            onChange={(val) => this.setState({ country: val })}
            style={{
              fontFamily: "roboto",
              fontSize: 18,
              border: "0.5px solid #000",
              borderRadius: 12,
              width: 280,
              height: 40,
            }}
          />
        </Form.Item>
        <Form.Item
          //   label="First Name"
          style={{ display: "inline-block", margin: "5px" }}
          name="age"
          //labelCol={{ span: 6 }}
          rules={[
            {
              required: true,
              message: "Please input your age.",
            },
          ]}
        >
          <Input
            onChange={(input) => this.setState({ age: input.target.value })}
            placeholder={"Age"}
            style={{
              fontFamily: "roboto",
              fontSize: 18,
              display: "inline-block",
              border: "0.5px solid #000",
              borderRadius: 12,
              width: 100,
              height: 40,
            }}
          />
        </Form.Item>
        <Form.Item
          //  label="Email"
          style={{
            display: "inline-block",
            margin: "5px",
            paddingRight: "5px",
            width: "100%",
            maxWidth: 390,
          }}
          name="email"
          large
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input your email.",
            },
          ]}
        >
          <Input
            onChange={(input) => this.setState({ email: input.target.value })}
            placeholder={"Email"}
            style={{
              fontFamily: "roboto",
              fontSize: 18,
              border: "0.5px solid #000",
              borderRadius: 12,
              width: "100%",
              maxWidth: 390,
              height: 40,
            }}
          />
        </Form.Item>
        <br />
        <Form.Item
          //   label="First Name"
          style={{ display: "inline-block", margin: "5px" }}
          name="level"
          //labelCol={{ span: 6 }}
          rules={[
            {
              required: true,
              message: "Please input your desired level.",
            },
          ]}
        >
          <select
            placeholder={"Level"}
            name="Level"
            id="Level"
            style={{
              fontFamily: "roboto",
              fontSize: 18,
              border: "0.5px solid #000",
              borderRadius: 12,
              width: 190,
              height: 40,
            }}
            onChange={(input) => this.setState({ level: input.target.value })}
          >
            <option value="Level 1">Level 1</option>
            <option value="Level 2">Level 2</option>
            <option value="Level 3">Level 3</option>
          </select>
        </Form.Item>

        <Form.Item
          //   label="First Name"
          style={{ display: "inline-block", margin: "5px" }}
          name="goals"
          //labelCol={{ span: 6 }}
          rules={[
            {
              required: true,
              message: "Please input your goals.",
            },
          ]}
        >
          {/* <Input
            onChange={(input) => this.setState({ goals: input.target.value })}
            placeholder={"Goals"}
            style={{
              fontFamily: "roboto",
              fontSize: 18,
              border: "0.5px solid #000",
              borderRadius: 12,
              width: 190,
              height: 40,
            }}
          /> */}

          <select
            // defaultValue={"Stress Control"}
            style={{
              fontFamily: "roboto",
              fontSize: 18,
              border: "0.5px solid #000",
              borderRadius: 12,
              width: 190,
              height: 40,
            }}
            onChange={(input) => this.setState({ goals: input.target.value })}
            placeholder={"Goals"}
          >
            <option value="" disabled selected>
              Your goals
            </option>
            <option value="Stress Control">Stress Control</option>
            <option value="Pilates Method">Pilates Method</option>
            <option value="Yoga">Yoga</option>
            <option value="Meditation">Meditation</option>
            <option value="Pre/Post Birth"> Pre/Post Birth</option>
            <option value="Injury recovered "> Injury recover </option>
            <option value="Physical Conditioning">Physical Conditioning</option>
          </select>
        </Form.Item>
        <br />
        <Form.Item
          style={{ display: "inline-block", margin: "5px" }}
          //label="Password"
          name="password"
          large
          rules={[
            {
              required: true,
              message: "Please choose your password!",
            },
          ]}
        >
          <Input.Password
            placeholder={"Password"}
            onChange={(input) =>
              this.setState({ password: input.target.value })
            }
            style={{
              fontFamily: "roboto",
              fontSize: 18,
              border: "0.5px solid #000",
              borderRadius: 12,
              width: 190,
              height: 40,
            }}
          />
        </Form.Item>
        <Form.Item
          //   label="Repeat Password"
          style={{
            display: "inline-block",
            margin: "5px",
            fontFamily: "roboto",
            fontSize: 18,
          }}
          name="secondPassword"
          large
          rules={[
            {
              required: true,
              message: "Please repeat password",
            },
          ]}
        >
          <Input.Password
            placeholder={"Repeat Password"}
            onChange={(input) =>
              this.setState({ secondPassword: input.target.value })
            }
            style={{
              fontFamily: "roboto",
              fontSize: 18,
              border: "0.5px solid #000",
              borderRadius: 12,
              width: 190,
              height: 40,
            }}
          />
        </Form.Item>
        {/* <Form.Item
        {...tailLayout}
        name="remember"
        valuePropName="checked"
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item> */}
        <Form.Item
          // {...tailLayout}
          style={{
            margin: "5px",
            width: "100%",
            paddingRight: "5px",
            fontFamily: "roboto",
            fontSize: 18,
            display: "inline-block",
            marginTop: 20,
          }}
        >
          {/* <Button type="primary"  htmlType="submit"> */}

          <div
            // htmlType="submit"

            onClick={() => {
              if (this.state.password !== this.state.secondPassword) {
                message.warning("Passwords are different.");
                return;
              }

              this.formIsReady()
                ? this.signUp()
                : message.warning("Check missing fields");
            }}
            style={{
              display: "flex",
              height: "40px",

              justifyContent: "center",
              alignItems: "center",
              // padding: "8px 20px",
              border: this.formIsReady()
                ? "0.5px solid #000"
                : "3px solid #fff",
              borderRadius: "12px",
              backgroundColor: this.formIsReady()
                ? "rgb(86,94,80)"
                : "rgb(86,94,80)",
              color: "#fff",
              fontSize: "14px",
              transition: "all .2s ease-in-out",
              cursor: "pointer",
              //  whiteSpace: "nowrap",
              fontWeight: 500,
              //margin: 5,
            }}
          >
            Register
          </div>
          {/* </Button> */}
        </Form.Item>
      </Form>
    );
  }

  formIsReady() {
    return (
      this.state.email &&
      this.state.family_name &&
      this.state.given_name &&
      this.state.level &&
      this.state.goals &&
      this.state.password &&
      this.state.secondPassword &&
      this.state.password === this.state.secondPassword
    );
  }
  async signUp() {
    let email = this.state.email;
    let password = this.state.password;

    this.setState({ isLoading: true });
    const hide = message.loading("Signing you up ...", 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 2500);

    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email: this.state.email,
          family_name: this.state.family_name,
          given_name: this.state.given_name,
        },
      }).then(async (user) => {
        console.log("Sign Up result: ", user);
        this.setState({ isLoading: false });

        let userProfile = {
          id: user.userSub,
          email: this.state.email,
          name: this.state.given_name + " " + this.state.family_name,
          age: this.state.age,
          level: this.state.level,
          goals: this.state.goals,
          country: this.state.country,
          role: "user", //must be validated on BE
        };
        console.log("registering on BE", user);
        let registerResult = await registerUser(user.userSub, userProfile).then(
          (result) => {
            openNotification();
            //console.log("result: ", result);

            this.setState({
              stripeCustomer: result.data.stripeCustomer,
              redirectToPayment: true,
            });
          }
        );
        // console.log("registerResult: ", registerResult);
        // window.location.reload();
      });
      //  console.log({ user });
    } catch (error) {
      console.log("error signing up:", error);
      message.error({
        content: error.message,
        className: "custom-class",
        style: {
          marginTop: "20vh",
        },
      });
      this.setState({ isLoading: false });
    }
  }
}
