import { Col, Row } from "antd";
import React from "react";
import Slider from "react-slick";
import imgVera from "../media/teachers/vera2.png";
import { Link } from "react-router-dom";

import PilatesPic from "../media/localImages/pilatesPic.jpg";
import { FastForwardOutlined } from "@ant-design/icons";
import { RightOutlined } from "@ant-design/icons";
import { Parallax } from "react-scroll-parallax";
import arrowNext from "../media/localImages/next.png";
import arrowPrevious from "../media/localImages/previous.png";
import BancoLeite_Logo from "../media/localImages/BancoLeite_Logo.png";

//import "../style/home.css";
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const sentences = [
  {
    sentence:
      "I've been an athlete and active all my life and it was only when I began working with Jacqueline that I began to better understand proper movement and muscle use. After two years, I'm still learning and strengthening my body, which at my age is essential. Jacqueline is amazing!",
    who: "Robert McCoole, USA",
  },
  {
    sentence:
      "The online Pilates classes strongly contribute to keeping my  physical and spiritual balance, whenever I need, wherever I am.",
    who: "Teresa Romão, Portugal",
  },
  {
    sentence:
      "Stefano is a a wonderful teacher who truly teaches from his heart. His knowledge in yoga goes beyond asana, and his ability to explain alignment has helped me in my own practice.",
    who: "Katelyne Suttleworth, Canadá",
  },
];
export default class Home extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div style={{ backgroundColor: "#faf9f7" }}>
        {this.renderHeroHomeBlock()}
        {/* {this.renderSentenceSlider()} */}
        {this.renderHomeClassTypes()}
        {this.renderBancodeLeite()}
      </div>
    );
  }

  renderHeroHomeBlock() {
    return (
      <Row type="flex" style={{ overflowY: "hidden", background: "#fff" }}>
        <Col xs={{ span: 24 }} md={{ span: 14 }}>
          <Parallax
            x={[0, 0]}
            y={[-50, 50]}
            tagOuter="figure"
            style={{ zIndex: -300 }}
          >
            <div
              style={{
                height: "80vh",
                width: "100%",
                //position: "absolute",
                backgroundImage:
                  'url("https://source.unsplash.com/betmVWGYcLY/800x800")',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
              }}
            ></div>
          </Parallax>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <div
            style={{
              color: "black",
              height: "80vh",
              width: "100%",
              // border: "1px solid #ccc",
              backgroundColor: "#fff",
              padding: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              zIndex: 100,
            }}
          >
            <div
              style={{
                padding: "5px",
                paddingBottom: 10,
                fontSize: "45px",
                fontWeight: "700",
                fontFamily: "Abel",
                color: "rgb(86,94,80)",
              }}
            >
              HAUS
            </div>
            <div
              style={{
                padding: "5px",
                paddingBottom: "10px",
                fontSize: "22px",
                fontFamily: "Roboto",
                color: "rgb(86,94,80)",
              }}
            >
              Haus is a project that was born with the aim of offering the
              general population a healthier and more sustainable lifestyle
              through a set of tools such as physical exercises that improve the
              relationship between body and mind, as well as other fundamental
              proposals for our well-being.
            </div>
            {/* <Link to="/wellbeing">
              <div
                style={{
                  display: "flex",
                  padding: "12px 24px",
                  border: "1px solid #000",
                  borderRadius: "24px",
                  backgroundColor: "#000",
                  color: "#fff",
                  fontSize: "16px",
                  transition: "all .2s ease-in-out",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                Go to Wellbeing
              </div>
            </Link> */}
          </div>
        </Col>
      </Row>
    );
  }

  renderBancodeLeite() {
    return (
      <div
        style={{
          padding: 30,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "#f6f6f6",
          marginTop: 20,
          zIndex: 100,
        }}
      >
        <div
          style={{
            padding: "10px",

            fontSize: "30px",
            fontWeight: "400",
            fontFamily: "Abel",
            color: "rgb(86,94,80)",
            textAlign: "center",
          }}
        >
          We donate 1% of all subscription to
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "200px",

            // minWidth: "400px",
            height: "150px",
            backgroundImage: 'url("' + BancoLeite_Logo + '")',
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
          }}
        />

        <div
          style={{
            padding: "2px",
            fontSize: "20px",
            fontWeight: "400",
            fontFamily: "Abel",
            color: "rgb(86,94,80)",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open("https://www.bancodeleite.pt/");
          }}
        >
          Learn more
        </div>
      </div>
    );
  }

  renderSentenceSlider() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "#f6f6f6",
          marginTop: 20,
          zIndex: 100,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            minHeight: "35vh",
            height: "auto",
            width: "90%",
            padding: "20px",
          }}
        >
          <Slider {...settings}>
            {sentences.map((sentence) => {
              return (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      minHeight: "30vh",
                      height: "auto",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Abel",
                        fontSize: this.props.matches.small ? 15 : 30,
                        paddingLeft: 30,
                        paddingRight: 30,
                        height: "auto",
                      }}
                    >
                      {sentence.sentence}
                    </div>
                    <div
                      style={{
                        fontWeight: 600,
                        fontFamily: "Roboto",
                        fontSize: 20,
                        padding: 30,
                      }}
                    >
                      {sentence.who}
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    );
  }
  renderBigImage(title, text, image) {
    return (
      <div style={{ margin: 20 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minWidth: "350px",
            height: "350px",
            backgroundImage: 'url("' + image + '")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
          }}
        >
          <div
            className={"wellbeingDivEffect"}
            style={{
              //  backgroundColor: "#0005",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }} //black overlay
          >
            <div
              style={{
                padding: 10,
                fontFamily: "Abel",
                fontSize: 50,
                color: "#fff",
                textTransform: "uppercase",
                // textShadow: "2px 2px black",
                textAlign: "center",
              }}
            >
              {title}
            </div>
          </div>
        </div>
        <div
          style={{
            padding: 5,
            fontFamily: "Roboto",
            fontSize: 18,
            color: "rgb(86,94,80)",
            fontFamily: "Abel",
          }}
        >
          {/* {text} */}
        </div>
      </div>
    );
  }

  renderHomeClassTypes() {
    return (
      <div>
        <Row
          type="flex"
          align="middle"
          justify="space-around"
          style={{ margin: "10px", zIndex: 100 }}
        >
          <Col s={{ span: 22 }} md={{ span: 10 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Link to="/classes-types/yoga">
                {this.renderBigImage(
                  "Yoga Classes",
                  "example text, description of the class",
                  "https://source.unsplash.com/D2uK7elFBU4/800x800"
                )}
              </Link>
            </div>
          </Col>
          <Col s={{ span: 22 }} md={{ span: 10 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Link to="/classes-types/pilates">
                {this.renderBigImage(
                  "Pilates Classes",
                  "example text, description of the class",
                  PilatesPic
                )}
              </Link>
            </div>
          </Col>
        </Row>
        <Row
          type="flex"
          align="middle"
          justify="space-around"
          style={{ margin: "10px" }}
        >
          <Col s={{ span: 22 }} md={{ span: 7 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Link to="/classes-types/fitness">
                {this.renderBigImage(
                  "FITNESS Classes",
                  "example text, description of the class",
                  "https://source.unsplash.com/lrQPTQs7nQQ/800x800"
                )}
              </Link>
            </div>
          </Col>
          <Col s={{ span: 22 }} md={{ span: 7 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Link to="/classes-types/meditation">
                {this.renderBigImage(
                  "Meditation Classes",
                  "example text, description of the class",
                  "https://source.unsplash.com/6CLBoiWuzSU/800x800"
                )}
              </Link>
            </div>
          </Col>
          <Col s={{ span: 22 }} md={{ span: 7 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Link to="/wellbeing">
                {this.renderBigImage(
                  "Wellbeing",
                  "example text, description of the class",
                  "https://source.unsplash.com/xMNel_otvWs/800x800"
                )}
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
function NextArrow(props) {
  const { className, style, onClick } = props;
  //console.log("className", className);
  return (
    <div className={className} onClick={onClick}>
      {/* <RightOutlined /> */}
      <div
        style={{
          width: 50,
          height: 50,
          backgroundImage: 'url("' + arrowNext + '")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
        }}
      ></div>
    </div>
    // <div
    //   className="slick-arrow"
    //   style={{ ...style, display: "block" }}
    //   // className={className}

    //   onClick={onClick}
    // >
    //   <img src={arrowNext} alt="arrow_left" />
    //   {/* <div
    //     style={{
    //       backgroundImage: 'url("' + arrowNext + '")',
    //       backgroundSize: "cover",
    //       backgroundRepeat: "no-repeat",
    //       backgroundPosition: "50% 50%",
    //     }}
    //   ></div> */}
    // </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      {/* <RightOutlined /> */}
      <div
        style={{
          width: 50,
          height: 50,
          backgroundImage: 'url("' + arrowPrevious + '")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
        }}
      ></div>
    </div>
  );
}
