import { Col, Row } from "antd";
import React from "react";
import Slider from "react-slick";
import imgThais from "../media/teachers/thais1.png";
import imgCarolina from "../media/teachers/carolina1.png";
import imgRita from "../media/teachers/rita1.png";
import imgAtul from "../media/teachers/atul.png";
import imgVera from "../media/teachers/vera2.png";
import stefano from "../media/teachers/stefano1.jpg";
import patricia from "../media/teachers/PatriciaNazaré1.jpg";
import tathiane from "../media/teachers/Tathiane01.jpg";
import imgJacqueline from "../media/teachers/jacqueline_small.jpg";
import landingHeroImage from "../media/localImages/landing_hero_image.jpg";
import forYou from "../media/localImages/forYouIcon.png";
import Sustainability from "../media/localImages/sustainabilityIcon.png";
import arrowNext from "../media/localImages/next.png";
import arrowPrevious from "../media/localImages/previous.png";
import classesIcon from "../media/localImages/classesIcon.png";
import { Parallax } from "react-scroll-parallax";
import hausLogoComplete from "../media/logoHaus.png";
import Media from "react-media";
import { Link } from "react-router-dom";
import BancoLeite_Logo from "../media/localImages/BancoLeite_Logo.png";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};
const sentences = [
  {
    sentence:
      "I've been an athlete and active all my life and it was only when I began working with Jacqueline that I began to better understand proper movement and muscle use. After two years, I'm still learning and strengthening my body, which at my age is essential. Jacqueline is amazing!",
    who: "Robert McCoole, USA",
  },
  {
    sentence:
      "The online Pilates classes strongly contribute to keeping my  physical and spiritual balance, whenever I need, wherever I am.",
    who: "Teresa Romão, Portugal",
  },
  {
    sentence:
      "Stefano is a a wonderful teacher who truly teaches from his heart. His knowledge in yoga goes beyond asana, and his ability to explain alignment has helped me in my own practice.",
    who: "Katelyne Suttleworth, Canadá",
  },
];
export default class LandingPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div style={{ backgroundColor: "#fff" }}>
        {this.renderHeroHomeBlock()}
        {this.renderHomeStyles()}
        {this.renderConvenienceHomeBlock()}
        {this.renderWellbeingHomeBlock()}
        {this.renderTeachers()}
        {this.renderSentenceSlider()}
        {/* {this.renderSustainabilityBlock()} */}
        {this.renderGetStartedBar()}
      </div>
    );
  }
  renderSentenceSlider() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "#f6f6f6",
          // marginTop: 20,
          paddingBottom: 20,
          zIndex: 100,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            minHeight: "35vh",
            height: "auto",
            width: "90%",
            padding: "20px",
          }}
        >
          <div
            style={{
              //padding: "5px",
              width: "100%",
              textAlign: "center",
              paddingBottom: 10,
              fontSize: "50px",
              fontWeight: "700",
              fontFamily: "Abel",
              color: "rgb(86,94,80)",
            }}
          >
            Testimonials
          </div>
          <Slider {...settings}>
            {sentences.map((sentence) => {
              return (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      minHeight: "30vh",
                      height: "auto",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Abel",
                        fontSize: this.props.matches.small ? 15 : 30,
                        paddingLeft: 30,
                        paddingRight: 30,
                        height: "auto",
                      }}
                    >
                      {sentence.sentence}
                    </div>
                    <div
                      style={{
                        fontWeight: 600,
                        fontFamily: "Roboto",
                        fontSize: 20,
                        padding: 30,
                      }}
                    >
                      {sentence.who}
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    );
  }

  renderGetStartedBar() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgb(237,194,167)",
          width: "100%",
          height: "20vh",
        }}
      >
        <Link to={"/register"}>
          <div
            style={{
              margin: "50px",
              border: "1px solid rgb(86,94,80)",
              backgroundColor: "rgb(251,247,233)",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "190px",
              height: "50px",
              fontSize: "18px",
              backgroundColor: "#fff",
              color: "rgb(86,94,80)",
              cursor: "pointer",
              textTransform: "uppercase",
            }}
          >
            Let's get started
          </div>
        </Link>
      </div>
    );
  }

  renderHeroHomeBlock() {
    return (
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)",
          retina: "(min-width: 2200px)",
        }}
      >
        {(matches) => (
          <Row
            type="flex"
            align="middle"
            style={{
              // margin: "10px",
              backgroundColor: "rgb(209, 215, 227)",
              zIndex: -100,
              overflow: "hidden",
            }}
          >
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              {/* <img src="https://source.unsplash.com/F2qh3yjz6Jk" alt="new" /> */}

              {matches.small ? (
                <Parallax x={[0, 0]} y={[-30, 30]} tagOuter="figure">
                  <div
                    style={{
                      height: "80vh",
                      width: "100%",
                      backgroundImage: 'url("' + landingHeroImage + '")',
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "20% 50%",
                      marginBottom: 30,
                    }}
                  ></div>
                </Parallax>
              ) : (
                <Parallax x={[0, 0]} y={[-30, 50]} tagOuter="figure">
                  <div
                    style={{
                      height: "80vh",
                      minHeight: "80vh",
                      height: "auto",
                      maxHeight: "1000px",
                      width: "100%",
                      backgroundImage: 'url("' + landingHeroImage + '")',
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: matches.retina
                        ? "20% 50%"
                        : "10% 50%",
                    }}
                  ></div>
                </Parallax>
              )}
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <div
                style={{
                  color: "black",
                  minHeight: "80vh",
                  height: "auto",
                  width: "100%",
                  border: "1px solid rgb(209,215,227)",
                  backgroundColor: "rgb(209,215,227)",
                  padding: "50px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    padding: "5px",
                    //   paddingBottom: 10,
                    fontSize: "70px",
                    fontWeight: "500",
                    fontFamily: "Abel",
                    color: "rgb(86,94,80)",
                  }}
                >
                  JUST BE
                </div>
                <div
                  style={{
                    padding: "5px",
                    // paddingBottom: "10px",
                    fontSize: matches.small ? "15px" : "22px",
                    color: "rgb(86,94,80)",
                    fontFamily: "Roboto",
                  }}
                >
                  Haus is a place of creation, inspiration and comfort. <br />
                  It's returning to yourself and discovering your full potential
                  with a healthy and sustainable lifestyle.
                  <br />
                  <br />
                </div>
                <div
                  style={{
                    padding: "5px",
                    paddingBottom: "20px",
                    fontSize: matches.small ? "15px" : "22px",
                    color: "rgb(86,94,80)",
                    fontFamily: "Roboto",
                  }}
                >
                  We offer online and on-demand different styles of pilates,
                  yoga, fitness for all levels, meditation, healthy food,
                  natural cosmetics and astrology.
                </div>
                <div
                  style={{
                    padding: "5px",
                    // paddingBottom: "10px",
                    fontSize: matches.small ? "15px" : "22px",
                    color: "rgb(86,94,80)",
                    fontFamily: "Roboto",
                  }}
                >
                  <span style={{ fontSize: 30, fontWeight: 800 }}>9.99€</span>
                  /Month OR{" "}
                  <span style={{ fontSize: 30, fontWeight: 800 }}>84€</span>
                  /Year
                </div>
                <div
                  style={{
                    padding: "5px",
                    paddingBottom: "20px",
                    fontSize: matches.small ? "15px" : "18px",
                    color: "rgb(86,94,80)",
                    fontFamily: "Roboto",
                  }}
                >
                  Cancel easily anytime!
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",

                    justifyContent: "start",
                  }}
                >
                  <Link to={"/register"}>
                    <div
                      style={{
                        //  margin: "10px",
                        textShadow: "0.5px 0.5px #000",
                        fontSize: 18,
                        //border: "1px solid #fff",
                        borderRadius: "10px",
                        fontFamily: "Roboto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "190px",
                        height: "50px",
                        backgroundColor: "rgb(237,194,167)",
                        color: "#fff",
                        cursor: "pointer",
                        textTransform: "uppercase",
                      }}
                    >
                      15 days free trial
                    </div>
                  </Link>
                </div>
                {/* <Link to="/wellbeing">
              <div
                style={{
                  display: "flex",
                  padding: "12px 24px",
                  border: "1px solid #000",
                  borderRadius: "24px",
                  backgroundColor: "#000",
                  color: "#fff",
                  fontSize: "16px",
                  transition: "all .2s ease-in-out",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                Go to Wellbeing
              </div>
            </Link> */}
              </div>
            </Col>
          </Row>
        )}
      </Media>
    );
  }

  renderConvenienceHomeBlock() {
    return (
      <Row
        type="flex"
        align="space-around"
        justify="space-around"
        style={{ padding: "20px", backgroundColor: "#fff" }}
      >
        <Col xs={{ span: 24 }} md={{ span: 9 }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                color: "black",
                height: "auto",
                width: "100%",
                // border: "1px solid #ccc",
                backgroundColor: "#fff",
                padding: "10px",
                //margin: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                alignSelf: "start",
                textAlign: "start",
              }}
            >
              <div
                style={{
                  //padding: "5px",
                  paddingBottom: 10,
                  fontSize: "50px",
                  fontWeight: "700",
                  fontFamily: "Abel",
                  color: "rgb(86,94,80)",
                }}
              >
                Convenience and mobility
              </div>
              <div
                style={{
                  //padding: "5px",
                  paddingBottom: "10px",
                  fontSize: "25px",
                  color: "rgb(86,94,80)",
                  fontFamily: "Roboto",
                }}
              >
                Practice anywhere, anytime. <br />
                Explore a new way to exercise and learn more about yourself.
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  width: "100%",
                }}
              >
                <Link to={"/register"}>
                  <div
                    style={{
                      marginTop: "20px",
                      //border: "1px solid #fff",
                      borderRadius: "10px",
                      display: "flex",
                      fontSize: "18px",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "230px",
                      height: "50px",
                      backgroundColor: "rgb(86,94,80)",
                      color: "#fff",
                      cursor: "pointer",
                      textTransform: "uppercase",
                    }}
                  >
                    Start your free trial
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          {/* <img src="https://source.unsplash.com/F2qh3yjz6Jk" alt="new" /> */}

          <div
            style={{
              height: "70vh",
              width: "100%",
              backgroundImage:
                'url("https://source.unsplash.com/HOSjS6qIGAs/700x500")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 50%",
            }}
          ></div>
        </Col>
      </Row>
    );
  }

  renderWellbeingHomeBlock() {
    return (
      <Row
        type="flex"
        align="space-around"
        justify="space-around"
        style={{ padding: "20px" }}
      >
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          {/* <img src="https://source.unsplash.com/F2qh3yjz6Jk" alt="new" /> */}

          <div
            style={{
              height: "70vh",
              width: "100%",
              backgroundImage:
                'url("https://source.unsplash.com/9OflqNWhcrE/600x600")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 50%",
            }}
          ></div>
        </Col>

        <Col xs={{ span: 24 }} md={{ span: 9 }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                color: "black",
                height: "auto",
                width: "100%",
                // border: "1px solid #ccc",
                backgroundColor: "#fff",
                padding: "10px",
                margin: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                alignSelf: "start",
              }}
            >
              <div
                style={{
                  //padding: "5px",
                  paddingBottom: 10,
                  fontSize: "50px",
                  fontWeight: "700",
                  fontFamily: "Abel",
                  color: "rgb(86,94,80)",
                }}
              >
                Wellbeing
              </div>
              <div
                style={{
                  // padding: "5px",
                  paddingBottom: "10px",
                  fontSize: "25px",
                  color: "rgb(86,94,80)",
                  fontFamily: "Roboto",
                }}
              >
                You will learn about healthy food and natural cosmetic, besides
                getting to know how we are all connected through an astrological
                perspective.
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  renderHausStyleBlock(title, image, description) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          width: 320,
          //  paddingBottom: "50px",
          // paddingTop: "50px",
          margin: "10px",
        }}
      >
        <div
          style={{
            height: "100px",
            width: "100px",
            backgroundImage: 'url("' + image + '")',
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
            // borderRadius: "50%",
          }}
        ></div>
        <div
          style={{
            padding: 5,
            color: "rgb(86,94,80)",
            fontSize: "32px",
            letterSpacing: "-0.4px",
            fontFamily: "Abel",
            textTransform: "uppercase",
          }}
        >
          {title}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: 80,
            padding: 5,
            fontSize: 15,
            color: "rgb(86,94,80)",
            fontFamily: "Roboto",
            textTransform: "uppercase",
            // backgroundColor: "#0002",
            height: "auto",
          }}
        >
          {description}
        </div>
        {title === "Social Responsibility" /* bad temporary W.A */ ? (
          <a target="_blank" href="https://www.bancodeleite.pt/">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                // minHeight: 80,
                padding: 5,
                fontSize: 15,
                color: "rgb(86,94,80)",
                fontFamily: "Roboto",
                textTransform: "uppercase",
                // backgroundColor: "#0002",
                height: "auto",
                cursor: "pointer",
              }}
            >
              Learn More
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100px",

                // minWidth: "400px",
                height: "70px",
                backgroundImage: 'url("' + BancoLeite_Logo + '")',
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
              }}
            />
          </a>
        ) : null}
      </div>
    );
  }
  renderTeacherBlock(title, image, description, country) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: 350,
          width: 250,
          justifyContent: "center",
          alignItems: "center",

          margin: "40px",
        }}
      >
        <div
          style={{
            height: "300px",
            width: "250px",
            backgroundImage: 'url("' + image + '")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 5%",
            // borderRadius: "50%",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "#fff",
            width: "100%",
          }}
        >
          <div
            style={{
              padding: 5,
              color: "#000",
              fontSize: "24px",
              fontWeight: "600",
              letterSpacing: "-0.4px",
              color: "rgb(86,94,80)",
              fontFamily: "Abel",
              textTransform: "uppercase",
            }}
          >
            {title}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
              fontSize: 23,
              color: "#000",
              fontWeight: "600",
              // backgroundColor: "#fff5",
              //borderRadius: 20,
              fontFamily: "Abel",
              height: "auto",
              color: "rgb(86,94,80)",
            }}
          >
            {description}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 2,
              fontSize: 18,
              backgroundColor: "#fff5",
              borderRadius: 20,
              height: "auto",
              color: "rgb(86,94,80)",
              fontFamily: "Abel",
              fontStyle: "italic",
            }}
          >
            {country}
          </div>
        </div>
      </div>
    );
  }

  renderHomeStyles() {
    return (
      <Row>
        <div
          style={{
            //  minHeight: "10vh",
            height: "auto",
            padding: 20,
            width: "100%",
            backgroundColor: "#fff6e9",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "center",
            zIndex: 100,
          }}
        >
          {this.renderHausStyleBlock(
            "For you",
            forYou,
            "Personalize your practice, because you are unique."
          )}
          {this.renderHausStyleBlock(
            "Classes",
            classesIcon,
            "OUR CLASSES ARE INCLUSIVE, AND OFFERED IN ENGLISH AND PORTUGUESE."
          )}
          {this.renderHausStyleBlock(
            "Social Responsibility",
            Sustainability,
            "Be-Haus donates 1% for education"
          )}
        </div>
      </Row>
    );
  }

  renderTeachers() {
    return (
      <Row style={{ backgroundColor: "rgb(245,246,247)" }}>
        <div
          style={{
            //padding: "5px",
            width: "100%",
            textAlign: "center",
            paddingTop: 20,
            paddingBottom: 0,
            fontSize: "50px",
            fontWeight: "700",
            fontFamily: "Abel",
            color: "rgb(86,94,80)",
          }}
        >
          Meet our Team
        </div>
        <div
          style={{
            minHeight: "50vh",
            height: "auto",
            width: "100%",
            //backgroundColor: background-color: #fff1df;

            //backgroundColor: "#fff",
            // backgroundImage: "linear-gradient(19deg, #fff1df 0%, #e3deff 0%)",

            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {this.renderTeacherBlock(
            "Pilates",
            imgThais,
            "Thaís Caniceiro",
            "Brazil"
          )}
          {this.renderTeacherBlock("Yoga", imgVera, "Vera Sousa", "Portugal")}
          {this.renderTeacherBlock(
            "Pilates",
            imgJacqueline,
            "Jacqueline Fritz",
            "U.S.A"
          )}
          {this.renderTeacherBlock(
            "Fitness",
            imgRita,
            "Rita Fragoso",

            "Portugal"
          )}
          {this.renderTeacherBlock(
            "Yoga",
            stefano,
            "Stefano Allegri",
            "Costa Rica"
          )}
          {this.renderTeacherBlock(
            "Meditation",
            imgAtul,
            "Atul Mulji",
            "Portugal"
          )}
        </div>
      </Row>
    );
  }

  renderSustainabilityBlock() {
    return (
      <Row type="flex" align="middle" style={{ margin: "10px" }}>
        <Col xs={{ span: 24 }} md={{ span: 14 }}>
          {/* <img src="https://source.unsplash.com/F2qh3yjz6Jk" alt="new" /> */}

          <div
            style={{
              height: "80vh",
              width: "100%",
              backgroundImage:
                'url("https://source.unsplash.com/EwKXn5CapA4/600x550")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 50%",
            }}
          ></div>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <div
            style={{
              color: "black",
              height: "80vh",
              width: "100%",
              border: "1px solid #ccc",
              backgroundColor: "#fff",
              padding: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <div
              style={{
                padding: "5px",
                paddingBottom: 10,
                fontSize: "40px",
                fontWeight: "700",
                fontFamily: "ArchitectsDaughter",
              }}
            >
              SUSTAINABILITY
            </div>
            <div
              style={{
                padding: "5px",
                paddingBottom: "10px",
                fontSize: "22px",
              }}
            >
              We believe that we can make the difference, whether from our
              example, or by supporting a single person or a group of people.
              <br />
              <br />
              For this reason, HAUS decided to support initiatives that
              encourage one of the most impacting aspects for our present and
              for our future, the education.
            </div>
          </div>
        </Col>
        <div
          style={{
            height: "70px",
            width: "100%",
            background: "#fff",
            margin: 10,
          }}
        >
          {" "}
        </div>
      </Row>
    );
  }
}
function NextArrow(props) {
  const { className, style, onClick } = props;
  //console.log("className", className);
  return (
    <div className={className} onClick={onClick}>
      {/* <RightOutlined /> */}
      <div
        style={{
          width: 50,
          height: 50,
          backgroundImage: 'url("' + arrowNext + '")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
        }}
      ></div>
    </div>
    // <div
    //   className="slick-arrow"
    //   style={{ ...style, display: "block" }}
    //   // className={className}

    //   onClick={onClick}
    // >
    //   <img src={arrowNext} alt="arrow_left" />
    //   {/* <div
    //     style={{
    //       backgroundImage: 'url("' + arrowNext + '")',
    //       backgroundSize: "cover",
    //       backgroundRepeat: "no-repeat",
    //       backgroundPosition: "50% 50%",
    //     }}
    //   ></div> */}
    // </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      {/* <RightOutlined /> */}
      <div
        style={{
          width: 50,
          height: 50,
          backgroundImage: 'url("' + arrowPrevious + '")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
        }}
      ></div>
    </div>
  );
}
