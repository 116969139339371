import {
  ContactsOutlined,
  UserOutlined,
  InstagramOutlined,
  FacebookOutlined,
} from "@ant-design/icons";
import { Input, message, Modal, Select, Switch, Tabs } from "antd";
import React from "react";
import PicturesWall from "./../components/UploadComponent";
import { putTeacherInfo, saveNewTeacher } from "./../network/basicContent";

const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const languagePossibilities = [
  <Option key={"Portuguese"}>{"Portuguese"}</Option>,
  <Option key={"English"}>{"English"}</Option>,
  <Option key={"Spanish"}>{"Spanish"}</Option>,
];
const classesPossibilities = [
  <Option key={"Yoga"}>{"Yoga"}</Option>,
  <Option key={"Pilates"}>{"Pilates"}</Option>,
  <Option key={"Fitness"}>{"Fitness"}</Option>,
  <Option key={"Meditation"}>{"Meditation"}</Option>,
];

export default class EditTeacher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      teacherName: "",
      teacherBio: "",
      smallBio: "",
      teacherEmail: "",
      teacherLanguages: [],
      teacherClassTypes: [],
      teacherVisible: true,
    };
  }

  componentDidMount() {
    //console.log("this.props.teacherInfo", this.props.teacherInfo);
    if (this.props.creatingNewTeacher) {
    } else {
      if (this.props.teacherInfo) {
        this.setState({
          teacherLanguages: this.props.teacherInfo.language,
          teacherClassTypes: this.props.teacherInfo.tags,
          teacherName: this.props.teacherInfo.name,
          teacherBio: this.props.teacherInfo.bio,
          smallBio: this.props.teacherInfo.smallBio,
          teacherEmail: this.props.teacherInfo.email,
          teacherVisible: this.props.teacherInfo.isVisible,
          teacherInstagram: this.props.teacherInfo.instagram,
          teacherFacebook: this.props.teacherInfo.facebook,
        });
      }
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = async () => {
    let savingResult;
    // console.log(e);
    this.setState({ isLoading: true });

    message.loading("Saving...", 2);
    // Dismiss manually and asynchronously
    // setTimeout(hide, 2000);
    if (this.props.creatingNewTeacher) {
      savingResult = await this.saveNewTeacher();
    } else {
      savingResult = await this.saveAllChanges();
    }

    this.setState({ isLoading: false });

    console.log("savingResult", savingResult);

    if (savingResult && savingResult.status && savingResult.status == 200) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.setState({
        modalVisible: false,
      });
      window.location.reload();
    }
  };

  handleCancel = (e) => {
    //console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleChangeClass(value) {
    //console.log(`selected`, value);
    this.setState({ teacherClassTypes: value });
  }

  handleChangeLanguage(value) {
    //console.log(`selected`, value);

    this.setState({ teacherLanguages: value });
  }

  renderEditTeacherPanel() {
    return (
      <div
        style={{
          color: "black",
          minHeight: "130px",
          height: "auto",
          width: "100%",
          backgroundColor: "#fff",
          padding: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div
          onClick={this.showModal}
          style={{
            display: "flex",
            height: "50px",
            width: "auto",
            justifyContent: "center",
            alignItems: "center",
            //padding: "20px",
            padding: "10px",
            border: "0.5px solid #000",
            borderRadius: "24px",
            backgroundColor: "#faf9f7",
            color: "#000",
            fontSize: "12px",
            cursor: "pointer",
            fontWeight: 600,
            margin: 5,
          }}
        >
          {this.props.creatingNewTeacher ? "Add new teacher" : "Edit teacher"}
        </div>
      </div>
    );
  }

  setTeacherVisible(visible) {
    this.setState({ teacherVisible: visible });
  }

  render() {
    return (
      <div style={{ backgroundColor: "#faf9f7" }}>
        {this.renderEditTeacherPanel()}
        {this.renderEditTeacherModal()}
      </div>
    );
  }

  renderTeacherInfoPanel() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          alignItems: "flex-end",
          width: "100%",
          height: "auto",
        }}
      >
        {/* <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Tags Mode"
          onChange={this.handleChangeLanguage}
        >
          {this.state.teacherLanguages.map((language, index) => (
            <Option key={index}>{language}</Option>
          ))}
        </Select> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%",
            height: "auto",

            padding: 20,
          }}
        >
          <div
            style={{
              height: "auto",
              width: "200px",
              margin: 10,
            }}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Teacher Languages"
              value={this.state.teacherLanguages}
              onChange={(value) => this.handleChangeLanguage(value)}
            >
              {languagePossibilities}
            </Select>
          </div>
          <div style={{ height: "auto", width: "200px", margin: 10 }}>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Styles"
              value={this.state.teacherClassTypes}
              onChange={(value) => this.handleChangeClass(value)}
            >
              {classesPossibilities}
            </Select>
          </div>
          <div
            style={{
              height: "auto",
              width: "200px",
              padding: 5,
              margin: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>Visible to public?</div>
            <Switch
              checked={this.state.teacherVisible}
              onChange={(visible) => this.setTeacherVisible(visible)}
            />
          </div>
        </div>
        <Input
          style={{ marginBottom: 20 }}
          size="large"
          placeholder="First and last name"
          prefix={<UserOutlined />}
          value={this.state.teacherName}
          onChange={(input) =>
            this.setState({ teacherName: input.target.value })
          }
        />
        <Input
          style={{ marginBottom: 20 }}
          size="large"
          placeholder="email"
          prefix={<ContactsOutlined />}
          value={this.state.teacherEmail}
          onChange={(input) =>
            this.setState({ teacherEmail: input.target.value })
          }
        />
        <div style={{ fontSize: 13, marginTop: 30, alignSelf: "flex-start" }}>
          Teacher small bio:
        </div>
        <TextArea
          rows={3}
          value={this.state.smallBio}
          onChange={(input) => this.setState({ smallBio: input.target.value })}
        />
        <div style={{ fontSize: 13, marginTop: 30, alignSelf: "flex-start" }}>
          Teacher full bio:
        </div>
        <TextArea
          style={{ marginBottom: 20 }}
          rows={8}
          value={this.state.teacherBio}
          onChange={(input) =>
            this.setState({ teacherBio: input.target.value })
          }
        />
        <Input
          style={{ marginBottom: 20 }}
          size="large"
          placeholder="Instagram"
          prefix={<InstagramOutlined />}
          value={this.state.teacherInstagram}
          onChange={(input) =>
            this.setState({ teacherInstagram: input.target.value })
          }
        />
        <Input
          style={{ marginBottom: 20 }}
          size="large"
          placeholder="Facebook"
          prefix={<FacebookOutlined />}
          value={this.state.teacherFacebook}
          onChange={(input) =>
            this.setState({ teacherFacebook: input.target.value })
          }
        />
        {/* <div
          onClick={async () => {
            if (this.props.creatingNewTeacher) {
              await this.saveNewTeacher();
            } else {
              await this.saveAllChanges();
            }
          }}
          style={{
            display: "flex",
            width: "150px",
            margin: 10,
            padding: "12px 24px",
            border: "1px solid #000",
            borderRadius: "24px",
            backgroundColor: "#000",
            color: "#fff",
            fontSize: "16px",
            transition: "all .2s ease-in-out",
            cursor: "pointer",
            whiteSpace: "nowrap",
          }}
        >
          Save changes
        </div> */}
      </div>
    );
  }

  async saveNewTeacher() {
    return await saveNewTeacher({
      name: this.state.teacherName,
      bio: this.state.teacherBio,
      smallBio: this.state.smallBio,
      email: this.state.teacherEmail,
      tags: this.state.teacherClassTypes,
      language: this.state.teacherLanguages,
      isVisible: this.state.teacherVisible,
      facebook: this.state.teacherFacebook,
      instagram: this.state.teacherInstagram,
    }).then((result) => {
      // console.log("result of saving: ", result);

      if (result.status == 200) {
        message.success("Teacher info saved!");
        return result;
      } else {
        message.error("Info not saved. Check your connection.");
      }
    });
  }
  async saveAllChanges() {
    return await putTeacherInfo(this.props.teacherInfo._id, {
      name: this.state.teacherName,
      bio: this.state.teacherBio,
      smallBio: this.state.smallBio,
      email: this.state.teacherEmail,
      tags: this.state.teacherClassTypes,
      language: this.state.teacherLanguages,
      isVisible: this.state.teacherVisible,
      facebook: this.state.teacherFacebook,
      instagram: this.state.teacherInstagram,
    }).then((result) => {
      //console.log("result of saving: ", result);

      if (result.status == 200) {
        message.success("Teacher info saved!");
        return result;
      } else {
        message.error("Info not saved. Check your connection.");
      }
    });
  }
  renderEditTeacherModal() {
    return (
      <Modal
        title="Add Teacher"
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        style={{ height: "80vh" }}
        width={"80vw"}
      >
        <Tabs defaultActiveKey="1" onChange={this.tabChanged}>
          <TabPane tab="Teacher Info" key="1">
            {this.renderTeacherInfoPanel()}
          </TabPane>
          {!this.props.creatingNewTeacher ? (
            <TabPane tab="Photos" key="2">
              <PicturesWall teacherInfo={this.props.teacherInfo} />
            </TabPane>
          ) : null}
        </Tabs>
      </Modal>
    );
  }
}
